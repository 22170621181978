<template>
    <div class="padding-container">
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Register Agent</h3>
            </div>
            <div class="row">
                <div class="col-lg-6 d-flex gap-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Cari nama agent"
                        v-model="search"
                        @input="debouncedFilterData"
                    />
                    <button
                        class="btn d-flex btn-filter gap-2 px-4"
                        @click="toggleFilter()"
                    >
                        Filter
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 8L9.5 8"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M16 8H20M16 8C16 6.89543 15.1046 6 14 6C12.8954 6 12 6.89543 12 8C12 9.10457 12.8954 10 14 10C15.1046 10 16 9.10457 16 8Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M8 16H4M8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M14.5 16L20 16"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="box mt-3" v-if="showFilter">
                <label class="fw-bold mb-3">Filter Data</label>
                <div class="row">
                    <div class="col-md-4 mt-2">
                        <label class="mb-3">Kota/Kab. Domisili</label>
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Cari kota/kab"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mt-2 position-relative">
                        <label class="mb-3">Status</label>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-toggle text-gray-dashboard"
                            id="statusDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <div class="radius-select d-flex">
                                <div
                                    class="d-flex align-items-center justify-content-between w-100 gap-4"
                                >
                                    <div class="scroll-select">
                                        <template
                                            v-if="selectedStatus.length === 0"
                                        >
                                            <p class="placeholder-custom">
                                                Pilih status
                                            </p>
                                        </template>
                                        <template v-else>
                                            {{
                                                selectedStatus
                                                    .map((s) =>
                                                        s.name
                                                            .split("_")
                                                            .join(" ")
                                                    )
                                                    .join(", ")
                                            }}
                                        </template>
                                    </div>
                                    <div class="ms-auto arrow-custom"></div>
                                </div>
                            </div>
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="statusDropdown"
                        >
                            <li
                                v-for="statusOption in statuses"
                                :key="statusOption.id"
                                class="pt-1 pb-1"
                            >
                                <div
                                    class="form-check form-check-inline cursor-pointer"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="statusOption.id"
                                        :value="statusOption"
                                        v-model="selectedStatus"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="statusOption.id"
                                    >
                                        {{
                                            statusOption.name
                                                .split("_")
                                                .join(" ")
                                        }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="invisible mb-3">invisible</label>
                        <button
                            class="btn px-5 w-100"
                            :class="{
                                'btn-clear-disabled': !canFilter,
                                'btn-outline-red': canFilter,
                            }"
                            :disabled="!canFilter"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="invisible mb-3">invisible</label>
                        <button
                            class="btn px-5 w-100"
                            :class="{
                                'btn-filter-disabled': !canFilter,
                                'btn-green': canFilter,
                            }"
                            :disabled="!canFilter"
                            @click="applyFilter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoading">
                <ListLoader />
            </div>
            <div v-else>
                <div
                    class="d-flex justify-content-between align-items-center mt-4"
                >
                    <p class="mb-0">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pagination.start }}-{{ pagination.end }} </span
                        >Data dari
                        <span class="fw-bold">{{ totalData }} </span>Data
                    </p>
                    <div class="d-flex gap-3 align-items-center">
                        <p class="mb-0">Rows Per Page</p>
                        <select
                            class="form-control form-select w-select bg-row"
                            v-model="rowsPerPage"
                            @change="changeRowsPerPage()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive-custom mb-4">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Agent
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Telepon
                                </th>
                                <th class="text-center" scope="col">
                                    Kota/Kabupaten
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Koordinator
                                </th>
                                <th class="text-center" scope="col">
                                    Terakhir Diupdate
                                </th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">
                                    Reject Count
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr></tr>
                            <tr
                                v-for="(agent, index) in agents"
                                :key="agent.id"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <router-link
                                        class="text-link"
                                        :to="`/register-sales-affiliate/detail/${agent.id}`"
                                    >
                                        {{ agent.agent_name }}
                                    </router-link>
                                </td>
                                <td class="text-center">
                                    {{ agent.agent_phone }}
                                </td>
                                <td>{{ agent.regency }}</td>
                                <td>{{ agent.coor_name }}</td>
                                <td class="text-center">
                                    {{ agent.last_update }}
                                </td>
                                <td class="text-center d-flex flex-column">
                                    <div
                                        :class="[
                                            'tag rounded-pill w-100 capitalize',
                                            {
                                                panding:
                                                    agent.status === 'pending',
                                                reject:
                                                    agent.status === 'reject',
                                                resubmit:
                                                    agent.status === 'resubmit',
                                                banned:
                                                    agent.status === 'banned',
                                                'bg-success text-white':
                                                    agent.status === 'accept',
                                            },
                                        ]"
                                    >
                                        {{ agent.status }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div
                                        :class="{
                                            'text-link cursor-pointer':
                                                agent.reject_count > 0,
                                        }"
                                        @click="
                                            agent.reject_count > 0
                                                ? openModalTimeline(
                                                      agent.rejects
                                                  )
                                                : null
                                        "
                                    >
                                        {{ agent.reject_count }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div
                                        v-if="
                                            agent.status === 'reject' ||
                                            agent.status === 'banned' ||
                                            agent.status === 'accept'
                                        "
                                    >
                                        <img
                                            src="../../assets/check-gray.png"
                                            width="20"
                                            class="me-2"
                                            alt=""
                                        />
                                        <img
                                            src="../../assets/close-gray.png"
                                            width="20"
                                            alt=""
                                        />
                                    </div>
                                    <div v-else>
                                        <a
                                            @click="
                                                openConfirmModalAccept(agent.id)
                                            "
                                            href="javascript:void(0)"
                                            class="text-green size-20 me-2"
                                        >
                                            <img
                                                src="../../assets/check-green.png"
                                                width="20"
                                                alt=""
                                            />
                                        </a>
                                        <a
                                            @click="
                                                openConfirmModalReject(agent.id)
                                            "
                                            href="javascript:void(0)"
                                            class="text-green size-20"
                                        >
                                            <img
                                                src="../../assets/close-red.png"
                                                width="20"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-section" v-if="agents.length === 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalData"
                    :per-page="rowsPerPage"
                    align="right"
                    @change="changePage"
                ></b-pagination>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue" v-if="modalConfirmAccept">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Apakah Kamu Yakin Ingin Menyetujui Registrasi Sales
                        Agent Ini?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalConfirmAccept = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="storeAccept()"
                                :disabled="isAcceptLoading"
                            >
                                <span
                                    v-if="isAcceptLoading"
                                    class="spinner-border spinner-border-sm me-2"
                                ></span>
                                Iya
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessAccept">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/check-new.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Registrasi Sales Agent Ini Berhasil Disetujui
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-12 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="
                                    modalSuccessAccept = false;
                                    fetchData();
                                "
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmReject">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Pendaftaran Sales Agent Ini Akan Ditolak
                    </div>
                    <div class="text-gray-dashboard text-center">
                        Mohon lengkapi checklist sheet dan berikan alasan
                        penolakan
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalConfirmReject = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <router-link
                                :to="`/register-sales-affiliate/reject/${this.selectedAgentIdReject}`"
                                class="btn btn-green w-100 btn-lg"
                            >
                                Ok
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalTimeline">
            <div class="overlay"></div>
            <div class="modal-body-chat sm">
                <h4 class="fw-bold">Alasan Penolakan</h4>
                <ul class="timeline timeline-split mt-4">
                    <li
                        class="timeline-item d-flex"
                        v-for="i in selectedRejections.length"
                        :set="
                            (reject =
                                selectedRejections[
                                    selectedRejections.length - i
                                ])
                        "
                        :key="i"
                    >
                        <div
                            class="timeline-marker"
                            v-bind:class="{
                                'active-red': i == 1,
                            }"
                        ></div>
                        <div class="timeline-content w-100">
                            <div
                                class="timeline-title"
                                v-bind:class="{
                                    'text-dark': i == 1,
                                }"
                            >
                                Penolakan
                                <template v-if="selectedRejections.length == 3">
                                    <template v-if="i == 1">Ketiga</template>
                                    <template v-if="i == 2">Kedua</template>
                                    <template v-if="i == 3">Pertama</template>
                                </template>
                                <template v-if="selectedRejections.length == 2">
                                    <template v-if="i == 1">Kedua</template>
                                    <template v-if="i == 2">Pertama</template>
                                </template>
                                <template v-if="selectedRejections.length == 1">
                                    <template v-if="i == 1">Pertama</template>
                                </template>
                                -
                                <span
                                    v-bind:class="{
                                        'text-danger': i == 1,
                                    }"
                                >
                                    {{ reject.rejected_at }}
                                </span>
                            </div>
                            <div class="mt-3">
                                <small
                                    class="form-box d-block bg-transparent"
                                    v-bind:class="{
                                        reject: i == 1,
                                        gray: i > 1,
                                    }"
                                >
                                    {{ reject.reason }}
                                </small>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="mt-2">
                    <button
                        class="btn btn-green w-100 btn-md"
                        @click="modalTimeline = false"
                    >
                        Tutup
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    data() {
        return {
            showFilter: false,
            search: null,
            regency: null,
            regencies: [],
            selectedStatus: [],
            statuses: [
                { name: "Pending", id: "pending" },
                { name: "Reject", id: "reject" },
                { name: "Resubmit", id: "resubmit" },
                { name: "Banned", id: "banned" },
            ],
            modalConfirmAccept: false,
            modalSuccessAccept: false,
            modalConfirmReject: false,
            agents: [],
            totalData: 0,
            rowsPerPage: 10,
            currentPage: 1,
            isLoading: false,
            isAcceptLoading: false,
            selectedAgentIdAccept: null,
            selectedAgentIdReject: null,
            modalTimeline: false,
            selectedRejections: [],
        };
    },
    created() {
        this.getRegency();
        this.fetchData();
        this.debouncedFilterData = debounce(this.filterData, 1000);
    },
    computed: {
        pagination() {
            const start = (this.currentPage - 1) * this.rowsPerPage + 1;
            const end = Math.min(
                this.currentPage * this.rowsPerPage,
                this.totalData
            );
            return { start, end };
        },
        canFilter() {
            return this.regency !== null && this.selectedStatus.length !== 0;
        },
    },
    methods: {
        openConfirmModalAccept(agentId) {
            this.selectedAgentIdAccept = agentId;
            this.modalConfirmAccept = true;
        },
        openConfirmModalReject(agentId) {
            this.selectedAgentIdReject = agentId;
            this.modalConfirmReject = true;
        },
        openModalTimeline(rejections) {
            this.selectedRejections = rejections;
            this.modalTimeline = true;
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        fetchData() {
            this.isLoading = true;

            const params = {
                search: this.search,
                regency_id: this.regency ? this.regency.id : null,
                status: this.selectedStatus.map((s) => s.id).join(","),
                limit: this.rowsPerPage,
                page: this.currentPage,
            };

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/agent/register`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                    params,
                }
            )
                .then((response) => {
                    const data = response.data.data;
                    this.agents = data.list_data;
                    this.totalData = data.total_data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
        },
        clearFilter() {
            this.regency = null;
            this.selectedStatus = [];
            this.fetchData();
        },
        applyFilter() {
            this.currentPage = 1;
            this.fetchData();
        },
        filterData() {
            this.currentPage = 1;
            this.fetchData();
        },
        changeRowsPerPage() {
            this.currentPage = 1;
            this.fetchData();
        },
        changePage(page) {
            this.currentPage = page;
            this.fetchData();
        },
        storeAccept() {
            this.isAcceptLoading = true;
            const data = {
                agent_id: this.selectedAgentIdAccept,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/agent/register/accept`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isAcceptLoading = false;
                    this.modalConfirmAccept = false;
                    this.modalSuccessAccept = true;
                })
                .catch((err) => {
                    this.isAcceptLoading = false;
                    this.modalConfirmAccept = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.text-link {
    color: #3c87b1 !important;
}
.btn-clear-disabled {
    border: 1px solid #d2d2d5 !important;
    color: #d2d2d5 !important;
}
.btn-filter-disabled {
    background-color: #d2d2d5 !important;
    color: white !important;
    border: none !important;
}
.bg-row {
    background-color: #f6faff;
}
.dropdown-menu.show {
    width: 93%;
}
.dropdown-toggle::after {
    display: none;
}
.arrow-custom::before {
    position: relative;
    right: 8px;
    top: 12px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: "";
}
div::-webkit-scrollbar {
    display: none;
}
.radius-select {
    border: 1px solid #e9ecef !important;
    padding: 0px 7px 0 7px !important;
    min-height: 35px !important;
    border-radius: 5px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.scroll-select .placeholder-custom {
    margin-bottom: 0;
    color: #6c757d;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}

th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.btn-filter {
    background-color: #fbfbfb;
    border: 1px solid #d2d2d5;
}
.btn-filter:hover {
    background-color: #fbfbfb;
    border: 1px solid #d2d2d5;
}
</style>
