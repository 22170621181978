<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoadData"></div>
        <div id="loader" v-if="isLoadData"></div>
        <div
            class="text-black size-16 text-gray-dashboard fw-semibold mb-4 cursor-pointer"
            @click="$router.go(-1)"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </div>
        <div class="box mt-4" v-if="data">
            <h3 class="mb-0 fw-bold">Reject Akun Sales Agent</h3>
            <div class="text-gray-dashboard">
                Mohon tandai berkas sales agent yang tidak sesuai
            </div>
            <div class="row">
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="bank_name"
                            @change="toggleField('bank_id')"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="bank_name"
                        >
                            Nama Bank
                        </label>
                        <div class="form-box mt-2">
                            {{
                                data.bank_account
                                    ? data.bank_account.bank_name
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="account_number"
                            @change="toggleField('account_number')"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="account_number"
                        >
                            Nomor Rekening
                        </label>
                        <div class="form-box mt-2">
                            {{
                                data.bank_account
                                    ? data.bank_account.account_number
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="account_name"
                            @change="toggleField('account_name')"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="account_name"
                        >
                            Nama Pemilik Rekening
                        </label>
                        <div class="form-box mt-2">
                            {{
                                data.bank_account
                                    ? data.bank_account.account_name
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="profile_picture"
                            @change="toggleField('agent_picture')"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="profile_picture"
                        >
                            Foto Profile
                        </label>
                        <div class="mt-2">
                            <viewer
                                :options="options"
                                v-if="data.agent_picture"
                            >
                                <img
                                    class="image-cover"
                                    :src="data.agent_picture"
                                />
                            </viewer>
                            <div
                                v-else
                                class="image-default"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../../assets/empty.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="form-check pl-0">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="identity_picture"
                            @change="toggleField('identity_picture')"
                        />
                        <label
                            class="form-check-label fw-semibold"
                            for="identity_picture"
                        >
                            Foto KTP
                        </label>
                        <div class="mt-2">
                            <viewer
                                :options="options"
                                v-if="data.identity_picture"
                            >
                                <img
                                    class="image-cover"
                                    :src="data.identity_picture"
                                />
                            </viewer>
                            <div
                                v-else
                                class="image-default"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../../assets/empty.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label>Alasan Penolakan</label>
                    <textarea
                        cols="30"
                        rows="5"
                        class="form-control mt-2"
                        placeholder="Tuliskan alasan penolakan driver"
                        v-model="rejectionReason"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <router-link
                        to="/register-sales-affiliate"
                        class="btn btn-outline-red btn-lg px-4 me-3"
                    >
                        Batal
                    </router-link>
                    <button
                        class="btn btn-lg px-5"
                        :class="{
                            'btn-filter-disabled': !isFormValid,
                            'btn-green': isFormValid,
                        }"
                        @click="modalConfirm = true"
                        :disabled="!isFormValid"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Registrasi Sales Agent Ini Akan Ditolak
                    </div>
                    <div class="mt-3">
                        <label class="fw-semibold text-gray-dashboard"
                            >Alasan Penolakan</label
                        >
                        <div class="form-box mt-2">
                            {{ rejectionReason }}
                        </div>
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalConfirm = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="storeData()"
                            >
                                <span
                                    v-if="isLoading"
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Registrasi Sales Agent Ini Sudah Ditolak!
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/register-sales-affiliate"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    data() {
        return {
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            modalConfirm: false,
            modalSuccess: false,
            data: null,
            isLoadData: false,
            selectedFields: [],
            rejectionReason: "",
            isLoading: false,
        };
    },
    created() {
        this.getDetail();
    },
    computed: {
        isFormValid() {
            return (
                this.selectedFields.length > 0 &&
                this.rejectionReason &&
                this.rejectionReason.trim() !== ""
            );
        },
    },
    methods: {
        getDetail() {
            this.isLoadData = true;
            const params = {
                id: this.$route.params.id,
            };

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/agent/detail`,
                {
                    params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.isLoadData = false;
                })
                .catch((err) => {
                    this.isLoadData = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        toggleField(field) {
            const index = this.selectedFields.indexOf(field);
            if (index > -1) {
                this.selectedFields.splice(index, 1);
            } else {
                this.selectedFields.push(field);
            }
        },
        storeData() {
            this.isLoading = true;
            const payload = {
                agent_id: this.$route.params.id,
                reason: this.rejectionReason,
                fields: this.selectedFields.join(","),
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/agent/register/reject`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.modalConfirm = false;
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.btn-filter-disabled {
    background-color: #d2d2d5 !important;
    color: white !important;
    border: none !important;
}
.form-input {
    background: rgba(239, 241, 249, 0.6);
    border-width: 0px;
    border: none;
    outline: none;
    border-radius: 10px;
    width: 258px;
    height: 40px;
    padding: 10px;
}
.image-cover {
    border-radius: 10px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid #f7f7f7;
}
.image-default {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    border: 2px solid #f7f7f7;
}
.checkbox input[type="checkbox"] {
    display: none;
}
.form-check-input {
    display: none !important;
}
.form-check label {
    padding-left: 0;
    cursor: pointer;
}

.form-check label:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 3px;
    font-family: "FontAwesome";
    cursor: pointer;
}
.form-check input[type="checkbox"]:checked + label::before {
    content: "\f00d";
    background: #faccd0;
    color: #e60013;
    border: 1px solid #e60013;
}
</style>
