import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import NewPassword from "../views/NewPassword.vue";
import ForgotOtp from "../views/Otp.vue";
import DashboardFinance from "../views/dashboard/DashboardFinance.vue";
import DashboardOperation from "../views/dashboard/DashboardOperation.vue";
import DashboardTrawltruck from "../views/dashboard/DashboardTrawltruck.vue";
import DashboardTrawltruckOperation from "../views/dashboard/DashboardTrawltruckOperation.vue";
import DashboardTrawltruckFinance from "../views/dashboard/DashboardTrawltruckFinance.vue";
import DashboardTrawltruckMitra from "../views/dashboard/DashboardTrawltruckMitra.vue";
import DashboardIncomeMB from "../views/dashboard/DashboardIncomeMB.vue";
import DashboardItemMB from "../views/dashboard/DashboardItemMB.vue";
import DashboardIncomeMPW from "../views/dashboard/DashboardIncomeMPW.vue";
import DashboardItemMPW from "../views/dashboard/DashboardItemMPW.vue";
import DashboardIncomeMTAK from "../views/dashboard/DashboardIncomeMTAK.vue";
import DashboardCRM from "../views/dashboard/DashboardCRM.vue";
import DashboardCRMStaff from "../views/dashboard/DashboardCRMStaff.vue";
import Chat from "../views/Chat.vue";
import CustomerPayment from "../views/CustomerPayment.vue";
import IncomeMitraBusiness from "../views/IncomePartner/IncomeMitraBusiness.vue";
import IncomeMitraBusinessDetail from "../views/IncomePartner/IncomeMitraBusinessDetail.vue";
import IncomeMitraSpace from "../views/IncomePartner/IncomeMitraSpace";
import IncomeMitraSpaceDetail from "../views/IncomePartner/IncomeMitraSpaceDetail";
import IncomeMitraPoolWarehouse from "../views/IncomePartner/IncomeMitraPoolWarehouse";
import IncomeMitraPoolWarehouseDetail from "../views/IncomePartner/IncomeMitraPoolWarehouseDetail";
import IncomeMitraTransporter from "../views/IncomePartner/IncomeMitraTransporter";
import IncomeMitraTransporterDetail from "../views/IncomePartner/IncomeMitraTransporterDetail";
import IncomeMitraPos from "../views/IncomePartner/IncomeMitraPos";
import IncomeMitraPosDetail from "../views/IncomePartner/IncomeMitraPosDetail";
import PartnerDisbursement from "../views/disbursement/PartnerDisbursement";
import PartnerDisbursementDetail from "../views/disbursement/PartnerDisbursementDetail";
import AgentDisbursement from "../views/disbursement/AgentDisbursement";
import AgentDisbursementDetail from "../views/disbursement/AgentDisbursementDetail";
import WarehouseMB from "../views/warehouse/WarehouseMB";
import WarehouseMBDetail from "../views/warehouse/WarehouseMBDetail";
import WarehouseMPW from "../views/warehouse/WarehouseMPW";
import WarehouseMPWDetail from "../views/warehouse/WarehouseMPWDetail";
import WarehouseMS from "../views/warehouse/WarehouseMS";
import WarehouseMSDetail from "../views/warehouse/WarehouseMSDetail";
import CheckManifest from "../views/trackingOrder/Manifest";
import TrackingManifestDetail from "../views/trackingOrder/ManifestDetail";
import Receipt from "../views/trackingOrder/Receipt";
import AssignTransporter from "../views/trackingOrder/AssignTransporter";
import CorporateList from "../views/corporate/Index";
import EditMitra from "../views/corporate/EditMitra";
import AdminTrawlpackPartnerIncomingOrder from "../views/corporate/IncomingOrder";
import CorporateCreate from "../views/corporate/Create";
import CorporateEdit from "../views/corporate/Edit";
import OrderList from "../views/order_trawlcareer/Index";
import OrderDetail from "../views/order_trawlcareer/OrderDetail";
import OrderIncoming from "../views/order_trawlcareer/Incoming";
import OrderConfirmation from "../views/order_trawlcareer/Confirmation";
import Manifest from "../views/manifest/Index";
import ManifestDetail from "../views/manifest/Detail";
import IncomeList from "../views/wallet/Income";
import DisbursementList from "../views/wallet/Disbursement";
import DisbursementRequest from "../views/wallet/Request";
import Profile from "../views/profile/Index";
import Vehicle from "../views/vehicle/Index";
import CreateVehicle from "../views/vehicle/CreateVehicle";
import EditVehicle from "../views/vehicle/EditVehicle";
import Driver from "../views/vehicle/Driver";
import Assignment from "../views/vehicle/Assignment";
import Payment from "../views/corporate/Detail";
import TrawlCareerOrderList from "../views/trawlcareer/OrderList";
import TrawlCareerOrderAssignMitra from "../views/trawlcareer/AssignMitra";
import TrawlCareerVehicleList from "../views/trawlcareer/VehicleList";
import TrawlCareerVehicleCreate from "../views/trawlcareer/CreateVehicle";
import TrawlCareerDriverList from "../views/trawlcareer/DriverList";
import TrawlCareerDriverHistory from "../views/trawlcareer/DriverHistory";
import TrawlCareerDriverTrack from "../views/trawlcareer/DriverTrack";
import TrawlCareerDriverCreate from "../views/trawlcareer/CreateDriver";
import TrawlCareerDisbursement from "../views/trawlcareer/Disbursement";
import TrawlCareerDisbursementDetail from "../views/trawlcareer/DetailDisbursement";
import TrawlCareerManifest from "../views/trawlcareer/Manifest";
import TrawlCareerManifestDetail from "../views/trawlcareer/DetailManifest";
import TrawltruckAccountDriver from "../views/trawltruck/AccountDriver.vue";
import TrawltruckDetailAccountDriver from "../views/trawltruck/DetailAccountDriver.vue";
import TrawltruckRegisterDriver from "../views/trawltruck/RegisterDriver.vue";
import TrawltruckRegisterDriverReject from "../views/trawltruck/RegisterDriverReject.vue";
import TrawltruckSuspendDriver from "../views/trawltruck/SuspendDriver.vue";
import TrawltruckDisbursement from "../views/trawltruck/DisbursementOfFunds.vue";
import TrawltruckDisbursementDetail from "../views/trawltruck/DisbursementOfFundsDetail.vue";
import TrawltruckTrackingOrder from "../views/trawltruck/TrackingOrder.vue";
import TrawltruckTrackingOrderDetail from "../views/trawltruck/TrackingOrderDetail.vue";
import TrawltruckAssignDriver from "../views/trawltruck/AssignDriver.vue";
import TrawltruckAssignDriverDetail from "../views/trawltruck/DetailAccountDriver.vue";
import ManagementDriver from "../views/trawltruckCorporate/ManagementDriver.vue";
import DetailManagementDriver from "../views/trawltruckCorporate/DetailManagementDriver.vue";
import ManagementFleet from "../views/trawltruckCorporate/ManagementFleet.vue";
import DetailManagementFleet from "../views/trawltruckCorporate/DetailManagementFleet.vue";
import IncomingOrder from "../views/trawltruckCorporate/IncomingOrder.vue";
import ManagementOrder from "../views/trawltruckCorporate/ManagementOrder.vue";
import ManagementOrderDetail from "../views/trawltruckCorporate/ManagementOrderDetail.vue";
import ManagementDisbursement from "../views/trawltruckCorporate/ManagementDisbursement.vue";
import TopupTrawltruckCorporate from "../views/trawltruckCorporate/Topup.vue";
import ProfileTrawltruckCorporate from "../views/trawltruckCorporate/Profile.vue";
import TrawltruckCubicPrice from "../views/trawltruck/CubicPrice.vue";
import ProfileDashboardOwner from "../views/dashboardOwner/Profile.vue";
import OwnerIncomeMB from "../views/dashboardOwner/IncomeOrderMB.vue";
import OwnerIncomeMPW from "../views/dashboardOwner/IncomeOrderMPW.vue";
import OwnerIncomeMTAK from "../views/dashboardOwner/IncomeOrderMTAK.vue";
import StatusManifestMB from "../views/dashboardOwner/StatusManifestMB.vue";
import StatusManifestMPW from "../views/dashboardOwner/StatusManifestMPW.vue";
import StatusManifestMTAK from "../views/dashboardOwner/StatusManifestMTAK.vue";
import WarehouseOwnerMB from "../views/dashboardOwner/WarehouseMB.vue";
import MeasureWeight from "../views/dashboardOwner/MeasureWeight.vue";
import Packing from "../views/dashboardOwner/Packing.vue";
import ReadyToDelivery from "../views/dashboardOwner/ReadyToDelivery.vue";
import WarehouseOwnerMPW from "../views/dashboardOwner/WarehouseMPW.vue";
import Employee from "../views/dashboardOwner/Employee.vue";
import Transporter from "../views/dashboardOwner/Transporter.vue";
import ManagementMitra from "../views/trawltruck/ManagementMitra.vue";
import ManagementMitraCreate from "../views/trawltruck/ManagementMitraCreate.vue";
import ManagementMitraEdit from "../views/trawltruck/ManagementMitraEdit.vue";
import ManagementMitraDetail from "../views/trawltruck/ManagementMitraDetail.vue";
import ManagementTruck from "../views/trawltruck/ManagementTruck.vue";
import ManagementTruckCreate from "../views/trawltruck/ManagementTruckCreate.vue";
import ManagementTruckEdit from "../views/trawltruck/ManagementTruckEdit.vue";
import ManagementTruckDetail from "../views/trawltruck/ManagementTruckDetail.vue";
import ManagementDrivers from "../views/trawltruck/ManagementDrivers.vue";
import ManagementDriversCreate from "../views/trawltruck/ManagementDriversCreate.vue";
import ManagementDriversEdit from "../views/trawltruck/ManagementDriversEdit.vue";
import ManagementDriversDetail from "../views/trawltruck/ManagementDriversDetail.vue";
import ManagementDeliveryPayment from "../views/trawltruck/ManagementDeliveryPayment.vue";
import ManagementDeliveryPaymentEdit from "../views/trawltruck/ManagementDeliveryPaymentEdit.vue";
import ManagementDeliveryPaymentDetail from "../views/trawltruck/ManagementDeliveryPaymentDetail.vue";
import ManagementHoDisbursement from "../views/trawltruck/ManagementHoDisbursement.vue";
import ManagementHoDisbursementDetail from "../views/trawltruck/ManagementHoDisbursementDetail.vue";
import ManagementMasterFleet from "../views/trawltruck/ManagementMasterFleet.vue";
import ManagementMasterFleetCreate from "../views/trawltruck/ManagementMasterFleetCreate.vue";
import ManagementMasterFleetEdit from "../views/trawltruck/ManagementMasterFleetEdit.vue";
import ManagementMasterFleetDetail from "../views/trawltruck/ManagementMasterFleetDetail.vue";
import ManagementMasterRoute from "../views/trawltruck/ManagementMasterRoute.vue";
import ManagementMasterRouteCreate from "../views/trawltruck/ManagementMasterRouteCreate.vue";
import ManagementMasterRouteEdit from "../views/trawltruck/ManagementMasterRouteEdit.vue";
import ManagementAggrement from "../views/trawltruck/ManagementAggrement.vue";
import ManagementAggrementDetail from "../views/trawltruck/ManagementAggrementDetail.vue";
import ManagementAggrementEdit from "../views/trawltruck/ManagementAggrementEdit.vue";
import ManagementTopup from "../views/trawltruck/ManagementTopup.vue";
import ManagementTopupDetail from "../views/trawltruck/ManagementTopupDetail.vue";
import ManagementTopupEdit from "../views/trawltruck/ManagementTopupEdit.vue";
import ManagementTruckStandBy from "../views/trawltruck/ManagementTruckStandBy.vue";
import ManagementTruckStandByEdit from "../views/trawltruck/ManagementTruckStandByEdit.vue";
import ManagementHoOrder from "../views/trawltruck/ManagementHoOrder.vue";
import ManagementHoOrderDetail from "../views/trawltruck/ManagementHoOrderDetail.vue";
import ManagementHoOrderDetailOld from "../views/trawltruck/ManagementHoOrderDetailOld.vue";
import ManagementHoOrderCreate from "../views/trawltruck/ManagementHoOrderCreate.vue";
import ManagementHoOrderEdit from "../views/trawltruck/ManagementHoOrderEdit.vue";
import ManagementHoOrderEditStatus from "../views/trawltruck/ManagementHoOrderEditStatus.vue";
import TrawlpackSendInvoice from "../views/trawlpack/TrawlpackSendInvoice.vue";
import TrawlpackSendInvoiceCreate from "../views/trawlpack/TrawlpackSendInvoiceCreate.vue";
import TrawlpackInvoice from "../views/trawlpack/TrawlpackInvoice.vue";
import GratitudeJournal from "../views/crm/GratitudeJournal.vue";
import GratitudeJournalCreate from "../views/crm/GratitudeJournalCreate.vue";
import GratitudeJournalUser from "../views/crm/GratitudeJournalUser.vue";
import GratitudeJournalUserDetail from "../views/crm/GratitudeJournalUserDetail.vue";
import CrmOrder from "../views/crm/Order.vue";
import CrmOrderDetail from "../views/crm/CrmOrderDetail.vue";
import CrmTarget from "../views/crm/TargetHistory.vue";
import CrmOrderCreate from "../views/crm/OrderCreate.vue";
import CrmOrderEdit from "../views/crm/OrderEdit.vue";
import crmSpecialist from "../views/crm/Specialist.vue";
import crmSpecialistDetail from "../views/crm/SpecialistDetail.vue";
import crmSpecialistForm from "../views/crm/SpecialistForm.vue";
import crmCustomers from "../views/crm/Customers.vue";
import crmCustomersOrdered from "../views/crm/CustomersOrdered.vue";
import crmCustomersRegistered from "../views/crm/CustomerRegistered.vue";
import crmCustomersLeads from "../views/crm/CustomersLeads.vue";
import crmCustomersLeadsDetail from "../views/crm/CustomersLeadsDetail.vue";
import crmCustomersLeadsTags from "../views/crm/CustomersLeadsTags.vue";
import crmCustomersForm from "../views/crm/CustomersForm.vue";
import crmCustomersFormEdit from "../views/crm/CustomersFormEdit.vue";
import crmCustomerLeadsProspect from "../views/crm/CustomerLeadsProspect.vue";
import crmCustomerOrderedDetails from "../views/crm/CustomerOrderedDetails.vue";
import crmCustomerOrderedHistory from "../views/crm/CustomerOrderedHistory.vue";
import crmCustomerOrderedProspect from "../views/crm/CustomerOrderedProspect.vue";
import crmCustomerRegisteredProspect from "../views/crm/CustomerRegisteredProspect.vue";
import crmCustomerRegisteredDetail from "../views/crm/CustomerRegisteredDetail.vue";
import crmProspect from "../views/crm/Prospect.vue";
import crmProspectAll from "../views/crm/ProspectAll.vue";
import crmProspectToday from "../views/crm/ProspectToday.vue";
import crmProspectTomorrow from "../views/crm/ProspectTomorrow.vue";
import crmProspectNextTwoDays from "../views/crm/ProspectNextTwoDays.vue";
import crmProspectNextWeek from "../views/crm/ProspectNextWeek.vue";
import crmProspectNextMonth from "../views/crm/ProspectNextMonth.vue";
import crmProspectNeedFollowUp from "../views/crm/ProspectNeedFollowUp.vue";
import crmProspectDealt from "../views/crm/ProspectDealt.vue";
import crmProspectDetail from "../views/crm/ProspectDetail.vue";
import crmProspectLost from "../views/crm/ProspectLost.vue";
import crmProspectPrediction from "../views/crm/ProspectPrediction.vue";
import crmProfile from "../views/crm/CrmProfile.vue";
import crmProfileEdit from "../views/crm/CrmProfileEdit.vue";
import dashboardTrawlcarrierFinance from "../views/dashboard/DashboardTrawlcarrierFinance.vue";
import DashboardTrawlcarrierOperation from "../views/dashboard/DashboardTrawlcarrierOperation.vue";
import carrierHoRefund from "../views/trawlcareer/CarrierHoRefund.vue";
import carrierHoRefundDetail from "../views/trawlcareer/CarrierHoRefundDetail.vue";
import crmTags from "../views/crm/crmTags.vue";
import crmTagsCreate from "../views/crm/crmTagsCreate.vue";
import crmTagsDetail from "../views/crm/crmTagsDetail.vue";
import crmBranchs from "../views/crm/crmBranchs.vue";
import crmBranchsCreate from "../views/crm/crmBranchsCreate.vue";
import crmBranchsEdit from "../views/crm/crmBranchsEdit.vue";
import crmBranchsDetail from "../views/crm/crmBranchsDetail.vue";
import crmPartnershipRegistered from "../views/crm/crmPartnershipRegistered";
import crmPartnershipRegisteredDetail from "../views/crm/crmPartnershipRegisteredDetail";
import crmPartnershipCandidate from "../views/crm/crmPartnershipCandidate";
import crmPartnershipCandidateCreate from "../views/crm/crmPartnershipCandidateCreate";
import crmPartnershipCandidateEdit from "../views/crm/crmPartnershipCandidateEdit";
import crmPartnershipCandidateProspect from "../views/crm/crmPartnershipCandidateProspect";
import crmPartnershipCandidateDetail from "../views/crm/crmPartnershipCandidateDetail";
import crmPartnershipProspectToday from "../views/crm/crmPartnershipProspectToday";
import crmPartnershipProspectTomorrow from "../views/crm/crmPartnershipProspectTomorrow";
import crmPartnershipProspectNextTwoDays from "../views/crm/crmPartnershipProspectNextTwoDays";
import crmPartnershipProspectNextWeek from "../views/crm/crmPartnershipProspectNextWeek";
import crmPartnershipProspectNextMonth from "../views/crm/crmPartnershipProspectNextMonth";
import crmPartnershipProspectNextNeedFollowUp from "../views/crm/crmPartnershipProspectNextNeedFollowUp";
import crmPartnershipProspectDetail from "../views/crm/crmPartnershipProspectDetail";
import crmPartnershipRequestedTrawlcarrier from "../views/crm/crmPartnershipRequestedTrawlcarrier";
import crmPartnershipRequestedTrawlcarrierDetail from "../views/crm/crmPartnershipRequestedTrawlcarrierDetail";
import crmPartnershipRequestedTrawlpack from "../views/crm/crmPartnershipRequestedTrawlpack";
import crmPartnershipRequestedTrawlpackDetail from "../views/crm/crmPartnershipRequestedTrawlpackDetail";
import TrawltruckHoCekTarif from "../views/trawltruck/TrawltruckHoCekTarif";
import NotificationPage from "../views/Notification";
import TrawltruckMitra from "../views/trawltruck/TrawltruckMitra";
import TrawltruckMitraDetail from "../views/trawltruck/TrawltruckMitraDetail";
import TrawltruckMitraEdit from "../views/trawltruck/TrawltruckMitraEdit";
import TrawltruckMitraCreate from "../views/trawltruck/TrawltruckMitraCreate";
import crmFollowUpCro from "../views/crm/FollowUpCro.vue";
import CrmOrderLost from "../views/crm/CrmOrderLost.vue";
import CrmOrderLostDetail from "../views/crm/CrmOrderLostDetail.vue";
import Harbors from "../views/Harbors";
import HarborsCreate from "../views/HarborsCreate";
import HarborsEdit from "../views/HarborsEdit";
import TroCarrierPricing from "../views/trawlcareer/CarrierPricing";
import MasterCustomer from "../views/Customer.vue";
import MasterCustomerDetail from "../views/CustomerDetail.vue";
import MasterUser from "../views/MasterUser.vue";
import MasterUserDetail from "../views/MasterUserDetail.vue";
import TrawltruckWithdrawIndividu from "../views/trawltruck/Withdraw.vue";
import TrawltruckWithdrawIndividuDetail from "../views/trawltruck/WithdrawDetail.vue";
import TrawltruckMitraBalance from "../views/trawltruck/MitraBalance.vue";
import TrawltruckMitraBalanceDetail from "../views/trawltruck/MitraBalanceDetail.vue";
import TrotruckSticker from "../views/trawltruck/Sticker";
import TrotruckStickerReject from "../views/trawltruck/StickerReject";
import TrotruckStickerDetail from "../views/trawltruck/StickerDetail";
import TruckCommissionSticker from "../views/trawltruck/CommisionSticker";
import TruckCommissionStickerDetail from "../views/trawltruck/CommisionStickerDetail";
import TruckCommissionStickerGa from "../views/trawltruck/CommisionStickerGa";
import TruckCommissionStickerGaDetail from "../views/trawltruck/CommisionStickerGaDetail";
import TruckCommissionStickerGaReject from "../views/trawltruck/CommisionStickerGaReject";
import SetHarga from "../views/SetHarga.vue";
import RegisterSalesAffiliate from "../views/salesAffiliate/RegisterSalesAffiliate.vue";
import RegisterSalesAffiliateReject from "../views/salesAffiliate/RegisterSalesAffiliateReject.vue";
import RegisterSalesAffiliateDetail from "../views/salesAffiliate/RegisterSalesAffiliateDetail.vue";
import AccountSalesAffiliate from "../views/salesAffiliate/AccountSalesAffiliate.vue";
import AccountSalesAffiliateDetail from "../views/salesAffiliate/AccountSalesAffiliateDetail.vue";
import DisbursementSalesAffiliate from "../views/salesAffiliate/DisbursementSalesAffiliate.vue";
import DisbursementSalesAffiliateDetail from "../views/salesAffiliate/DisbursementSalesAffiliateDetail.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: Login,
    },
    {
        path: "/forgot/form",
        name: "forgot-password",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: ForgotPassword,
    },
    {
        path: "/forgot/new-password/:id",
        name: "new-password",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: NewPassword,
    },
    {
        path: "/forgot/otp",
        name: "forgot-otp",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: ForgotOtp,
    },
    {
        path: "/trawlpack/ho/finance",
        name: "dashboard-finance",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardFinance,
    },
    {
        path: "/trawltruck/ho/partner",
        name: "management-mitra",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMitra,
    },
    {
        path: "/trawltruck/ho/partner/create",
        name: "management-mitra-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMitraCreate,
    },
    {
        path: "/trawltruck/ho/partner/edit/:id",
        name: "management-mitra-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMitraEdit,
    },
    {
        path: "/trawltruck/ho/partner/detail/:id",
        name: "management-mitra-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMitraDetail,
    },
    {
        path: "/trawltruck/ho/fleet",
        name: "management-truck",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTruck,
    },
    {
        path: "/trawltruck/ho/fleet/create",
        name: "management-truck-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTruckCreate,
    },
    {
        path: "/trawltruck/ho/fleet/edit/:id",
        name: "management-truck-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTruckEdit,
    },
    {
        path: "/trawltruck/ho/fleet/detail/:id",
        name: "management-truck-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTruckDetail,
    },
    {
        path: "/trawltruck/ho/driver",
        name: "management-drivers",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDrivers,
    },
    {
        path: "/trawltruck/ho/driver/create",
        name: "management-drivers-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDriversCreate,
    },
    {
        path: "/trawltruck/ho/driver/edit/:id",
        name: "management-drivers-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDriversEdit,
    },
    {
        path: "/trawltruck/ho/driver/detail/:id",
        name: "management-drivers-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDriversDetail,
    },
    {
        path: "/trawltruck/ho/delivery-payment",
        name: "management-delivery-payment",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDeliveryPayment,
    },
    {
        path: "/trawltruck/ho/delivery-payment/edit/:id",
        name: "management-delivery-payment-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDeliveryPaymentEdit,
    },
    {
        path: "/trawltruck/ho/delivery-payment/detail/:id",
        name: "management-delivery-payment-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDeliveryPaymentDetail,
    },
    {
        path: "/trawltruck/ho/disbursement",
        name: "management-ho-disbursement",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoDisbursement,
    },
    {
        path: "/trawltruck/ho/disbursement/detail/:id",
        name: "management-disbursement-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoDisbursementDetail,
    },
    {
        path: "/trawltruck/ho/master/fleet",
        name: "management-master-fleet",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMasterFleet,
    },
    {
        path: "/trawltruck/ho/master/fleet/create",
        name: "management-master-fleet-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMasterFleetCreate,
    },
    {
        path: "/trawltruck/ho/master/fleet/edit/:id",
        name: "management-master-fleet-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMasterFleetEdit,
    },
    {
        path: "/trawltruck/ho/master/fleet/detail/:id",
        name: "management-master-fleet-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMasterFleetDetail,
    },
    {
        path: "/trawltruck/ho/master/route",
        name: "management-master-route",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMasterRoute,
    },
    {
        path: "/trawltruck/ho/master/route/create",
        name: "management-master-route-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMasterRouteCreate,
    },
    {
        path: "/trawltruck/ho/master/route/edit/:id",
        name: "management-master-route-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementMasterRouteEdit,
    },
    {
        path: "/trawltruck/ho/aggrement",
        name: "management-aggrement",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementAggrement,
    },
    {
        path: "/trawltruck/ho/aggrement/detail/:id",
        name: "management-aggrement-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementAggrementDetail,
    },
    {
        path: "/trawltruck/ho/aggrement/edit/:id",
        name: "management-aggrement-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementAggrementEdit,
    },
    {
        path: "/trawltruck/ho/topup",
        name: "management-topup",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTopup,
    },
    {
        path: "/trawltruck/ho/topup/detail/:id",
        name: "management-topup-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTopupDetail,
    },
    {
        path: "/trawltruck/ho/topup/edit/:id",
        name: "management-topup-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTopupEdit,
    },
    {
        path: "/trawltruck/ho/truck-stand-by",
        name: "management-truck-stand-by",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTruckStandBy,
    },
    {
        path: "/trawltruck/ho/truck-stand-by/edit/:id",
        name: "management-truck-stand-by-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementTruckStandByEdit,
    },
    {
        path: "/trawltruck/ho/order",
        name: "management-ho-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoOrder,
    },
    {
        path: "/trawltruck/ho/order/detail/:id",
        name: "management-ho-order-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoOrderDetail,
    },
    {
        path: "/trawltruck/ho/order-detail/:id",
        name: "management-ho-order-detail-old",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoOrderDetailOld,
    },
    {
        path: "/trawltruck/ho/order/create/",
        name: "management-ho-order-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoOrderCreate,
    },
    {
        path: "/trawltruck/ho/order/edit/:id",
        name: "management-ho-order-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoOrderEdit,
    },
    {
        path: "/trawltruck/ho/order/edit-status/:id",
        name: "management-ho-order-edit-status",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementHoOrderEditStatus,
    },
    {
        path: "/trawlpack/ho/operation",
        name: "dashboard-operation",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardOperation,
    },
    {
        path: "/trawltruck/ho/dashboard",
        name: "dashboard-trawltruck-ho",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardTrawltruck,
    },
    {
        path: "/trawltruck/ho/dashboard/operation",
        name: "dashboard-trawltruck-ho-operation",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardTrawltruckOperation,
    },
    {
        path: "/trawltruck/ho/dashboard/finance",
        name: "dashboard-trawltruck-ho-finance",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardTrawltruckFinance,
    },
    {
        path: "/trawltruck/partner/dashboard",
        name: "dashboard-trawltruck-mitra",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardTrawltruckMitra,
    },
    {
        path: "/trawlpack/partner/dashboard/income",
        name: "dashboard-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardIncomeMB,
    },
    {
        path: "/trawlpack/partner/dashboard/warehouse",
        name: "dashboard-item-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardItemMB,
    },
    {
        path: "/trawlpack/partner/dashboard/income",
        name: "dashboard-mpw",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardIncomeMPW,
    },
    {
        path: "/trawlpack/partner/dashboard/warehouse",
        name: "dashboard-item-mpw",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardItemMPW,
    },
    {
        path: "/trawlpack/partner/dashboard/income",
        name: "dashboard-mtak",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardIncomeMTAK,
    },
    {
        path: "/crm/dashboard/specialist",
        name: "dashboard-crm-staff",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardCRMStaff,
    },
    {
        path: "/crm/dashboard",
        name: "dashboard-crm",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardCRM,
    },
    {
        path: "/chat",
        name: "Chat",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Chat,
    },
    {
        path: "/trawlpack/ho/finance/customer-payment",
        name: "admin-customer-payment",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CustomerPayment,
    },
    // TRAWLVAREER
    {
        path: "/trawlcarrier/ho/order",
        name: "admin-trawlcareer-order-list",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerOrderList,
    },
    {
        path: "/trawlcarrier/ho/order/assign-mitra/:id",
        name: "admin-trawlcareer-order-assign-mitra",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerOrderAssignMitra,
    },
    {
        path: "/trawlcarrier/ho/fleet",
        name: "admin-trawlcareer-vehicle-list",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerVehicleList,
    },
    {
        path: "/trawlcareer/vehicle/create",
        name: "admin-trawlcareer-vehicle-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerVehicleCreate,
    },
    {
        path: "/trawlcarrier/ho/driver",
        name: "admin-trawlcareer-driver-list",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerDriverList,
    },
    {
        path: "/trawlcareer/driver/history/:id",
        name: "admin-trawlcareer-driver-history",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerDriverHistory,
    },
    {
        path: "/trawlcareer/driver/track/:id",
        name: "admin-trawlcareer-driver-track",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerDriverTrack,
    },
    {
        path: "/trawlcareer/driver/create",
        name: "admin-trawlcareer-driver-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerDriverCreate,
    },
    {
        path: "/trawlcarrier/ho/disbursement",
        name: "admin-trawlcareer-disbursement",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerDisbursement,
    },
    {
        path: "/trawlcarrier/ho/disbursement/:id",
        name: "admin-trawlcareer-disbursement-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerDisbursementDetail,
    },
    {
        path: "/trawlcarrier/ho/manifest",
        name: "admin-trawlcareer-manifest",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerManifest,
    },
    {
        path: "/trawlcarrier/ho/manifest/:id",
        name: "admin-trawlcareer-manifest-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerManifestDetail,
    },
    {
        path: "/trawlcarrier/ho/manifest",
        name: "admin-trawlcareer-manifest-asal",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlCareerManifest,
    },
    // END TRAWLCAREER

    {
        path: "/trawlpack/general/order",
        name: "admin-corporate-list",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CorporateList,
    },
    {
        path: "/trawlpack/mitra/:id",
        name: "admin-trawlpack-mitra-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: EditMitra,
    },
    {
        path: "/trawlpack/partner/incoming-order",
        name: "admin-trawlpack-partner-incoming-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AdminTrawlpackPartnerIncomingOrder,
    },
    {
        path: "/trawlpack/general/order/create",
        name: "admin-corporate-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CorporateCreate,
    },
    {
        path: "/trawlpack/general/order/:id",
        name: "admin-corporate-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CorporateEdit,
    },
    {
        path: "/trawlcarrier/partner/order",
        name: "admin-order-list",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderList,
    },
    {
        path: "/trawlcarrier/partner/order/:id",
        name: "admin-order-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderDetail,
    },
    {
        path: "/trawlcarrier/partner/incoming-order",
        name: "admin-order-incoming",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderIncoming,
    },
    {
        path: "/trawlcarrier/partner/order-confirmation",
        name: "admin-order-confirmation",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OrderConfirmation,
    },
    {
        path: "/trawlcarrier/partner/manifest",
        name: "admin-manifest",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Manifest,
    },
    {
        path: "/trawlcarrier/partner/manifest/:id",
        name: "admin-manifest-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManifestDetail,
    },
    {
        path: "/corporate/order-detail/:id",
        name: "admin-payment",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Payment,
    },
    {
        path: "/trawlcarrier/partner/wallet/income",
        name: "admin-wallet-income",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeList,
    },
    {
        path: "/trawlcarrier/partner/wallet/disbursement",
        name: "admin-wallet-disbursement",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DisbursementList,
    },
    {
        path: "/wallet/disbursement/request",
        name: "admin-wallet-disbursement-request",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DisbursementRequest,
    },
    {
        path: "/profile",
        name: "admin-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Profile,
    },
    {
        path: "/trawlcarrier/partner/fleet",
        name: "admin-vehicle",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Vehicle,
    },
    {
        path: "/vehicle/create",
        name: "admin-vehicle-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CreateVehicle,
    },
    {
        path: "/vehicle/edit/:id",
        name: "admin-vehicle-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: EditVehicle,
    },
    {
        path: "/trawlcarrier/partner/driver",
        name: "admin-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Driver,
    },
    {
        path: "/trawlcarrier/partner/assign",
        name: "admin-assignment",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Assignment,
    },
    {
        path: "/trawlpack/ho/income/mitra-business",
        name: "admin-income-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraBusiness,
    },
    {
        path: "/trawlpack/ho/income/mitra-business/:partnerid",
        name: "admin-income-mb-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraBusinessDetail,
    },
    {
        path: "/trawlpack/ho/income/mitra-space",
        name: "admin-income-ms",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraSpace,
    },
    {
        path: "/trawlpack/ho/income/mitra-space/:partnerid",
        name: "admin-income-ms-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraSpaceDetail,
    },
    {
        path: "/trawlpack/ho/income/mitra-pool-warehouse",
        name: "admin-income-mpw",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraPoolWarehouse,
    },
    {
        path: "/trawlpack/ho/income/mitra-pool-warehouse/:partnerid",
        name: "admin-income-mpw-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraPoolWarehouseDetail,
    },
    {
        path: "/trawlpack/ho/income/mitra-transporter",
        name: "admin-income-mt",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraTransporter,
    },
    {
        path: "/trawlpack/ho/income/mitra-transporter/:partnerid",
        name: "admin-income-mt-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraTransporterDetail,
    },
    {
        path: "/trawlpack/ho/income/mitra-pos",
        name: "admin-income-mp",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraPos,
    },
    {
        path: "/trawlpack/ho/income/mitra-pos/:partnerid",
        name: "admin-income-mp-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomeMitraPosDetail,
    },
    {
        path: "/trawlpack/ho/disbursement/partner",
        name: "partner-disbursement",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: PartnerDisbursement,
    },
    {
        path: "/trawlpack/ho/disbursement/partner/:partnerid",
        name: "partner-disbursement-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: PartnerDisbursementDetail,
    },
    {
        path: "/trawlpack/ho/disbursement/sales-agent",
        name: "agent-disbursement",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AgentDisbursement,
    },
    {
        path: "/trawlpack/ho/disbursement/sales-agent/:user_id",
        name: "agent-disbursement-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AgentDisbursementDetail,
    },
    {
        path: "/trawlpack/ho/inventory/mitra-business",
        name: "warehouse-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseMB,
    },
    {
        path: "/trawlpack/ho/inventory/mitra-business/:id",
        name: "warehouse-mb-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseMBDetail,
    },
    {
        path: "/trawlpack/ho/inventory/mitra-warehouse",
        name: "warehouse-mpw",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseMPW,
    },
    {
        path: "/trawlpack/ho/inventory/mitra-warehouse/:id",
        name: "warehouse-mpw-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseMPWDetail,
    },
    {
        path: "/trawlpack/ho/inventory/mitra-space",
        name: "warehouse-ms",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseMS,
    },
    {
        path: "/trawlpack/ho/inventory/mitra-space/:id",
        name: "warehouse-ms-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseMSDetail,
    },
    {
        path: "/trawlpack/ho/tracking-order/manifest",
        name: "manifest",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CheckManifest,
    },
    {
        path: "/trawlpack/ho/tracking-order/manifest/:code",
        name: "detail-manifest",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrackingManifestDetail,
    },
    {
        path: "/trawlpack/ho/tracking-order/assign-transporter",
        name: "assign-transporter",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AssignTransporter,
    },
    {
        path: "/trawlpack/ho/tracking-order/receipt",
        name: "receipt",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Receipt,
    },
    {
        path: "/trawltruck/driver/account",
        name: "account-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckAccountDriver,
    },
    {
        path: "/trawltruck/driver/account/:id",
        name: "detail-account-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckDetailAccountDriver,
    },
    {
        path: "/trawltruck/driver/registration",
        name: "register-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckRegisterDriver,
    },
    {
        path: "/trawltruck/driver/registration/reject/:id",
        name: "register-driver-reject",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckRegisterDriverReject,
    },
    {
        path: "/trawltruck/driver/suspend",
        name: "suspend-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckSuspendDriver,
    },
    {
        path: "/trawltruck/disbursement",
        name: "disbursement-of-funds",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckDisbursement,
    },
    {
        path: "/trawltruck/disbursement/:id",
        name: "disbursement-of-funds-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckDisbursementDetail,
    },
    {
        path: "/trawltruck/order/tracking",
        name: "tracking-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckTrackingOrder,
    },
    {
        path: "/trawltruck/order/tracking/:id",
        name: "tracking-driver-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckTrackingOrderDetail,
    },
    {
        path: "/trawltruck/assign-driver",
        name: "assign-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckAssignDriver,
    },
    {
        path: "/trawltruck/assign-driver/detail/:id",
        name: "assign-driver-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckAssignDriverDetail,
    },
    {
        path: "/trawltruck/partner/driver",
        name: "management-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDriver,
    },
    {
        path: "/trawltruck/partner/driver/:id",
        name: "detail-management-driver",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DetailManagementDriver,
    },
    {
        path: "/trawltruck/partner/fleet",
        name: "management-fleet",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementFleet,
    },
    {
        path: "/trawltruck/partner/fleet/:id",
        name: "detail-management-fleet",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DetailManagementFleet,
    },
    {
        path: "/trawltruck/incoming/order",
        name: "incoming-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: IncomingOrder,
    },
    {
        path: "/trawltruck/partner/order",
        name: "management-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementOrder,
    },
    {
        path: "/trawltruck/partner/order/detail/:id",
        name: "management-order-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementOrderDetail,
    },
    {
        path: "/trawltruck/partner/disbursement",
        name: "management-disbursement",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ManagementDisbursement,
    },
    {
        path: "/trawltruck/partner/topup",
        name: "topup",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TopupTrawltruckCorporate,
    },
    {
        path: "/trawltruck/partner/profile",
        name: "trawltruck-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ProfileTrawltruckCorporate,
    },
    {
        path: "/trawltruck/cubic/price",
        name: "trawltruck-cubic-price",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckCubicPrice,
    },
    {
        path: "/trawlpack/owner/profile",
        name: "owner-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ProfileDashboardOwner,
    },
    {
        path: "/trawlpack/partner/income",
        name: "owner-income-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OwnerIncomeMB,
    },
    {
        path: "/trawlpack/partner/income",
        name: "owner-income-mpw",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OwnerIncomeMPW,
    },
    {
        path: "/trawlpack/partner/income",
        name: "owner-income-mtak",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: OwnerIncomeMTAK,
    },
    {
        path: "/trawlpack/partner/manifest",
        name: "status-manifest-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: StatusManifestMB,
    },
    {
        path: "/trawlpack/partner/manifest",
        name: "status-manifest-mpw",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: StatusManifestMPW,
    },
    {
        path: "/trawlpack/partner/manifest",
        name: "status-manifest-mtak",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: StatusManifestMTAK,
    },
    {
        path: "/warehouse/owner/mb",
        name: "warehouse-owner-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseOwnerMB,
    },
    {
        path: "/warehouse/owner/mpw",
        name: "warehouse-owner-mpw",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: WarehouseOwnerMPW,
    },
    {
        path: "/trawlpack/partner/warehouse/weight",
        name: "warehouse-mw-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: MeasureWeight,
    },
    {
        path: "/trawlpack/partner/warehouse/packing",
        name: "warehouse-p-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Packing,
    },
    {
        path: "/trawlpack/partner/warehouse/delivery",
        name: "warehouse-rtd-mb",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: ReadyToDelivery,
    },
    {
        path: "/trawlpack/partner/asset/staff",
        name: "owner-employee",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Employee,
    },
    {
        path: "/trawlpack/partner/asset/fleet",
        name: "owner-transporter",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Transporter,
    },
    {
        path: "/trawlpack/invoice/send",
        name: "trawlpack-send-invoice",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlpackSendInvoice,
    },
    {
        path: "/trawlpack/invoice/send/detail/:id",
        name: "trawlpack-send-invoice-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlpackSendInvoiceCreate,
    },
    {
        path: "/trawlpack/invoice/print",
        name: "trawlpack-invoice",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawlpackInvoice,
    },
    {
        path: "/crm/specialist",
        name: "crm-specialist",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmSpecialist,
    },
    {
        path: "/crm/specialist/detail/:id",
        name: "crm-specialist-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmSpecialistDetail,
    },
    {
        path: "/crm/specialist/form",
        name: "crm-specialist-form",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmSpecialistForm,
    },
    {
        path: "/crm/customers",
        name: "crm-customers",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomers,
    },
    {
        path: "/crm/customers/ordered",
        name: "crm-customers-ordered",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersOrdered,
    },
    {
        path: "/crm/customers/leads",
        name: "crm-customers-leads",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeads,
    },
    {
        path: "/crm/customers/leads/detail/:id",
        name: "crm-customers-leads-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeadsDetail,
    },
    {
        path: "/crm/customers/leads/tags",
        name: "crm-customers-leads-tags",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeadsTags,
    },
    {
        path: "/crm/customers/leads/form",
        name: "crm-customers-form",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersForm,
    },
    {
        path: "/crm/customers/leads/form/:id",
        name: "crm-customers-form-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersFormEdit,
    },
    {
        path: "/crm/customers/leads/prospect/:id",
        name: "crm-customers-leads-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerLeadsProspect,
    },
    {
        path: "/crm/customer/order/detail/:id",
        name: "crm-customers-order-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerOrderedDetails,
    },
    {
        path: "/crm/customer/order/history/:id",
        name: "crm-customers-order-history",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerOrderedHistory,
    },
    {
        path: "/crm/customers/order/prospect/:id",
        name: "crm-customers-order-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerOrderedProspect,
    },
    {
        path: "/crm/prospect",
        name: "crm-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspect,
    },
    {
        path: "/crm/prospect/all",
        name: "crm-prospect-all",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectAll,
    },
    {
        path: "/crm/prospect/today",
        name: "crm-prospect-today",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectToday,
    },
    {
        path: "/crm/prospect/tomorrow",
        name: "crm-prospect-tomorrow",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectTomorrow,
    },
    {
        path: "/crm/prospect/next-two-days",
        name: "crm-prospect-next-two-days",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextTwoDays,
    },
    {
        path: "/crm/prospect/next-week",
        name: "crm-prospect-next-week",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextWeek,
    },
    {
        path: "/crm/prospect/next-month",
        name: "crm-prospect-next-month",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextMonth,
    },
    {
        path: "/crm/prospect/need-follow-up",
        name: "crm-prospect-need-follow-up",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNeedFollowUp,
    },
    {
        path: "/crm/prospect/detail/:id",
        name: "crm-prospect-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDetail,
    },
    {
        path: "/crm/prospect/prediction/:id",
        name: "crm-prospect-prediction",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectPrediction,
    },
    {
        path: "/crm/prospect/lost/:id",
        name: "crm-prospect-lost",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectLost,
    },
    {
        path: "/crm/prospect/deal/:id",
        name: "crm-prospect-deal",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDealt,
    },
    {
        path: "/crm/gratitude-journal",
        name: "gratitude-journal",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournal,
    },
    {
        path: "/crm/gratitude-journal/create",
        name: "gratitude-journal-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournalCreate,
    },
    {
        path: "/crm/gratitude-journal/user/:id",
        name: "gratitude-journal-user",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournalUser,
    },
    {
        path: "/crm/gratitude-journal/user/detail/:id",
        name: "gratitude-journal-user-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournalUserDetail,
    },
    {
        path: "/crm/order",
        name: "crm-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrder,
    },
    {
        path: "/crm/order/detail",
        name: "crm-order-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderDetail,
    },
    {
        path: "/crm/target",
        name: "crm-target",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmTarget,
    },
    {
        path: "/crm/target/form",
        name: "crm-order-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderCreate,
    },
    {
        path: "/crm/target/edit/:id",
        name: "crm-order-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderEdit,
    },
    {
        path: "/crm/profile",
        name: "crm-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProfile,
    },
    {
        path: "/crm/profile/edit",
        name: "crm-profile-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProfileEdit,
    },
    {
        path: "/carrier/ho/dasboard/finance",
        name: "carrier-ho-dashboard-finance",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: dashboardTrawlcarrierFinance,
    },
    {
        path: "/carrier/ho/dasboard/operation",
        name: "carrier-ho-dashboard-operation",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardTrawlcarrierOperation,
    },
    {
        path: "/carrier/ho/refund",
        name: "carrier-ho-refund",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: carrierHoRefund,
    },
    {
        path: "/carrier/ho/refund/detail/:id",
        name: "carrier-ho-refund-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: carrierHoRefundDetail,
    },
    {
        path: "/crm/tags",
        name: "crm-tags",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmTags,
    },
    {
        path: "/crm/tags/create",
        name: "crm-tags-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmTagsCreate,
    },
    {
        path: "/crm/tags/detail/:id",
        name: "crm-tags-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmTagsDetail,
    },
    {
        path: "/crm/customers/registered",
        name: "crm-customers-registered",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersRegistered,
    },
    {
        path: "/crm/customers/registered/prospect/:id",
        name: "crm-customers-registered-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerRegisteredProspect,
    },
    {
        path: "/crm/customers/registered/detail/:id",
        name: "crm-customers-registered-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerRegisteredDetail,
    },
    {
        path: "/crm/branchs",
        name: "crm-branchs",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchs,
    },
    {
        path: "/crm/branchs/create",
        name: "crm-branchs-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchsCreate,
    },
    {
        path: "/crm/branchs/edit/:id",
        name: "crm-branchs-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchsEdit,
    },
    {
        path: "/crm/branchs/detail/:id",
        name: "crm-branchs-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchsDetail,
    },
    {
        path: "/crm/partnership/registered",
        name: "crm-partnership-registered",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipRegistered,
    },
    {
        path: "/crm/partnership/registered/detail/",
        name: "crm-partnership-registered-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipRegisteredDetail,
    },
    {
        path: "/crm/partnership/candidate",
        name: "crm-partnership-candidate",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipCandidate,
    },
    {
        path: "/crm/partnership/candidate/create",
        name: "crm-partnership-candidate-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipCandidateCreate,
    },
    {
        path: "/crm/partnership/candidate/edit/:id",
        name: "crm-partnership-candidate-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipCandidateEdit,
    },
    {
        path: "/crm/partnership/candidate/prospect/:id",
        name: "crm-partnership-candidate-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipCandidateProspect,
    },
    {
        path: "/crm/partnership/candidate/detail/:id",
        name: "crm-partnership-candidate-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipCandidateDetail,
    },
    {
        path: "/crm/partnership/prospect/today",
        name: "crm-partnership-prospect-today",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipProspectToday,
    },
    {
        path: "/crm/partnership/prospect/tomorrow",
        name: "crm-partnership-prospect-tomorrow",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipProspectTomorrow,
    },
    {
        path: "/crm/partnership/prospect/next-two-days",
        name: "crm-partnership-prospect-next-two-days",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipProspectNextTwoDays,
    },
    {
        path: "/crm/partnership/prospect/next-week",
        name: "crm-partnership-prospect-next-week",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipProspectNextWeek,
    },
    {
        path: "/crm/partnership/prospect/next-month",
        name: "crm-partnership-prospect-next-month",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipProspectNextMonth,
    },
    {
        path: "/crm/partnership/prospect/need-follow-up",
        name: "crm-partnership-prospect-need-follow-up",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipProspectNextNeedFollowUp,
    },
    {
        path: "/crm/partnership/prospect/detail/:id",
        name: "crm-partnership-prospect-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipProspectDetail,
    },
    {
        path: "/crm/partnership/requested-trawlcarrier",
        name: "crm-partnership-requested-trawlcarrier",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipRequestedTrawlcarrier,
    },
    {
        path: "/crm/partnership/requested-trawlcarrier/detail",
        name: "crm-partnership-requested-trawlcarrier-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipRequestedTrawlcarrierDetail,
    },
    {
        path: "/crm/partnership/requested-trawlpack",
        name: "crm-partnership-requested-trawlpack",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipRequestedTrawlpack,
    },
    {
        path: "/crm/partnership/requested-trawlpack/detail",
        name: "crm-partnership-requested-trawlpack-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmPartnershipRequestedTrawlpackDetail,
    },
    {
        path: "/trawltruck/ho/cek-tarif",
        name: "trawltruck-ho-cek-tarif",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckHoCekTarif,
    },
    {
        path: "/trawltruck/mitra",
        name: "trawltruck-mitra",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckMitra,
    },
    {
        path: "/trawltruck/mitra/detail/:id",
        name: "trawltruck-mitra-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckMitraDetail,
    },
    {
        path: "/trawltruck/mitra/edit/:id",
        name: "trawltruck-mitra-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckMitraEdit,
    },
    {
        path: "/trawltruck/mitra/create",
        name: "trawltruck-mitra-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckMitraCreate,
    },
    {
        path: "/notification",
        name: "notification",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: NotificationPage,
    },
    {
        path: "/crm/follow-up-cro",
        name: "crm-follow-up-cro",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmFollowUpCro,
    },
    {
        path: "/crm/follow-up-cro/detail/:id",
        name: "crm-follow-up-cro-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDetail,
    },
    {
        path: "/crm/follow-up-cro/prospect/:id",
        name: "crm-follow-up-cro-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectPrediction,
    },
    {
        path: "/crm/follow-up-cro/prospect-deal/:id",
        name: "crm-follow-up-cro-prospect-deal",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDealt,
    },
    {
        path: "/crm/follow-up-cro/prospect-lost/:id",
        name: "crm-follow-up-cro-prospect-lost",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectLost,
    },
    {
        path: "/crm/order-lost",
        name: "crm-order-lost",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderLost,
    },
    {
        path: "/crm/order-lost/detail/:id",
        name: "crm-order-lost-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderLostDetail,
    },
    {
        path: "/harbors",
        name: "harbors",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Harbors,
    },
    {
        path: "/harbors/create",
        name: "harbors-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: HarborsCreate,
    },
    {
        path: "/harbors/edit/:id",
        name: "harbors-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: HarborsEdit,
    },
    {
        path: "/trocarrier/pricing",
        name: "trocarrier-pricing",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TroCarrierPricing,
    },
    {
        path: "/service/setharga-layanan",
        name: "set-harga-layanan",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: SetHarga,
    },
    {
        path: "/master/customer",
        name: "master-customer",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: MasterCustomer,
    },
    {
        path: "/master/customer/detail/:id",
        name: "master-customer-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: MasterCustomerDetail,
    },
    {
        path: "/master/user",
        name: "master-user",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: MasterUser,
    },
    {
        path: "/master/user/detail/:id",
        name: "master-user-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: MasterUserDetail,
    },
    {
        path: "/trawltruck/withdraw/individu",
        name: "trawltruck-withdraw-individu",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckWithdrawIndividu,
    },
    {
        path: "/trawltruck/withdraw/individu/:id",
        name: "trawltruck-withdraw-individu-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckWithdrawIndividuDetail,
    },
    {
        path: "/trawltruck/mitra/balance",
        name: "trawltruck-mitra-balance",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckMitraBalance,
    },
    {
        path: "/trawltruck/mitra/balance/:id",
        name: "trawltruck-mitra-balance-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrawltruckMitraBalanceDetail,
    },
    {
        path: "/truck/sticker",
        name: "truck-sticker",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrotruckSticker,
    },
    {
        path: "/truck/sticker/reject/:id",
        name: "truck-sticker-reject",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrotruckStickerReject,
    },
    {
        path: "/truck/sticker/detail/:id",
        name: "truck-sticker-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TrotruckStickerDetail,
    },
    {
        path: "/truck/commission/sticker",
        name: "truck-commission-sticker",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TruckCommissionSticker,
    },
    {
        path: "/truck/commission/sticker/detail/:id",
        name: "truck-commission-sticker-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TruckCommissionStickerDetail,
    },
    {
        path: "/truck/commission/sticker-ga",
        name: "truck-commission-sticker-ga",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TruckCommissionStickerGa,
    },
    {
        path: "/truck/commission/sticker-ga/detail/:id",
        name: "truck-commission-sticker-ga-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TruckCommissionStickerGaDetail,
    },
    {
        path: "/truck/commission/sticker-ga/reject/:id",
        name: "truck-commission-sticker-ga-reject",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: TruckCommissionStickerGaReject,
    },
    {
        path: "/register-sales-affiliate",
        name: "register-sales-affiliate",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: RegisterSalesAffiliate,
    },
    {
        path: "/register-sales-affiliate/reject/:id",
        name: "register-sales-affiliate-reject",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: RegisterSalesAffiliateReject,
    },
    {
        path: "/register-sales-affiliate/detail/:id",
        name: "register-sales-affiliate-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: RegisterSalesAffiliateDetail,
    },
    {
        path: "/account-sales-affiliate",
        name: "account-sales-affiliate",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AccountSalesAffiliate,
    },
    {
        path: "/account-sales-affiliate/detail/:id",
        name: "account-sales-affiliate-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: AccountSalesAffiliateDetail,
    },
    {
        path: "/disbursement-sales-affiliate",
        name: "disbursement-sales-affiliate",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DisbursementSalesAffiliate,
    },
    {
        path: "/disbursement-sales-affiliate/detail/:id",
        name: "disbursement-sales-affiliate-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DisbursementSalesAffiliateDetail,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

const onError = (e) => {
    if (e.name !== "NavigationDuplicated") throw e;
};

const _push = router.__proto__.push;
router.__proto__.push = function push(...args) {
    try {
        const op = _push.call(this, ...args);
        if (op instanceof Promise) op.catch(onError);
        return op;
    } catch (e) {
        onError(e);
    }
};
(function () {})();
export default router;
