<template>
    <div class="padding-container">
        <!-- <div class="content-load" v-if="isLoadData"></div>
        <div id="loader" v-if="isLoadData"></div> -->
        <div
            class="text-black size-16 text-gray-dashboard fw-semibold mb-4 cursor-pointer"
            @click="$router.go(-1)"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="box my-3">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h4>Detail Pencairan Komisi</h4>
                        <div
                            class="tag rounded-pill capitalize badge-pending px-4"
                        >
                            Pending
                        </div>
                    </div>
                    <div class="table-responsive-custom">
                        <table class="table mt-4">
                            <tr>
                                <td>Periode</td>
                                <td>:</td>
                                <td>September 2024</td>
                            </tr>
                            <tr>
                                <td>Nama Agent</td>
                                <td>:</td>
                                <td>Hana Zulaika</td>
                            </tr>
                            <tr>
                                <td>Bank Tujuan</td>
                                <td>:</td>
                                <td>Bank Mandiri</td>
                            </tr>
                            <tr>
                                <td>Nama Pemilik Rekening</td>
                                <td>:</td>
                                <td>Hana Zulaikha</td>
                            </tr>
                            <tr>
                                <td>Nomor Rekening</td>
                                <td>:</td>
                                <td>5050174338</td>
                            </tr>
                            <tr>
                                <td>Nominal Pencairan</td>
                                <td>:</td>
                                <td>Rp 782.000</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="box my-3">
                    <h4>Bukti Transfer Pencairan Komisi</h4>
                    <p>Bukti Transfer</p>
                    <div
                        class="border d-flex justify-content-center h-15 align-items-center rounded mb-4 cursor-pointer"
                    >
                        <!-- <img
                            src="https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aW1hZ2V8ZW58MHx8MHx8fDA%3D"
                            alt="input image"
                            class="w-100 h-100 object-fit-cover"
                        /> -->
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37"
                            height="36"
                            viewBox="0 0 37 36"
                            fill="none"
                        >
                            <path
                                d="M27.75 13.5C27.325 13.5 26.969 13.356 26.682 13.068C26.395 12.78 26.251 12.424 26.25 12V10.5H24.75C24.325 10.5 23.969 10.356 23.682 10.068C23.395 9.78 23.251 9.424 23.25 9C23.25 8.575 23.394 8.219 23.682 7.932C23.97 7.645 24.326 7.501 24.75 7.5H26.25V6C26.25 5.575 26.394 5.219 26.682 4.932C26.97 4.645 27.326 4.501 27.75 4.5C28.175 4.5 28.5315 4.644 28.8195 4.932C29.1075 5.22 29.251 5.576 29.25 6V7.5H30.75C31.175 7.5 31.5315 7.644 31.8195 7.932C32.1075 8.22 32.251 8.576 32.25 9C32.25 9.425 32.106 9.7815 31.818 10.0695C31.53 10.3575 31.174 10.501 30.75 10.5H29.25V12C29.25 12.425 29.106 12.7815 28.818 13.0695C28.53 13.3575 28.174 13.501 27.75 13.5ZM11.25 25.5H26.25C26.55 25.5 26.775 25.3625 26.925 25.0875C27.075 24.8125 27.05 24.55 26.85 24.3L22.725 18.7875C22.575 18.5875 22.375 18.4875 22.125 18.4875C21.875 18.4875 21.675 18.5875 21.525 18.7875L17.625 24L14.85 20.2875C14.7 20.0875 14.5 19.9875 14.25 19.9875C14 19.9875 13.8 20.0875 13.65 20.2875L10.65 24.3C10.45 24.55 10.425 24.8125 10.575 25.0875C10.725 25.3625 10.95 25.5 11.25 25.5ZM8.25 31.5C7.425 31.5 6.719 31.206 6.132 30.618C5.545 30.03 5.251 29.324 5.25 28.5V7.5C5.25 6.675 5.544 5.969 6.132 5.382C6.72 4.795 7.426 4.501 8.25 4.5H20.25C20.675 4.5 21.0315 4.644 21.3195 4.932C21.6075 5.22 21.751 5.576 21.75 6V10.5C21.75 10.925 21.894 11.2815 22.182 11.5695C22.47 11.8575 22.826 12.001 23.25 12H24.75V13.5C24.75 13.925 24.894 14.2815 25.182 14.5695C25.47 14.8575 25.826 15.001 26.25 15H30.75C31.175 15 31.5315 15.144 31.8195 15.432C32.1075 15.72 32.251 16.076 32.25 16.5V28.5C32.25 29.325 31.9565 30.0315 31.3695 30.6195C30.7825 31.2075 30.076 31.501 29.25 31.5H8.25Z"
                                fill="#D2D2D5"
                            />
                        </svg>
                    </div>
                    <button class="btn btn-green w-100 py-2" disabled>
                        Simpan
                    </button>
                </div>
            </div>
            <div class="col-md-6">
                <div class="box my-3">
                    <h5>Detail Nominal Pencairan</h5>
                    <div class="d-flex justify-content-between text-green">
                        <p>5 Resi</p>
                        <p>Rp 782.000</p>
                    </div>
                    <div class="accordion">
                        <div
                            v-b-toggle.accordion-1
                            class="d-flex bg-accordion my-3 justify-content-between align-items-center rounded p-2 px-3 fw-bold"
                        >
                            <p class="mb-0">TRB05122023001</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M5 8L12 16"
                                    stroke="#1F1E2C"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M19 8L12 16"
                                    stroke="#1F1E2C"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <b-collapse class="px-2" id="accordion-1">
                            <div
                                class="d-flex justify-content-between my-2 align-items-center"
                            >
                                <p class="mb-0">Jenis Layanan</p>
                                <p class="mb-0">Troben Cargo</p>
                            </div>
                            <div
                                class="d-flex justify-content-between my-2 align-items-center"
                            >
                                <p class="mb-0">Jenis Pengiriman</p>
                                <p class="mb-0">Pengiriman Motor</p>
                            </div>
                            <div
                                class="d-flex justify-content-between my-2 align-items-center fw-bold"
                            >
                                <p class="mb-0">Nominal Pendapatan</p>
                                <p class="mb-0">Rp 120.000</p>
                            </div>
                        </b-collapse>
                        <div
                            v-b-toggle.accordion-2
                            class="d-flex bg-accordion my-3 justify-content-between align-items-center rounded p-2 px-3 fw-bold"
                        >
                            <p class="mb-0">TRB05122023001</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M5 8L12 16"
                                    stroke="#1F1E2C"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M19 8L12 16"
                                    stroke="#1F1E2C"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <b-collapse class="px-2" id="accordion-2">
                            <div
                                class="d-flex justify-content-between my-2 align-items-center"
                            >
                                <p class="mb-0">Jenis Layanan</p>
                                <p class="mb-0">Troben Cargo</p>
                            </div>
                            <div
                                class="d-flex justify-content-between my-2 align-items-center"
                            >
                                <p class="mb-0">Jenis Pengiriman</p>
                                <p class="mb-0">Pengiriman Motor</p>
                            </div>
                            <div
                                class="d-flex justify-content-between my-2 align-items-center fw-bold"
                            >
                                <p class="mb-0">Nominal Pendapatan</p>
                                <p class="mb-0">Rp 120.000</p>
                            </div>
                        </b-collapse>
                        <div
                            v-b-toggle.accordion-3
                            class="d-flex bg-accordion my-3 justify-content-between align-items-center rounded p-2 px-3 fw-bold"
                        >
                            <p class="mb-0">TRB05122023001</p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M5 8L12 16"
                                    stroke="#1F1E2C"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M19 8L12 16"
                                    stroke="#1F1E2C"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <b-collapse class="px-2" id="accordion-3">
                            <div
                                class="d-flex justify-content-between my-2 align-items-center"
                            >
                                <p class="mb-0">Jenis Layanan</p>
                                <p class="mb-0">Troben Cargo</p>
                            </div>
                            <div
                                class="d-flex justify-content-between my-2 align-items-center"
                            >
                                <p class="mb-0">Jenis Pengiriman</p>
                                <p class="mb-0">Pengiriman Motor</p>
                            </div>
                            <div
                                class="d-flex justify-content-between my-2 align-items-center fw-bold"
                            >
                                <p class="mb-0">Nominal Pendapatan</p>
                                <p class="mb-0">Rp 120.000</p>
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="box my-3">
                    <h5>Riwayat Pencairan Komisi</h5>
                    <div class="table-responsive-custom table-bottom mb-4">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Tanggal Pencairan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Periode
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nominal Pencairan
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">1</td>
                                    <td class="text-center">Hana Zulaika</td>
                                    <td class="text-center">jakarta selatan</td>
                                    <td class="text-end">Rp 5.349.000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <b-pagination-nav
                        number-of-pages="100"
                        base-url="#"
                        first-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Api from "../../api/Api";
// import "viewerjs/dist/viewer.css";
// import { component as Viewer } from "v-viewer";

export default {
    components: {
        // Viewer,
    },
    data() {
        return {
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
        };
    },
    created() {},
    computed: {},
    methods: {},
};
</script>

<style scoped>
.table-bottom th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
.table-bottom tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
.table-bottom td {
    padding: 10px !important;
}
.table-bottom .table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.table-bottom .table tr:last-child {
    border-bottom: 1px solid #e8e8e9 !important;
}
.bg-accordion {
    background-color: #d8e7d3;
}
.btn-green:disabled {
    background: #d2d2d5 !important;
    border: none;
}
.h-15 {
    height: 15rem;
}
.table-responsive-custom .table {
    border-collapse: collapse;
}

.table-responsive-custom .table tr {
    border-bottom: 1px solid #e8e8e9;
}

.table-responsive-custom .table tr:last-child {
    border-bottom: none;
}

.table-responsive-custom .table td {
    padding: 10px;
}
.badge-pending {
    background-color: #feead4;
    color: #d17e20;
}
.badge-transferred {
    background-color: #d8e7d3;
    color: #33711e;
}
.form-input {
    background: rgba(239, 241, 249, 0.6);
    border-width: 0px;
    border: none;
    outline: none;
    border-radius: 10px;
    width: 258px;
    height: 40px;
    padding: 10px;
}
.image-cover {
    border-radius: 10px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid #f7f7f7;
}
.image-default {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    border: 2px solid #f7f7f7;
}
.checkbox input[type="checkbox"] {
    display: none;
}
.form-check-input {
    display: none !important;
}
.form-check label {
    padding-left: 0;
    cursor: pointer;
}

.form-check label:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 3px;
    font-family: "FontAwesome";
    cursor: pointer;
}
.form-check input[type="checkbox"]:checked + label::before {
    content: "\f00d";
    background: #faccd0;
    color: #e60013;
    border: 1px solid #e60013;
}
</style>
