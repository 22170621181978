<template>
    <div class="padding-container">
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Pencairan</h3>
            </div>
            <div class="row">
                <div class="col-lg-6 d-flex gap-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Cari nama agent"
                        v-model="search"
                    />
                    <button
                        class="btn d-flex btn-filter gap-2 px-4"
                        @click="toggleFilter()"
                    >
                        Filter
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 8L9.5 8"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M16 8H20M16 8C16 6.89543 15.1046 6 14 6C12.8954 6 12 6.89543 12 8C12 9.10457 12.8954 10 14 10C15.1046 10 16 9.10457 16 8Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M8 16H4M8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M14.5 16L20 16"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="box mt-3" v-if="showFilter">
                <label class="fw-bold mb-3">Filter Data</label>
                <div class="row">
                    <div class="col-md-4 mt-2">
                        <label class="mb-3">Kota/Kab. Domisili</label>
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            track-by="value"
                            placeholder="Cari kota/kab"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mt-2 position-relative">
                        <label class="mb-3">Periode Pencairan</label>
                        <date-picker
                            v-model="disbursementPeriode"
                            range
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih periode pencairan"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-4 mt-2 position-relative">
                        <label class="mb-3">Status Pencairan</label>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-toggle text-gray-dashboard"
                            id="disbursementStatusDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <div class="radius-select d-flex">
                                <div
                                    class="d-flex align-items-center justify-content-between w-100 gap-4"
                                >
                                    <div class="scroll-select">
                                        <template
                                            v-if="
                                                selectedDisbursementStatus.length ===
                                                0
                                            "
                                        >
                                            <p class="placeholder-custom">
                                                Pilih status pencairan
                                            </p>
                                        </template>
                                        <template v-else>
                                            {{
                                                selectedDisbursementStatus
                                                    .map((s) =>
                                                        s.name
                                                            .split("_")
                                                            .join(" ")
                                                    )
                                                    .join(", ")
                                            }}
                                        </template>
                                    </div>
                                    <div class="ms-auto arrow-custom"></div>
                                </div>
                            </div>
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="disbursementStatusDropdown"
                        >
                            <li
                                v-for="statusOption in statuses"
                                :key="statusOption.id"
                                class="pt-1 pb-1"
                            >
                                <div
                                    class="form-check form-check-inline cursor-pointer"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="statusOption.id"
                                        :value="statusOption"
                                        v-model="selectedDisbursementStatus"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="statusOption.id"
                                    >
                                        {{
                                            statusOption.name
                                                .split("_")
                                                .join(" ")
                                        }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-4 mt-2 position-relative">
                        <label class="mb-3">Tanggal Pencairan</label>
                        <date-picker
                            v-model="disbursementDate"
                            range
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih tanggal pencairan"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="mb-3 invisible">Invisible</label>
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="
                                !regency ||
                                selectedDisbursementStatus.length === 0 ||
                                !disbursementPeriode ||
                                !disbursementDate
                            "
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="mb-3 invisible">Invisible</label>
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="
                                !regency ||
                                selectedDisbursementStatus.length === 0 ||
                                !disbursementPeriode ||
                                !disbursementDate
                            "
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="mt-4">
                <ListLoader />
            </div> -->
            <div>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Agent
                                </th>
                                <th class="text-center" scope="col">
                                    Kota/Kabupaten
                                </th>
                                <th class="text-center" scope="col">
                                    Nominal Pencairan
                                </th>
                                <th class="text-center" scope="col">Periode</th>
                                <th class="text-center" scope="col">
                                    Status Pencairan
                                </th>
                                <th class="text-center" scope="col">
                                    Tanggal Pencairan
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">1</td>
                                <td class="text-center">Hana Zulaika</td>
                                <td class="text-center">jakarta selatan</td>
                                <td>Rp 5.349.000</td>
                                <td>September 2024</td>
                                <td class="text-center d-flex flex-column">
                                    <div
                                        class="tag rounded-pill w-100 capitalize badge-pending"
                                    >
                                        Pending
                                    </div>
                                    <div
                                        class="tag rounded-pill w-100 capitalize badge-transferred"
                                    >
                                        Transferred
                                    </div>
                                </td>
                                <td class="text-center">23/10/2023 | 20:00</td>
                                <td class="text-center">
                                    <router-link
                                        to="/disbursement-sales-affiliate/detail/12"
                                        class="btn btn-transfer d-flex align-items-center gap-2"
                                    >
                                        Transfer Komisi
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.29916 2.27588H2.84199C2.57569 2.27588 2.34315 2.18024 2.14437 1.98896C1.94634 1.79768 1.84732 1.56514 1.84732 1.29134C1.84732 1.01755 1.94634 0.781257 2.14437 0.582474C2.34241 0.38369 2.57494 0.284673 2.84199 0.285423H10.805C11.063 0.285423 11.279 0.372812 11.4531 0.547592C11.6271 0.721622 11.7141 0.937283 11.7141 1.19458V9.15754C11.7141 9.42459 11.6185 9.65713 11.4272 9.85516C11.2352 10.0539 11.0026 10.1533 10.7296 10.1533C10.4558 10.1533 10.2195 10.0539 10.0207 9.85516C9.82267 9.65713 9.72365 9.42459 9.72365 9.15754V3.72625L2.0116 11.4383C1.81507 11.6311 1.57503 11.7275 1.29148 11.7275C1.00793 11.7275 0.769014 11.63 0.574732 11.4349C0.380449 11.2399 0.284057 11.0002 0.285557 10.7159C0.287057 10.4316 0.38345 10.1935 0.574732 10.0014L8.29916 2.27588Z"
                                                fill="#FBFBFB"
                                            />
                                        </svg>
                                    </router-link>
                                    <router-link
                                        to="/disbursement-sales-affiliate/detail/12"
                                        class="btn btn-detail d-flex align-items-center gap-2"
                                    >
                                        Lihat Detail
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="12"
                                            viewBox="0 0 14 12"
                                            fill="none"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M6.9987 10.6666C9.05977 10.6666 10.9741 9.4483 12.2905 7.35024C12.8078 6.52581 12.8078 5.47415 12.2905 4.64972C10.9741 2.55166 9.05977 1.33331 6.9987 1.33331H7.0013C4.94023 1.33331 3.02594 2.55166 1.70952 4.64972C1.19223 5.47415 1.19223 6.52581 1.70952 7.35024C3.02594 9.4483 4.94023 10.6666 7.0013 10.6666H6.9987Z"
                                                fill="#FBFBFB"
                                                stroke="#FBFBFB"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M7 4C8.10457 4 9 4.89543 9 6C9 7.10457 8.10457 8 7 8C5.89543 8 5 7.10457 5 6C5 4.89543 5.89543 4 7 4Z"
                                                fill="#FBFBFB"
                                                stroke="#48A2D4"
                                                stroke-width="1.5"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="text-center mt-section">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div> -->
            </div>
        </div>
        <div class="d-flex">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select class="form-control form-select w-select">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <b-pagination-nav
                        number-of-pages="100"
                        base-url="#"
                        first-number
                        align="right"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <!-- MODAL -->
    </div>
</template>

<script>
import Api from "../../api/Api";
// import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        Multiselect,
        // ListLoader,
        DatePicker,
    },
    data() {
        return {
            showFilter: false,
            disbursementPeriode: null,
            disbursementDate: null,
            disbursementStatus: null,
            search: null,
            regency: null,
            regencies: [],
            selectedDisbursementStatus: [],
            statuses: [
                {
                    name: "Pending",
                    id: "pending",
                },
                {
                    name: "Transferred",
                    id: "transferred",
                },
            ],
        };
    },
    created() {
        this.getRegency();
    },
    computed: {},
    methods: {
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
        },
        clearFilter() {
            this.selectedDisbursementStatus = [];
            this.regency = null;
            this.disbursementPeriode = null;
            this.disbursementDate = null;
            this.disbursementStatus = null;
        },
    },
};
</script>

<style scoped>
.dropdown-menu.show {
    width: 93%;
}
.dropdown-toggle::after {
    display: none;
}
.arrow-custom::before {
    position: relative;
    right: 8px;
    top: 12px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: "";
}
div::-webkit-scrollbar {
    display: none;
}
.radius-select {
    border: 1px solid #e9ecef !important;
    padding: 0px 7px 0 7px !important;
    min-height: 35px !important;
    border-radius: 5px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.scroll-select .placeholder-custom {
    margin-bottom: 0;
    color: #6c757d;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}

th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.btn-filter {
    background-color: #fbfbfb;
    border: 1px solid #d2d2d5;
}
.btn-filter:hover {
    background-color: #fbfbfb;
    border: 1px solid #d2d2d5;
}
.badge-pending {
    background-color: #feead4;
    color: #d17e20;
}
.badge-transferred {
    background-color: #d8e7d3;
    color: #33711e;
}
.btn-transfer {
    background-color: #3d8824;
    color: #fbfbfb;
}
.btn-detail {
    background-color: #48a2d4;
    color: #fbfbfb;
}
.btn-transfer:hover {
    background-color: #3d8824;
    color: #fbfbfb;
}
.btn-detail:hover {
    background-color: #48a2d4;
    color: #fbfbfb;
}
.btn-transfer:active {
    background-color: #3d8824 !important;
    color: #fbfbfb !important;
}
.btn-detail:active {
    background-color: #48a2d4 !important;
    color: #fbfbfb !important;
}
</style>
