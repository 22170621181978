<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoadData"></div>
        <div id="loader" v-if="isLoadData"></div>
        <div
            class="text-black size-16 text-gray-dashboard fw-semibold mb-4 cursor-pointer"
            @click="$router.go(-1)"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </div>
        <div class="box row" v-if="data">
            <div class="col-md-2">
                <img
                    v-if="data.agent_picture"
                    class="user-avatar-sidebar"
                    :src="data.agent_picture"
                    alt="agent picture"
                />
                <div
                    v-else
                    class="user-avatar-sidebar"
                    :style="{
                        'background-image':
                            'url(' + require('../../assets/user.jpg') + ')',
                    }"
                ></div>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="d-flex align-items-center gap-3 mb-3">
                        <h5 class="fw-bold">Detail Akun Agent</h5>
                        <span
                            :class="[
                                'tag rounded-pill capitalize px-4',
                                {
                                    panding: data.role === 'member',
                                    resubmit: data.role === 'coordinator',
                                },
                            ]"
                        >
                            {{
                                data.role === "member"
                                    ? "Anggota"
                                    : "Koordinator"
                            }}
                        </span>
                    </div>
                    <div class="col-md-12">
                        <p class="fw-bold">Informasi Agent</p>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Tanggal Registrasi </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.register_at ? data.register_at : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Tanggal Bergabung </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.accepted_at ? data.accepted_at : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Nama Koordinator </label>
                        <div class="form-box bg-transparent mt-2">
                            <router-link
                                :to="`/account-sales-affiliate/detail/${data.coor_id}`"
                                class="d-flex gap-2 align-items-center"
                            >
                                <p
                                    :class="{
                                        koordinator: data.coor_name,
                                        'mb-0': true,
                                    }"
                                >
                                    {{ data.coor_name ? data.coor_name : "-" }}
                                </p>
                                <svg
                                    v-if="data.coor_name"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                >
                                    <path
                                        d="M12.874 5.34481H6.05255C5.71968 5.34481 5.429 5.22526 5.18053 4.98615C4.93298 4.74705 4.80921 4.45638 4.80921 4.11413C4.80921 3.77189 4.93298 3.47652 5.18053 3.22805C5.42807 2.97957 5.71874 2.85579 6.05255 2.85673H16.0063C16.3288 2.85673 16.5989 2.96597 16.8164 3.18444C17.0339 3.40198 17.1427 3.67156 17.1427 3.99317V13.9469C17.1427 14.2807 17.0231 14.5714 16.784 14.8189C16.544 15.0674 16.2533 15.1916 15.912 15.1916C15.5698 15.1916 15.2744 15.0674 15.0259 14.8189C14.7784 14.5714 14.6546 14.2807 14.6546 13.9469V7.15777L5.01456 16.7978C4.76889 17.0388 4.46884 17.1593 4.11441 17.1593C3.75997 17.1593 3.46133 17.0374 3.21848 16.7936C2.97562 16.5498 2.85513 16.2502 2.85701 15.8949C2.85888 15.5395 2.97937 15.2418 3.21848 15.0018L12.874 5.34481Z"
                                        fill="#3C87B1"
                                    />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Nama Lengkap </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.agent_name ? data.agent_name : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Email </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.email ? data.email : "-" }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Nomor Telepon </label>
                        <div class="form-box bg-transparent mt-2">
                            {{ data.phone ? data.phone : "-" }}
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="col-md-12">
                        <p class="fw-bold">Alamat Domisili</p>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Provinsi </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.province
                                    ? data.domicile.province
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Kota/Kabupaten </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.regency
                                    ? data.domicile.regency
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Kecamatan </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.district
                                    ? data.domicile.district
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-6 mb-3">
                        <label class="fw-semibold"> Kelurahan </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.subdistrict
                                    ? data.domicile.subdistrict
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="fw-semibold"> Detail Alamat </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.domicile.address
                                    ? data.domicile.address
                                    : "-"
                            }}
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="col-md-12">
                        <p class="fw-bold">Informasi Nomor Rekening</p>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="fw-semibold"> Rekening Bank </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.bank_account.account_number
                                    ? data.bank_account.account_number
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="fw-semibold">
                            Nama Pemilik Rekening
                        </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.bank_account.account_name
                                    ? data.bank_account.account_name
                                    : "-"
                            }}
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="fw-semibold"> Nama Bank </label>
                        <div class="form-box bg-transparent mt-2">
                            {{
                                data.bank_account.bank_name
                                    ? data.bank_account.bank_name
                                    : "-"
                            }}
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="col-md-4 mt-3">
                        <label class="fw-semibold">Foto Agent</label>
                        <div class="mt-2">
                            <viewer
                                :options="options"
                                v-if="data.agent_picture"
                            >
                                <img
                                    class="image-cover"
                                    :src="data.agent_picture"
                                />
                            </viewer>
                            <div
                                v-else
                                class="image-default"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../../assets/empty.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label class="fw-semibold">Foto KTP</label>
                        <div class="mt-2">
                            <viewer
                                :options="options"
                                v-if="data.identity_picture"
                            >
                                <img
                                    class="image-cover"
                                    :src="data.identity_picture"
                                />
                            </viewer>
                            <div
                                v-else
                                class="image-default"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../../assets/empty.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4" v-if="data && data.role === 'coordinator'">
            <h5 class="fw-bold">Daftar Anggota Tim</h5>
            <div class="table-responsive-custom mb-4">
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Nama Agent</th>
                            <th class="text-center" scope="col">
                                Nomor Telepon
                            </th>
                            <th class="text-center" scope="col">Email</th>
                            <th class="text-center" scope="col">
                                Kota/Kabupaten
                            </th>
                            <th class="text-center" scope="col">
                                Tanggal Bergabung
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr></tr>
                        <tr
                            v-for="(agent, index) in data.referral_list"
                            :key="agent.id"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                                <router-link
                                    class="text-link"
                                    :to="`/account-sales-affiliate/detail/${agent.id}`"
                                >
                                    {{ agent.name }}
                                </router-link>
                            </td>
                            <td class="text-center">
                                {{ agent.phone }}
                            </td>
                            <td>{{ agent.email }}</td>
                            <td>{{ agent.regency }}</td>
                            <td class="text-center">
                                {{ agent.accepted_at }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="text-center mt-section"
                v-if="!data.referral_list || data.referral_list.length === 0"
            >
                <img src="../../assets/no-data.png" width="100" alt="No data" />
                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">Data Kosong</h4>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    data() {
        return {
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            data: null,
            isLoadData: false,
        };
    },
    created() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            this.isLoadData = true;
            const params = {
                id: this.$route.params.id,
            };

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/agent/detail`,
                {
                    params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.isLoadData = false;
                })
                .catch((err) => {
                    this.isLoadData = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
    watch: {
        $route(to, from) {
            if (
                to.name === "account-sales-affiliate-detail" &&
                from.name === "account-sales-affiliate-detail"
            ) {
                this.getDetail(to.params.id);
            }
        },
    },
};
</script>

<style scoped>
.text-link {
    color: #3c87b1 !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.user-avatar-sidebar {
    width: 110px;
    height: 110px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.image-cover {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
}
.image-default {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: 2px solid #e2e3e5;
}
.text-reject {
    color: #e60013;
}
.koordinator {
    border-bottom: 1px solid #3c87b1;
    color: #3c87b1;
}
</style>
