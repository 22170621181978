<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box mt-4">
            <div class="d-flex align-items-center bd-highlight">
                <div class="flex-grow-1 bd-highlight">
                    <h3 class="fw-bold">Daftar Pesanan</h3>
                </div>
                <div class="bd-highlight">
                    <a
                        href="/trawlpack/general/order/create"
                        class="btn btn-green btn-lg px-5"
                    >
                        Buat Pesanan</a
                    >
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Cari Id Order"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <select
                        class="form-control form-select"
                        v-model="req.status"
                        @change="getCorporateListsDebounced()"
                    >
                        <option value="">Filter status</option>
                        <option value="draft">Draft</option>
                        <option value="pending">Pending</option>
                        <option value="paid">Paid</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <input
                        type="date"
                        class="form-control"
                        v-model="req.start"
                        placeholder="Start date"
                        @change="getCorporateListsDebounced()"
                    />
                </div>
                <div class="col-md-3">
                    <input
                        type="date"
                        class="form-control"
                        v-model="req.end"
                        placeholder="End date"
                        @change="getCorporateListsDebounced()"
                    />
                </div>
            </div>

            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <table
                    class="table position-relative mt-5"
                    v-if="lists.data.length > 0"
                >
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">Id Order</td>
                            <td class="th">Mitra Pickup</td>
                            <td class="th">Lokasi Pengirim</td>
                            <td class="th">Tipe Order</td>
                            <td class="th">Order By</td>
                            <td class="th">Status Bayar</td>
                            <td class="th">Tanggal Order</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(list, index, key) in lists.data">
                        <tr class="tr border-body" :key="list.id">
                            <td class="td fw-bold">{{ index + 1 }}.</td>
                            <td class="td pt-3 pb-3 fw-bold">
                                <template v-if="list.status == 'cancel'">
                                    <a
                                        href="javascript:void(0)"
                                        @click="toggleModal(list.id)"
                                        class="text-link p-0"
                                    >
                                        {{
                                            list.code.content
                                                ? list.code.content
                                                : "-"
                                        }}
                                    </a>
                                </template>
                                <template v-if="list.status != 'cancel'">
                                    <template
                                        v-if="
                                            list.parent_destination == null &&
                                            list.corporate?.payment_method ==
                                                'va' &&
                                            list.payment_status == 'pending' &&
                                            isRole(rolesVA)
                                        "
                                    >
                                        <a
                                            :href="
                                                '/corporate/order-detail/' +
                                                list.id
                                            "
                                            class="text-link p-0"
                                            target="_blank"
                                        >
                                            {{
                                                list.code.content
                                                    ? list.code.content
                                                    : "-"
                                            }}
                                        </a>
                                    </template>
                                    <template
                                        v-else-if="
                                            (list.parent_destination ||
                                                list.payment_status ==
                                                    'pending') &&
                                            list.payment_status == 'draft'
                                        "
                                    >
                                        <a
                                            :href="
                                                '/corporate/order-detail/' +
                                                list?.parent_destination
                                                    ?.parent_id
                                            "
                                            class="text-link p-0"
                                            target="_blank"
                                        >
                                            {{
                                                list.code.content
                                                    ? list.code.content
                                                    : "-"
                                            }}
                                        </a>
                                    </template>
                                    <template v-else>
                                        <a
                                            href="javascript:void(0)"
                                            @click="toggleModal(list.id)"
                                            class="text-link p-0"
                                        >
                                            {{
                                                list.code.content
                                                    ? list.code.content
                                                    : "-"
                                            }}
                                        </a>
                                    </template>
                                </template>
                                <div class="fw-normal p-0">
                                    Total Charge Weight:
                                    <span class="fw-bold">{{
                                        list.total_weight
                                            ? list.total_weight
                                            : 0
                                    }}</span>
                                    Kg
                                </div>
                                <div class="fw-normal p-0">
                                    Total Per Kg:
                                    <span class="fw-bold">{{
                                        currencyFormat(
                                            list.tier_price
                                                ? list.tier_price
                                                : 0
                                        )
                                    }}</span>
                                </div>
                                <div class="fw-normal p-0">
                                    Total Biaya:
                                    <span class="fw-bold">{{
                                        currencyFormat(
                                            list.total_amount
                                                ? list.total_amount
                                                : 0
                                        )
                                    }}</span>
                                </div>
                                <div class="fw-normal p-0">
                                    Status Pembayaran:
                                    <span class="fw-bold capitalize">{{
                                        list.payment_status
                                    }}</span>
                                </div>
                            </td>
                            <td class="td capitalize">
                                <span
                                    v-for="(mnf, index2) in list.picked_up_by"
                                    :key="index2"
                                >
                                    <template v-if="mnf.type == 'pickup'">
                                        {{ mnf.partner?.code ?? "-" }}
                                    </template>
                                </span>
                            </td>
                            <td class="td" style="width: 200px">
                                {{
                                    list.sender_way_point
                                        ? list.sender_way_point
                                        : "-"
                                }}
                            </td>
                            <td class="td capitalize">
                                {{ list.type2 ? list.type2 : "-" }}
                            </td>
                            <td class="td capitalize">
                                {{ list.type ? list.type : "-" }}
                            </td>
                            <td class="td capitalize">
                                <template
                                    v-if="
                                        list.corporate?.payment_method ==
                                            'va' &&
                                        list.payment_status == 'draft' &&
                                        list.parent_destination == null
                                    "
                                >
                                    <button
                                        class="btn btn-green btn-sm px-3"
                                        @click="showModalBank(list.id)"
                                    >
                                        Generate No VA
                                    </button>
                                </template>
                                <template v-else-if="list.parent_destination">
                                    <div
                                        style="
                                            background-color: #3d8824;
                                            color: white;
                                            border-radius: 50px;
                                            text-align: center;
                                            padding: 1px 0px;
                                        "
                                    >
                                        Resi Multi
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        style="
                                            background-color: #3d8824;
                                            color: white;
                                            border-radius: 50px;
                                            text-align: center;
                                            padding: 1px 0px;
                                        "
                                        v-if="list.payment_status == 'paid'"
                                    >
                                        <span class="uppercase">{{
                                            list.corporate?.payment_method ??
                                            "VA"
                                        }}</span>
                                        - {{ list.payment_status }}
                                    </div>
                                    <div
                                        style="
                                            background-color: #48a2d4;
                                            color: white;
                                            border-radius: 50px;
                                            text-align: center;
                                            padding: 1px 0px;
                                        "
                                        v-else-if="
                                            list.payment_status == 'draft'
                                        "
                                    >
                                        <span class="uppercase">{{
                                            list.corporate?.payment_method ??
                                            "VA"
                                        }}</span>
                                        - {{ list.payment_status }}
                                    </div>
                                    <div
                                        style="
                                            background-color: #fb9727;
                                            color: white;
                                            border-radius: 50px;
                                            text-align: center;
                                            padding: 1px 0px;
                                        "
                                        v-else-if="
                                            list.payment_status == 'pending'
                                        "
                                    >
                                        <span class="uppercase">{{
                                            list.corporate?.payment_method ??
                                            "VA"
                                        }}</span>
                                        - {{ list.payment_status }}
                                    </div>
                                    <div v-else>
                                        <span class="uppercase">{{
                                            list.corporate?.payment_method ??
                                            "VA"
                                        }}</span>
                                        - {{ list.payment_status }}
                                    </div>
                                </template>
                            </td>
                            <td class="td">
                                {{
                                    moment(list.created_at).format(
                                        "(HH:mm) DD MMMM YYYY"
                                    )
                                }}
                            </td>
                        </tr>
                        <tr class="border-footer bc-gray pb-5" :key="index">
                            <td class="p-2 pt-1 pb-1" colspan="8">
                                <div class="d-flex">
                                    <div>
                                        <template
                                            v-if="list.status == 'cancel'"
                                        >
                                            <span
                                                class="text-danger capitalize"
                                            >
                                                <i
                                                    class="fa fa-circle size-12 me-1"
                                                ></i>
                                                {{
                                                    list.status_label
                                                        ? list.status_label
                                                        : "-"
                                                }}
                                            </span>
                                        </template>
                                        <template
                                            v-else-if="
                                                list.status == 'delivered'
                                            "
                                        >
                                            <span class="text-green capitalize">
                                                <i
                                                    class="fa fa-circle size-12 me-1"
                                                ></i>
                                                {{
                                                    list.status_label
                                                        ? list.status_label
                                                        : "-"
                                                }}
                                                Oleh
                                                {{
                                                    list.received_by
                                                        ? list.received_by
                                                        : ""
                                                }}
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span
                                                class="text-yellow capitalize"
                                            >
                                                <i
                                                    class="fa fa-circle size-12 me-1"
                                                ></i>

                                                {{
                                                    list.status_label
                                                        ? list.status_label
                                                        : "-"
                                                }}
                                            </span>
                                        </template>
                                    </div>
                                    <div class="ms-4">
                                        <template
                                            v-if="
                                                list.multi_destination.length !=
                                                    0 &&
                                                list.parent_destination == null
                                            "
                                        >
                                            Ini adalah Multi Resi:
                                            <small
                                                class="tag green pt-0 pb-0 pr-resi pl-resi"
                                                :key="index"
                                                v-for="(
                                                    multi, index
                                                ) in list.multi_destination"
                                            >
                                                {{
                                                    multi?.packages?.code
                                                        ?.content
                                                }}
                                            </small>
                                        </template>
                                        <template
                                            v-if="
                                                list.multi_destination.length ==
                                                    0 &&
                                                list.parent_destination != null
                                            "
                                        >
                                            Multi Resi Parent :

                                            <small
                                                class="tag green pt-0 pb-0 pr-resi pl-resi"
                                            >
                                                {{
                                                    list?.parent_destination
                                                        ?.packages?.code
                                                        ?.content
                                                }}
                                            </small>
                                        </template>
                                    </div>
                                    <div class="ms-auto">
                                        <template
                                            v-if="
                                                isRole(rolesInvoice) &&
                                                list.payment_status == 'paid'
                                            "
                                        >
                                            <button
                                                class="btn btn-green btn-sm px-3 me-2"
                                                type="button"
                                                disabled
                                                v-if="is_download_invoice"
                                            >
                                                <span
                                                    class="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                <span class="visually-hidden"
                                                    >Loading...</span
                                                >
                                                Download Invoice
                                            </button>
                                            <button
                                                class="btn btn-green btn-sm px-3 me-2"
                                                v-else
                                                @click="
                                                    downloadInvoice(list.id)
                                                "
                                            >
                                                <i
                                                    class="fa fa-download me-1"
                                                ></i>
                                                Download Invoice
                                            </button>
                                        </template>
                                        <template
                                            v-if="
                                                list.status == 'pending' &&
                                                list.type == 'app'
                                            "
                                        >
                                            <a
                                                :href="
                                                    `/trawlpack/mitra/` +
                                                    list.hash
                                                "
                                                class="btn btn-yellow btn-sm px-3"
                                                v-show="isRole(rolesEditMitra)"
                                            >
                                                <i
                                                    class="fa fa-pencil me-1"
                                                ></i>
                                                Ubah Mitra
                                            </a>
                                        </template>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr :key="key">
                            <td colspan="9">
                                <div class=""></div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray-dashboard">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>

        <b-modal v-model="showModal" size="lg" hide-footer hide-header>
            <div class="p-4">
                <div class="d-flex align-items-center">
                    <h2 class="fw-bold">Detail Resi</h2>
                    <div
                        class="ms-auto cursor-pointer"
                        @click="showModal = false"
                    >
                        <i class="fa fa-times-circle size-28 text-danger"></i>
                    </div>
                </div>
                <hr />
                <div class="d-flex bd-highlight mt-4">
                    <div class="bd-highlight me-3">
                        <div
                            class="user-image"
                            :style="{
                                'background-image':
                                    'url(' +
                                    require('../../assets/logo-tb-order.png') +
                                    ')',
                            }"
                        ></div>
                    </div>
                    <div class="bd-highlight">
                        <div class="text-gray-dashboard capitalize">
                            Mitra {{ detail?.partner?.type }}
                        </div>
                        <div class="mt-2 fw-bold size-18">
                            {{ detail?.partner?.code }}
                        </div>
                        <div class="size-16">
                            {{
                                moment(detail?.created_at).format(
                                    "dddd[,] DD MMMM YYYY"
                                )
                            }}
                        </div>
                    </div>
                    <div class="ms-auto p-2 bd-highlight">
                        <div
                            v-if="
                                detail?.multi_destination?.length != 0 &&
                                detail?.parent_destination == null
                            "
                            class="text-gray-dashboard fw-medium"
                        >
                            Multi Resi
                        </div>
                        <div class="size-18 fw-semibold">
                            {{ detail?.code?.content }}
                        </div>
                        <div class="mt-1">
                            <span class="tag green-solid px-5">
                                <template v-if="detail?.service_code == 'tps'">
                                    Reguler
                                </template>
                                <template v-else> Express </template>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <div class="box-icon me-2">
                        <i class="fa fa-rocket"></i>
                    </div>
                    <div class="fw-bold size-16">Pengirim</div>
                </div>
                <div class="d-flex">
                    <div>
                        <div class="mt-2 size-16 fw-bold">
                            {{ detail.sender_name }}
                        </div>
                        <div class="text-dark size-14">
                            {{ detail.sender_phone }}
                        </div>
                    </div>
                </div>
                <div class="mt-2 size-14">
                    <b>Alamat Pengirim:</b>
                    <div class="mt-1">
                        {{ detail.sender_address }}
                    </div>
                </div>
                <div class="mt-2 size-14">
                    <b>Note:</b>
                    {{
                        detail?.sender_way_point
                            ? detail?.sender_way_point
                            : "-"
                    }}
                </div>
                <div class="mt-4">
                    <hr />
                </div>
                <div class="mt-2">
                    <div class="d-flex align-items-center">
                        <div class="box-icon me-2">
                            <i class="fa fa-bell"></i>
                        </div>
                        <div class="fw-bold size-16">Penerima</div>
                    </div>
                    <div class="d-flex">
                        <div>
                            <div class="mt-2 size-16 fw-bold">
                                {{ detail.receiver_name }}
                            </div>
                            <div class="text-dark size-14">
                                {{ detail.receiver_phone }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 size-14">
                        <b>Alamat Penerima:</b>
                        <div class="mt-1">
                            {{ detail.destination_regency.name }},
                            {{ detail.destination_district.name }},
                            {{ detail.destination_sub_district.name }}
                            {{ detail.destination_sub_district.zip_code }}<br />
                            {{ detail.receiver_address }}
                        </div>
                    </div>
                    <div class="size-14 mt-2">
                        <b>Note:</b>
                        {{
                            detail?.receiver_way_point
                                ? detail?.receiver_way_point
                                : "-"
                        }}
                    </div>
                </div>
                <div class="box green mt-3 no-shadow p-3">
                    <div class="d-flex align-items-center">
                        <div>
                            <i class="fa fa-cart-plus size-26"></i>
                        </div>
                        <div class="ms-4">
                            <div class="fw-bold size-16">Darat</div>
                            <div class="mt-1 size-13 capitalize">
                                {{ detail.price?.notes ?? "-" }}
                            </div>
                        </div>
                        <div class="ms-auto size-18 fw-bold">
                            {{ currencyFormat(detail.tier_price) }}
                        </div>
                    </div>
                </div>
                <div class="row foto">
                    <div class="col-md-12">
                        <template v-for="(item, index) in itemsCount">
                            <div :key="index">
                                <div class="size-16 fw-bold mt-4">
                                    <template
                                        v-if="detail.order_type != 'bike'"
                                    >
                                        Detail Barang {{ index + 1 }}
                                    </template>
                                    <template v-else>Detail Motor</template>
                                </div>
                                <div class="text-gray-dashboard capitalize">
                                    <template v-if="item.name == 'clothes'"
                                        >Pakaian</template
                                    >
                                    <template v-if="item.name == 'cosmetic'"
                                        >Kosmetik</template
                                    >
                                    <template v-if="item.name == 'accessories'"
                                        >Aksesoris</template
                                    >
                                    <template
                                        v-if="item.name == 'non_frozen_food'"
                                        >Makanan Non Frozen</template
                                    >
                                    <template v-if="item.name == 'electronic'"
                                        >Elektronik</template
                                    >
                                    <template v-if="item.name == 'furniture'"
                                        >Parabotan</template
                                    >
                                    <template v-if="item.name == 'bike'"
                                        >Motor</template
                                    >
                                    <template v-if="item.name == 'other'"
                                        >Lainnya</template
                                    >
                                </div>
                                <table class="mt-3">
                                    <tr v-if="detail.order_type != 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Keterangan Barang</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            <template v-if="item.name">
                                                {{
                                                    item.name +
                                                    (item.desc != null &&
                                                    item.desc != ""
                                                        ? " " + item.desc
                                                        : "")
                                                }}
                                            </template>
                                            <template v-else> - </template>
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type != 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Berat Barang
                                            </span>
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ item.weight }} Kg
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type != 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Dimensi Barang</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ item.length }} x
                                            {{ item.width }} x
                                            {{ item.height }}
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type != 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Berat Volume</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ item.weight_volume }} Kg
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type != 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Jumlah Koli
                                            </span>
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ item.qty }} Koli
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type == 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Merek Motor</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ detail.moto_bikes.merk }}
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type == 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >CC Motor</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ detail.moto_bikes.cc }} CC
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type == 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Tipe Motor</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ detail.moto_bikes.type }}
                                        </td>
                                    </tr>
                                    <tr v-if="detail.order_type == 'bike'">
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Keluaran Tahun</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{ detail.moto_bikes.years }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Jenis Packing</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            <template
                                                v-if="
                                                    detail.order_type == 'bike'
                                                "
                                            >
                                                <small
                                                    class="tag green capitalize"
                                                >
                                                    Bubble Wrap
                                                </small>
                                                <small
                                                    class="tag green capitalize"
                                                >
                                                    Karung Besar
                                                </small>
                                            </template>
                                            <template v-else>
                                                <template
                                                    v-if="item.handling != null"
                                                >
                                                    <template
                                                        v-for="(
                                                            list, index
                                                        ) in item.handling"
                                                    >
                                                        <small
                                                            class="tag green"
                                                            :key="index"
                                                        >
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'bubble wrap'
                                                                "
                                                            >
                                                                Bubble Wrap
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'cardboard'
                                                                "
                                                            >
                                                                Kardus
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'plastic'
                                                                "
                                                            >
                                                                Plastik
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'wood'
                                                                "
                                                            >
                                                                Kayu
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'sandbag sm'
                                                                "
                                                            >
                                                                Karung Kecil
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'sandbag md'
                                                                "
                                                            >
                                                                Karung Sedang
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'sandbag l'
                                                                "
                                                            >
                                                                Karung Besar
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    list.type ==
                                                                    'pallete'
                                                                "
                                                            >
                                                                Palet
                                                            </template>
                                                        </small>
                                                    </template>
                                                </template>
                                                <template v-else> - </template>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                            >
                                                <template
                                                    v-if="
                                                        detail.order_type !=
                                                        'bike'
                                                    "
                                                >
                                                    Biaya Packing
                                                </template>
                                                <template v-else
                                                    >Biaya Perlindungan
                                                    Wajib</template
                                                >
                                            </span>
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{
                                                currencyFormat(
                                                    item.total_packing
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 180px">
                                            <span
                                                class="text-gray-dashboard fw-normal"
                                                >Asuransi</span
                                            >
                                        </td>
                                        <td style="width: 30px">:</td>
                                        <td class="fw-bold">
                                            {{
                                                currencyFormat(
                                                    item.total_insurance
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </table>
                                <div
                                    class="box bc-gray no-shadow mt-3 mb-3 p-3"
                                >
                                    <div class="d-flex">
                                        <div class="fw-bold size-18">
                                            Sub Total Biaya
                                        </div>
                                        <div class="ms-auto fw-bold size-18">
                                            {{ currencyFormat(item.sub_total) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="size-16 fw-bold mt-4">
                    <template v-if="detail.order_type != 'bike'">
                        Foto Barang
                    </template>
                    <template v-else> Foto Motor </template>
                </div>
                <div class="row foto mt-3">
                    <template v-if="detail.attachments.length > 0">
                        <div
                            v-for="(image, index) in detail.attachments"
                            class="col-md-2 me-2"
                            :key="index"
                        >
                            <viewer
                                :images="detail.attachments"
                                :options="options"
                            >
                                <img
                                    class="image-product"
                                    :key="image.uri_stream"
                                    :src="image.uri_stream"
                                />
                            </viewer>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-md-2 me-2">
                            <div
                                class="items-image"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        require('../../assets/no-photo.png') +
                                        ')',
                                }"
                            ></div>
                        </div>
                    </template>
                </div>
                <div
                    class="box no-shadow green mt-4 p-3"
                    v-if="detail.transporter_detail"
                >
                    <div class="d-flex align-items-center">
                        <div>
                            <img
                                :src="detail.transporter_detail.path_icons"
                                width="70"
                                alt=""
                            />
                        </div>
                        <div class="ms-4">
                            <div class="fw-bold size-16 capitalize">
                                {{ detail.transporter_detail.name }}
                            </div>
                            <div class="mt-1 size-13 capitalize">
                                Maksimal Dimensi:
                                {{ detail.transporter_detail.length }}x{{
                                    detail.transporter_detail.height
                                }}x{{ detail.transporter_detail.width }}
                            </div>
                            <div class="size-13 capitalize">
                                Maksimal Berat:
                                {{ detail.transporter_detail.weight }}Kg
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box no-shadow no-border bc-gray mt-4">
                    <h5 class="mb-0 fw-bold">Rincian Pembayaran</h5>
                    <div class="line"></div>
                    <div class="row">
                        <div class="col-md-5 size-16 fw-bold">
                            Biaya Penjemputan
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold">
                            {{ currencyFormat(pickupFee) }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Jumlah Biaya Packing
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2">
                            {{ currencyFormat(detail.total_handling_price) }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Biaya Pengiriman
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2">
                            {{ currencyFormat(detail.service_price) }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Biaya Asuransi
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2">
                            {{ currencyFormat(insuranceFee) }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Biaya Jasa Aplikasi
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2">
                            {{ currencyFormat(platformFee) }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Diskon pengiriman
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2">
                            {{ currencyFormat(detail?.discount_service_price) }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Diskon penjemputan
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2">
                            {{ currencyFormat(detail?.discount_pickup_price) }}
                        </div>
                        <div
                            class="col-md-5 size-16 fw-bold mt-2"
                            v-if="detail.order_type != 'bike'"
                        >
                            Biaya Tambahan
                        </div>
                        <div
                            class="col-md-1"
                            v-if="detail.order_type != 'bike'"
                        >
                            :
                        </div>
                        <div
                            class="col-md-6 size-16 fw-bold mt-2"
                            v-if="detail.order_type != 'bike'"
                        >
                            {{
                                currencyFormat(detail?.additional_service_price)
                            }}
                        </div>
                        <div
                            class="col-md-5 size-16 fw-bold mt-2"
                            v-if="detail.order_type != 'bike'"
                        >
                            Total Berat
                        </div>
                        <div
                            class="col-md-1"
                            v-if="detail.order_type != 'bike'"
                        >
                            :
                        </div>
                        <div
                            class="col-md-6 size-16 fw-bold mt-2"
                            v-if="detail.order_type != 'bike'"
                        >
                            {{ detail?.total_weight }}Kg
                        </div>
                        <div class="col-md-12">
                            <hr />
                        </div>
                        <div class="col-md-5 size-18 fw-bold">Total Biaya</div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-18 fw-bold">
                            {{ currencyFormat(detail.total_amount) }}
                        </div>
                    </div>
                </div>
                <div
                    class="box no-shadow no-border bc-gray mt-4"
                    v-if="detail.trx_payment"
                >
                    <h5 class="mb-0 fw-bold">Biaya Admin VA</h5>
                    <div class="line"></div>
                    <div class="row">
                        <div class="col-md-5 size-16 fw-bold">Bank</div>
                        <div class="col-md-1">:</div>
                        <div class="col-md-6 size-16 fw-bold uppercase">
                            {{ detail?.trx_payment?.bank }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Total Bayar
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2 capitalize">
                            {{ currencyFormat(detail?.trx_payment?.amount) }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">
                            Biaya Admin
                        </div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2 capitalize">
                            {{ currencyFormat(detail?.trx_payment?.admin) }}
                        </div>
                        <div
                            class="col-md-5 size-16 fw-bold mt-2"
                            v-if="detail?.trx_payment?.status != 'success'"
                        >
                            Batas Waktu
                        </div>
                        <div
                            class="col-md-1 mt-2"
                            v-if="detail?.trx_payment?.status != 'success'"
                        >
                            :
                        </div>
                        <div
                            class="col-md-6 size-16 fw-bold mt-2 capitalize"
                            v-if="detail?.trx_payment?.status != 'success'"
                        >
                            {{
                                moment(detail?.trx_payment?.expired_at).format(
                                    "DD MMM YYYY HH:mm"
                                )
                            }}
                        </div>
                        <div
                            class="col-md-5 size-16 fw-bold mt-2"
                            v-if="detail?.trx_payment?.status != 'success'"
                        >
                            Kode Pembayaran VA
                        </div>
                        <div
                            class="col-md-1 mt-2"
                            v-if="detail?.trx_payment?.status != 'success'"
                        >
                            :
                        </div>
                        <div
                            class="col-md-6 size-16 fw-bold mt-2 capitalize"
                            v-if="detail?.trx_payment?.status != 'success'"
                        >
                            {{ detail?.payment?.payment_content }}
                        </div>
                        <div
                            class="col-md-5 size-16 fw-bold mt-2"
                            v-if="detail?.trx_payment?.status == 'success'"
                        >
                            Waktu Bayar
                        </div>
                        <div
                            class="col-md-1 mt-2"
                            v-if="detail?.trx_payment?.status == 'success'"
                        >
                            :
                        </div>
                        <div
                            class="col-md-6 size-16 fw-bold mt-2 capitalize"
                            v-if="detail?.trx_payment?.status == 'success'"
                        >
                            {{
                                moment(detail?.trx_payment?.paid_at).format(
                                    "DD MMM YYYY HH:mm"
                                )
                            }}
                        </div>
                        <div class="col-md-5 size-16 fw-bold mt-2">Status</div>
                        <div class="col-md-1 mt-2">:</div>
                        <div class="col-md-6 size-16 fw-bold mt-2 capitalize">
                            {{ detail?.trx_payment?.status }}
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <div class="d-flex">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <div class="me-3">
                        <select
                            class="form-control form-select w-select"
                            v-model="per_page"
                            @change="getCorporateLists()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="">
                        <b-pagination-nav
                            v-if="lists.data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage()"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="showModalPaymentMethod"></div>
            <div class="modal-body-chat sm" v-if="showModalPaymentMethod">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <h5 class="mb-0 fw-bold size-22">
                            Pilih Bank Pembayaran
                        </h5>
                    </div>
                    <div class="ms-auto">
                        <a
                            href="javascript:void(0)"
                            @click="showModalPaymentMethod = false"
                        >
                            <i
                                class="fa fa-times-circle size-20 text-danger"
                            ></i>
                        </a>
                    </div>
                </div>
                <div class="mt-2">
                    Pilih bank dibawah ini untuk melanjutkan proses pembayaran
                    anda
                </div>
                <div class="mt-4">
                    <div class="row mt-3">
                        <template v-for="(item, index) in payment_lists">
                            <div class="col-md-6 mt-3" :key="index">
                                <label class="plan payment" :for="item.channel">
                                    <input
                                        type="radio"
                                        :id="item.channel"
                                        name="bank-item"
                                        v-model="req.is_bank"
                                        :value="item.channel"
                                    />
                                    <div
                                        class="plan-content text-center capitalize fw-bold p-3"
                                    >
                                        <img
                                            :src="item.picture"
                                            width="100"
                                            alt=""
                                        />
                                    </div>
                                </label>
                            </div>
                        </template>
                    </div>
                    <div class="mt-5">
                        <button
                            class="btn btn-green btn-lg w-100"
                            type="button"
                            disabled
                            v-if="is_payment_loading"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Loading...</span>
                            Lanjut
                        </button>
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="createPayment()"
                            :disabled="req.is_bank == ''"
                            v-else
                        >
                            Lanjut
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import debounce from "lodash/debounce";

export default {
    components: {
        ListLoader,
        Viewer,
    },
    name: "CorporateList",
    data() {
        return {
            moment: moment,
            lists: {
                data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            mitra_count: {},
            is_my_list_ready: false,
            currentPage: 1,
            totalPage: 0,
            per_page: 10,
            detail: {
                partner: {},
                code: {},
                corporate: {},
                items: [],
                price: {},
                prices: [],
                attachments: [],
                destination_regency: {},
                destination_district: {},
                destination_sub_district: {},
            },
            showModal: false,
            item_index: 0,
            total_packing: 0,
            req: {
                status: "",
                start: "",
                end: "",
                search: "",
                is_bank: null,
            },
            prices_data: null,
            rolesVA: [
                "trawlpack-partner-cs",
                "trawlpack-partner-cs-space",
                "trawlpack-partner-cashier",
                "trawlpack-partner-owner",
            ],
            rolesEditMitra: ["admin-super", "ho-operation"],
            rolesInvoice: [
                "trawlpack-partner-cashier",
                "admin-trawlpack",
                "admin-super",
                "ho-finance",
            ],
            roles: [],
            showModalPaymentMethod: false,
            package_id: "",
            payment_lists: [],
            is_payment_loading: false,
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            is_download_invoice: false,
        };
    },
    created() {
        this.getCorporateListsDebounced = debounce(
            this.getCorporateLists,
            5000
        );
        this.getCorporateLists();
        this.getUserLogin();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {
        insuranceFee() {
            let result = 0;
            this.detail?.prices?.forEach((el) => {
                if (
                    el.type == "insurance" &&
                    el.description == "insurance" &&
                    el.amount
                ) {
                    result = result + el.amount;
                }
            });
            return result > 0 ? parseInt(result) : 0;
        },
        platformFee() {
            let result = "-";
            this.detail?.prices?.forEach((el) => {
                if (
                    el.type == "platform" &&
                    el.description == "fee" &&
                    el.amount
                ) {
                    result = el.amount;
                }
            });
            return result == "-" ? "-" : parseInt(result);
        },
        pickupFee() {
            let result = 0;
            this.detail?.prices?.forEach((el) => {
                if (
                    el.type == "delivery" &&
                    el.description == "pickup" &&
                    el.amount
                ) {
                    result = el.amount;
                }
            });
            return result == "-" ? "-" : parseInt(result);
        },
        itemsCount() {
            let itemsCount = [];
            this.detail.items.forEach((item) => {
                console.log(item);
                if (item.prices.length > 0) {
                    let packings = item.prices.map((val) => {
                        if (val.type == "handling") {
                            return val.amount;
                        } else {
                            return 0;
                        }
                    });
                    let insurances = item.prices.map((val) => {
                        if (val.type == "insurance") {
                            return val.amount;
                        } else {
                            return 0;
                        }
                    });
                    let sub_totals = item.prices.map((val) => {
                        return val.amount;
                    });
                    let total_packing = packings.reduce((a, b) => a + b);
                    let total_insurance = insurances.reduce((a, b) => a + b);
                    let sub_total = sub_totals.reduce((a, b) => a + b);
                    itemsCount.push({
                        name: item.name,
                        desc: item.desc,
                        weight: item.weight,
                        height: item.height,
                        width: item.width,
                        length: item.length,
                        handling: item.handling,
                        qty: item.qty,
                        weight_volume: item.weight_volume,
                        total_packing: total_packing,
                        total_insurance: total_insurance,
                        sub_total: sub_total,
                    });
                } else {
                    itemsCount.push({
                        name: item.name,
                        desc: item.desc,
                        weight: item.weight,
                        height: item.height,
                        width: item.width,
                        length: item.length,
                        handling: item.handling,
                        qty: item.qty,
                        weight_volume: item.weight_volume,
                        total_packing: 0,
                        total_insurance: 0,
                        sub_total: 0,
                    });
                }
            });
            return itemsCount;
        },
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        changeSearch() {
            this.currentPage = 1;
            this.getCorporateListsDebounced();
        },
        changePage() {
            this.getCorporateListsDebounced();
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getCorporateLists() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/partner/corporate/order-list?start_date=${this.req.start}&end_date=${this.req.end}`,
                {
                    params: {
                        search: this.req.search,
                        status: this.req.status,
                        per_page: this.per_page,
                        page: this.currentPage,
                    },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.lists = res.data;
                    this.totalPage = res.data.last_page;
                    // this.currentPage = res.data.current_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        toggleModal(id) {
            this.showModal = true;
            this.getOrderDetail(id);
        },
        getOrderDetail(id) {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/partner/corporate/order-detail?package_id=${id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                })

                .catch((err) => {
                    console.log(err);
                });
        },
        showModalBank(id) {
            this.package_id = id;
            this.showModalPaymentMethod = true;
            this.getBank();
        },
        getBank() {
            Api.get(
                `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment?package_id=${this.package_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.payment_lists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        createPayment() {
            var data = {
                package_id: this.package_id,
                payment_channel: this.req.is_bank,
            };
            this.is_payment_loading = true;
            Api.post(
                `${process.env.VUE_APP_BASE_URL}/partner/corporate/order/payment`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_payment_loading = false;
                    this.payment_result = res.data.data;
                    this.showModalPaymentMethod = false;
                    this.getCorporateLists();
                    window.open(
                        `/corporate/order-detail/${this.package_id}`,
                        "_blank"
                    );
                })
                .catch((err) => {
                    this.is_payment_loading = false;
                    console.log(err);
                });
        },
        downloadInvoice(id) {
            var data = {
                order_id: id,
            };
            this.is_download_invoice = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/partner/trawlpack/invoice`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    window.open(res.data.data.file_url, "_blank");
                    this.is_download_invoice = false;
                })
                .catch((err) => {
                    this.is_download_invoice = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>
<style scoped>
.row.foto {
    --bs-gutter-x: 0rem !important;
}
.items-image {
    width: 100%;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: 2px solid #e2e3e5;
}
.table .th,
.table .td {
    padding: 12px 12px !important;
    vertical-align: top;
}
.image-product {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 100px;
    cursor: pointer;
}
</style>
