<template>
    <div
        class="page-wrapper chiller-theme"
        v-bind:class="!isMobile ? 'toggled' : ''"
    >
        <notifications group="foo" position="top right" />
        <div class="btn btn-sm btn-primary" id="show-sidebar">
            <i class="fa fa-angle-right"></i>
        </div>
        <nav id="sidebar" class="sidebar-wrapper">
            <div class="sidebar-content">
                <div class="sidebar-brand">
                    <div id="close-sidebar">
                        <i class="fa fa-angle-left"></i>
                    </div>
                </div>
                <div class="sidebar-header">
                    <div class="">
                        <center>
                            <div>
                                <img
                                    src="../assets/logo.png"
                                    width="70"
                                    alt=""
                                />
                            </div>
                            <div class="mt-3 fw-bold size-16">
                                Dashboard Troben
                            </div>
                            <!-- <div class="size-14 text-gray">
                                {{ user.phone }}
                            </div> -->
                        </center>
                    </div>
                </div>
                <hr class="hr-horizontal mb-3" />
                <div class="sidebar-menu">
                    <ul>
                        <li v-show="isRole(dashboardTrawlpackHoFinanceRoles)">
                            <a
                                href="/trawlpack/ho/finance"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'dashboard-finance',
                                }"
                            >
                                <i class="fa fa-dashboard"></i>
                                Dashboard Finance
                            </a>
                        </li>
                        <li v-show="isRole(dashboardTrawlpackHoOperationRoles)">
                            <a
                                href="/trawlpack/ho/operation"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'dashboard-operation',
                                }"
                            >
                                <i class="fa fa-dashboard"></i>
                                Dashboard Operation
                            </a>
                        </li>
                        <!-- Set Harga Layanan -->
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(roleOnlyAdmin)"
                        >
                            <a
                                href="/service/setharga-layanan"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'set-harga-layanan',
                                }"
                            >
                                <i class="fa fa-money"></i>
                                <span>Set Harga Layanan</span>
                            </a>
                        </li>
                        <!-- Cargo Menu -->
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(TropackRoles)"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'admin-corporate-list' ||
                                        this.$route.name ==
                                            'admin-trawlpack-mitra-edit' ||
                                        this.$route.name == 'admin-income-mb' ||
                                        this.$route.name ==
                                            'admin-income-mb-detail' ||
                                        this.$route.name == 'admin-income-ms' ||
                                        this.$route.name ==
                                            'admin-income-ms-detail' ||
                                        this.$route.name == 'warehouse-mb' ||
                                        this.$route.name ==
                                            'warehouse-mb-detail' ||
                                        this.$route.name == 'warehouse-mpw' ||
                                        this.$route.name ==
                                            'warehouse-mpw-detail' ||
                                        this.$route.name == 'warehouse-ms' ||
                                        this.$route.name ==
                                            'warehouse-ms-detail' ||
                                        this.$route.name ==
                                            'partner-disbursement' ||
                                        this.$route.name ==
                                            'partner-disbursement-detail' ||
                                        this.$route.name ==
                                            'agent-disbursement' ||
                                        this.$route.name ==
                                            'agent-disbursement-detail' ||
                                        this.$route.name == 'manifest' ||
                                        this.$route.name == 'detail-manifest' ||
                                        this.$route.name ==
                                            'assign-transporter' ||
                                        this.$route.name == 'receipt' ||
                                        this.$route.name ==
                                            'admin-customer-payment' ||
                                        this.$route.name ==
                                            'trawltruck-cubic-price' ||
                                        this.$route.name == 'harbors' ||
                                        this.$route.name == 'harbors-create' ||
                                        this.$route.name == 'harbors-edit',
                                }"
                            >
                                <i class="fa fa-cube"></i>
                                <span>Troben Cargo</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'admin-corporate-list'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlpack-mitra-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-corporate-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-corporate-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlpack-partner-incoming-order'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-income-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-income-mb-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-income-ms'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-income-ms-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-income-mpw'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-income-mpw-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-income-mt'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-income-mt-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-income-mp'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-income-mp-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'warehouse-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'warehouse-mb-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'warehouse-mpw'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'warehouse-mpw-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'warehouse-ms'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'warehouse-ms-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'partner-disbursement'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'partner-disbursement-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'agent-disbursement'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'agent-disbursement-detail'
                                        ? 'block'
                                        : '' || this.$route.name == 'manifest'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'detail-manifest'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'assign-transporter'
                                        ? 'block'
                                        : '' || this.$route.name == 'receipt'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-customer-payment'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-cubic-price'
                                        ? 'block'
                                        : '' || this.$route.name == 'harbors'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'harbors-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'harbors-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-corporate-list'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlpack-mitra-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-corporate-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-corporate-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlpack-partner-incoming-order'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-income-mb'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-income-mb-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-income-ms'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-income-ms-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-income-mpw'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-income-mpw-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-income-mt'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-income-mt-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-income-mp'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-income-mp-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'warehouse-mb'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'warehouse-mb-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'warehouse-mpw'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'warehouse-mpw-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'warehouse-ms'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'warehouse-ms-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'partner-disbursement'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'partner-disbursement-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'agent-disbursement'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'agent-disbursement-detail'
                                        ? 'none'
                                        : '' || this.$route.name != 'manifest'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'detail-manifest'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'assign-transporter'
                                        ? 'none'
                                        : '' || this.$route.name != 'receipt'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-customer-payment'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-cubic-price'
                                        ? 'none'
                                        : '' || this.$route.name != 'harbors'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'harbors-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'harbors-edit'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <!-- <li
                                        v-show="
                                            isRole(dashboardTrawlpackHoRoles)
                                        "
                                        class="sidebar-dropdown1"
                                        :class="{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                    'dashboard-finance' ||
                                                this.$route.name ==
                                                    'dashboard-operation',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'dashboard-finance'
                                                    ? 'active second mt-1'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'dashboard-operation'
                                                    ? 'active second mt-1'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-dashboard sub"></i>
                                            <span>Dashboard</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'dashboard-finance'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'dashboard-operation'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'dashboard-finance'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'dashboard-operation'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardTrawlpackHoFinanceRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawlpack/ho/finance"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'dashboard-finance',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Finance
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardTrawlpackHoOperationRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawlpack/ho/operation"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'dashboard-operation',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Operation
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> -->
                                    <li>
                                        <a
                                            href="/trawlpack/general/order"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-corporate-list' ||
                                                    this.$route.name ==
                                                        'admin-trawlpack-mitra-edit',
                                            }"
                                        >
                                            <i class="fa fa-file sub"></i>
                                            Daftar Pesanan
                                        </a>
                                    </li>
                                    <li v-show="isRole(hideAdminRole)">
                                        <a
                                            href="/trawlpack/partner/incoming-order"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-trawlpack-partner-incoming-order',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Incoming Order
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(partnerIncomeRoles)"
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'admin-income-mb',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'admin-income-mb'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mb-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-ms'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-ms-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mpw'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mpw-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mt'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mt-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mp'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mp-detail'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-dollar sub"></i>
                                            <span>Pendapatan Mitra</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'admin-income-mb'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mb-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-ms'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-ms-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mpw'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mpw-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mt'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mt-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mp'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'admin-income-mp-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mb'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mb-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-ms'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-ms-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mpw'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mpw-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mt'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mt-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mp'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'admin-income-mp-detail'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/income/mitra-business"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mb' ||
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mb-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Mitra Business
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/income/mitra-space"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-ms' ||
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-ms-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Mitra Space
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/income/mitra-pool-warehouse"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mpw' ||
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mpw-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Mitra Pool Warehouse
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/income/mitra-transporter"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mt' ||
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mt-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Mitra Transporter
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/income/mitra-pos"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mp' ||
                                                                this.$route
                                                                    .name ==
                                                                    'admin-income-mp-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Mitra Pos
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li v-show="isRole(disbursementRoles)">
                                        <a
                                            href="/trawlpack/ho/disbursement/partner"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'partner-disbursement' ||
                                                    this.$route.name ==
                                                        'partner-disbursement-detail',
                                            }"
                                        >
                                            <i class="fa fa-money sub"></i>
                                            Pencairan Mitra
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(dataItemRoles)"
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'warehouse-mb',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'warehouse-mb'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-mb-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-mpw'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-mpw-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-ms '
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-ms-detail'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-cubes sub"></i>
                                            <span>Data Barang</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'warehouse-mb'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-mb-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-mpw'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-mpw-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-ms'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'warehouse-ms-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'warehouse-mb'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'warehouse-mb-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'warehouse-mpw'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'warehouse-mpw-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'warehouse-ms'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'warehouse-ms-detail'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/inventory/mitra-business"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'warehouse-mb' ||
                                                                this.$route
                                                                    .name ==
                                                                    'warehouse-mb-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Gudang M.Business
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/inventory/mitra-warehouse"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'warehouse-mpw' ||
                                                                this.$route
                                                                    .name ==
                                                                    'warehouse-mpw-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Gudang M.Warehouse
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/inventory/mitra-space"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'warehouse-ms' ||
                                                                this.$route
                                                                    .name ==
                                                                    'warehouse-ms-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Gudang M.Space
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li
                                        v-show="isRole(trackingOrderRoles)"
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name == 'manifest',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name == 'manifest'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'detail-manifest'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'assign-transporter'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'receipt'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-globe sub"></i>
                                            <span>Tracking Order</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name == 'manifest'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'detail-manifest'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'assign-transporter'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'receipt'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'manifest'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'detail-manifest'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'assign-transporter'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'receipt'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/tracking-order/manifest"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'manifest' ||
                                                                this.$route
                                                                    .name ==
                                                                    'detail-manifest',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Cek Manifest
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            trackingAssignTransporterRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawlpack/ho/tracking-order/assign-transporter"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'assign-transporter' ||
                                                                this.$route
                                                                    .name ==
                                                                    'assign-transporter',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Assign Transporter
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/trawlpack/ho/tracking-order/receipt"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'receipt' ||
                                                                this.$route
                                                                    .name ==
                                                                    'receipt',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Cek Resi
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li v-show="isRole(customerPaymentRoles)">
                                        <a
                                            href="/trawlpack/ho/finance/customer-payment"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-customer-payment',
                                            }"
                                        >
                                            <i class="fa fa-money sub"></i>
                                            Customer payment
                                        </a>
                                    </li>
                                    <li
                                        class="sidebar-dropdown"
                                        v-show="isRole(rolePriceCubic)"
                                    >
                                        <a
                                            href="/trawltruck/cubic/price"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                    'trawltruck-cubic-price',
                                            }"
                                        >
                                            <i class="fa fa-dollar sub"></i>
                                            <span>Cek Harga Kubikasi</span>
                                        </a>
                                    </li>
                                    <li
                                        class="sidebar-dropdown"
                                        v-show="isRole(roleJadwalKapal)"
                                    >
                                        <a
                                            href="/harbors"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'harbors' ||
                                                    this.$route.name ==
                                                        'harbors-create' ||
                                                    this.$route.name ==
                                                        'harbors-edit',
                                            }"
                                        >
                                            <i class="fa fa-calendar sub"></i>
                                            <span>Jadwal Kapal</span>
                                        </a>
                                    </li>
                                    <li v-show="isRole(disbursementRoles)">
                                        <a
                                            href="/trawlpack/ho/disbursement/sales-agent"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'agent-disbursement' ||
                                                    this.$route.name ==
                                                        'agent-disbursement-detail',
                                            }"
                                        >
                                            <i class="fa fa-money sub"></i>
                                            Pencairan Agen
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(InvoiceRoles)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'trawlpack-send-invoice',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'trawlpack-send-invoice'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'trawlpack-send-invoice-create'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'trawlpack-invoice'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-clipboard sub"></i>
                                <span>Invoice</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'trawlpack-send-invoice'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawlpack-send-invoice-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawlpack-invoice'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'trawlpack-send-invoice'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawlpack-send-invoice-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawlpack-invoice'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/trawlpack/invoice/send"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'trawlpack-send-invoice' ||
                                                    this.$route.name ==
                                                        'trawlpack-send-invoice-create',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Send Invoice
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlpack/invoice/print"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'trawlpack-invoice',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Invoice Page
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <!-- Truk Menu -->
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(TrotruckkRoles)"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name ==
                                    'dashboard-trawltruck-ho-finance'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'dashboard-trawltruck-ho-operation'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-ho-cek-tarif'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-management-driver'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'management-fleet'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-management-fleet'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'incoming-order'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'management-order'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-order-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-disbursement'
                                        ? 'active mb-1'
                                        : '' || this.$route.name == 'topup'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'management-mitra'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-mitra-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-mitra-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-mitra-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'management-truck'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-edit-status'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-detail-old'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-delivery-payment'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-delivery-payment-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-delivery-payment-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-disbursement'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-disbursement-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-master-route'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-master-route-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-master-route-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-aggrement'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-aggrement-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-aggrement-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-stand-by'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-stand-by-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'register-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'register-driver-reject'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'account-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-account-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'suspend-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'tracking-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'tracking-driver-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'disbursement-of-funds'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'disbursement-of-funds-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'assign-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'assign-driver-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-withdraw-individu'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-withdraw-individu-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-balance'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-balance-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-commission-sticker'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-commission-sticker-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'truck-sticker'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-sticker-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-sticker-reject'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-truck"></i>
                                <span>Troben Truk</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name ==
                                    'dashboard-trawltruck-ho-finance'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'dashboard-trawltruck-ho-operation'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-ho-cek-tarif'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'register-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'account-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-account-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'register-driver-reject'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'suspend-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'disbursement-of-funds'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'disbursement-of-funds-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'tracking-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'tracking-driver-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'management-truck'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-edit-status'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-detail-old'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-order-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-drivers-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-delivery-payment'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-delivery-payment-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-delivery-payment-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-disbursement'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-disbursement-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-master-route'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-master-route-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-master-route-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-aggrement'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-aggrement-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-aggrement-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-stand-by'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-truck-stand-by-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'assign-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'assign-driver-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-withdraw-individu'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-withdraw-individu-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-balance'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-balance-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-commission-sticker'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-commission-sticker-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'truck-sticker'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-sticker-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'truck-sticker-reject'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'dashboard-trawltruck-ho-finance'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'dashboard-trawltruck-ho-operation'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-ho-cek-tarif'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'register-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'account-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'detail-account-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'register-driver-reject'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'suspend-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'disbursement-of-funds'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'disbursement-of-funds-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'tracking-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'tracking-driver-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'management-mitra'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-mitra-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-mitra-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-mitra-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'management-truck'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-truck-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-truck-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-truck-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-ho-order'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-ho-order-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-ho-order-edit-status'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-ho-order-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-ho-order-detail-old'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-ho-order-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-drivers'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-drivers-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-drivers-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-drivers-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-delivery-payment'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-delivery-payment-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-delivery-payment-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-ho-disbursement'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-disbursement-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-master-route'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-master-route-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-master-route-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-aggrement'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-aggrement-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-aggrement-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-truck-stand-by'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'management-truck-stand-by-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'trawltruck-mitra'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'assign-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'assign-driver-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-withdraw-individu'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-withdraw-individu-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-balance'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-balance-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'truck-commission-sticker'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'truck-commission-sticker-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'truck-sticker'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'truck-sticker-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'truck-sticker-reject'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li
                                        v-show="
                                            isRole(dashboardTrawltruckHoRoles)
                                        "
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'dashboard-trawltruck-ho-finance',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'dashboard-trawltruck-ho-finance'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'dashboard-trawltruck-ho-operation'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-dashboard sub"></i>
                                            <span>Dashboard</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'dashboard-trawltruck-ho-finance'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'dashboard-trawltruck-ho-operation'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'dashboard-trawltruck-ho-finance'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'dashboard-trawltruck-ho-operation'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardTrawltruckHoFinanceRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/dashboard/finance"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'dashboard-trawltruck-ho-finance',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Finance
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardTrawltruckHoOperationRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/dashboard/operation"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'dashboard-trawltruck-ho-operation',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Operation
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li
                                        v-show="isRole(tarifHoTrawltruck)"
                                        class="sidebar-dropdown1"
                                    >
                                        <a
                                            href="/trawltruck/ho/cek-tarif"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                    'trawltruck-ho-cek-tarif',
                                            }"
                                        >
                                            <i class="fa fa-dollar sub"></i>
                                            <span>Cek Tarif</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(financeRoles)"
                                        class="sidebar-dropdown1"
                                    >
                                        <a
                                            href="/trawltruck/withdraw/individu"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'trawltruck-withdraw-individu' ||
                                                    this.$route.name ==
                                                        'trawltruck-withdraw-individu-detail',
                                            }"
                                        >
                                            <i class="fa fa-money sub"></i>
                                            <span>Tarik Dana</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(financeRoles)"
                                        class="sidebar-dropdown1"
                                    >
                                        <a
                                            href="/trawltruck/mitra/balance"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'trawltruck-mitra-balance' ||
                                                    this.$route.name ==
                                                        'trawltruck-mitra-balance-detail',
                                            }"
                                        >
                                            <i class="fa fa-money sub"></i>
                                            <span>Saldo Mitra</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(trawltruckRoles)"
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'register-driver',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'register-driver'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'register-driver-reject'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'account-driver'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'detail-account-driver'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'suspend-driver'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'tracking-driver'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'tracking-driver-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'disbursement-of-funds'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'disbursement-of-funds-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'assign-driver'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'assign-driver-detail'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-user sub"></i>
                                            <span>Individu</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'register-driver'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'register-driver-reject'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'account-driver'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'detail-account-driver'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'suspend-driver'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'tracking-driver'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'tracking-driver-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'disbursement-of-funds'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'disbursement-of-funds-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'assign-driver'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'assign-driver-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'register-driver'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'register-driver-reject'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'account-driver'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'detail-account-driver'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'suspend-driver'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'tracking-driver'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'tracking-driver-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'disbursement-of-funds'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'disbursement-of-funds-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'assign-driver'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'assign-driver-detail'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li
                                                    v-show="
                                                        isRole(financeRoles)
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/disbursement"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'disbursement-of-funds' ||
                                                                this.$route
                                                                    .name ==
                                                                    'disbursement-of-funds-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Pencairan Dana
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            assignDriverRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/assign-driver"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'assign-driver' ||
                                                                this.$route
                                                                    .name ==
                                                                    'assign-driver-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Assign Register Driver
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/driver/registration"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'register-driver' ||
                                                                this.$route
                                                                    .name ==
                                                                    'register-driver-reject',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Register Driver
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/driver/account"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'account-driver' ||
                                                                this.$route
                                                                    .name ==
                                                                    'detail-account-driver',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Account Driver
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/order/tracking"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'tracking-driver' ||
                                                                this.$route
                                                                    .name ==
                                                                    'tracking-driver-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Tracking Order
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/driver/suspend"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'suspend-driver',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Suspend Driver
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li
                                        v-show="
                                            isRole(
                                                dashboardHoTrawltruckFinanceRoles
                                            )
                                        "
                                    >
                                        <a
                                            href="/trawltruck/ho/order"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'management-ho-order' ||
                                                    this.$route.name ==
                                                        'management-ho-order-edit' ||
                                                    this.$route.name ==
                                                        'management-ho-order-edit-status' ||
                                                    this.$route.name ==
                                                        'management-ho-order-detail' ||
                                                    this.$route.name ==
                                                        'management-ho-order-detail-old' ||
                                                    this.$route.name ==
                                                        'management-ho-order-create',
                                            }"
                                        >
                                            <i class="fa fa-file-o sub"></i>
                                            <span>Management Order</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="
                                            isRole(
                                                dashboardHoTrawltruckFinanceRoles
                                            )
                                        "
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'management-mitra',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'management-mitra'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-mitra-create'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-mitra-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-mitra-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-create'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers-create'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-delivery-payment'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-delivery-payment-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-delivery-payment-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-master-route'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-master-route-create'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-master-route-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-aggrement'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-aggrement-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-aggrement-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-stand-by'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-stand-by-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-ho-disbursement'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-disbursement-detail'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-building sub"></i>
                                            <span>Corporate</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'management-mitra'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-mitra-create'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-mitra-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-mitra-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-create'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers-create'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-drivers-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-delivery-payment'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-delivery-payment-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-delivery-payment-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-master-route'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-master-route-create'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-master-route-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-aggrement'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-aggrement-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-aggrement-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-stand-by'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-truck-stand-by-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-ho-disbursement'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'management-disbursement-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-mitra'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-mitra-create'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-mitra-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-mitra-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-truck'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-truck-create'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-truck-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-truck-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-drivers'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-drivers-create'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-drivers-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-drivers-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-delivery-payment'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-delivery-payment-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-delivery-payment-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-master-route'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-master-route-create'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-master-route-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-aggrement'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-aggrement-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-aggrement-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-truck-stand-by'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-truck-stand-by-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-ho-disbursement'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'management-disbursement-detail'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li
                                                    v-show="
                                                        isRole(financeRoles)
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/disbursement"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-ho-disbursement' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-disbursement-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Pencairan Dana
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/partner"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-mitra' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-mitra-create' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-mitra-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-mitra-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Management Mitra
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/fleet"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-truck' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-truck-create' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-truck-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-truck-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Management Truk
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/driver"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-drivers' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-drivers-create' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-drivers-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-drivers-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Management Driver
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckFinanceRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/delivery-payment"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-delivery-payment' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-delivery-payment-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-delivery-payment-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Uang Jalan
                                                    </a>
                                                </li>
                                                <!-- <li>
                                                    <a
                                                        href="/trawltruck/ho/master/fleet"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-master-fleet' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-master-fleet-create' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-master-fleet-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-master-fleet-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Jenis Truk
                                                    </a>
                                                </li> -->
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/master/route"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-master-route' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-master-route-create' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-master-route-edit',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Management Rute
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/aggrement"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-aggrement' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-aggrement-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-aggrement-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Perjanjian Kerjasama
                                                    </a>
                                                </li>
                                                <!-- <li>
                                                    <a
                                                        href="/trawltruck/ho/topup"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-topup' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-topup-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-topup-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Manajemen Asuransi
                                                    </a>
                                                </li> -->
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardHoTrawltruckRoles
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/trawltruck/ho/truck-stand-by"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'management-truck-stand-by' ||
                                                                this.$route
                                                                    .name ==
                                                                    'management-truck-stand-by-edit',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Truk Stand By
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li
                                        v-show="isRole(dashboardTrawlcarrier)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/truck/commission/sticker"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'truck-commission-sticker' ||
                                                    this.$route.name ==
                                                        'truck-commission-sticker-detail',
                                            }"
                                        >
                                            <i class="fa fa-money"></i>
                                            <span>Komisi Sticker Truck</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(truckStickerRoles)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/truck/sticker"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'truck-sticker' ||
                                                    this.$route.name ==
                                                        'truck-sticker-detail' ||
                                                    this.$route.name ==
                                                        'truck-sticker-reject',
                                            }"
                                        >
                                            <i class="fa fa-adjust"></i>
                                            <span>Sticker Truck</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <!-- Carier Menu -->
                        <li
                            v-show="isRole(dashboardTrawlcarrier)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name ==
                                    'carrier-ho-dashboard-finance'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'carrier-ho-dashboard-operation'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-order-list'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-vehicle-list'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-vehicle-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-list'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-history'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-track'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-disbursement'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-disbursement-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-manifest'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-manifest-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-order-assign-mitra'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'carrier-ho-refund'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'trocarrier-pricing'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'admin-manifest'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-manifest-detail'
                                        ? 'active mb-1'
                                        : ''
                                "
                            >
                                <i class="fa fa-inbox"></i>
                                <span>Troben Carier</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name ==
                                    'carrier-ho-dashboard-finance'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'carrier-ho-dashboard-operation'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-order-list'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-vehicle-list'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-vehicle-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-list'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-history'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-track'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-driver-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-disbursement'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-disbursement-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-manifest'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-manifest-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-trawlcareer-order-assign-mitra'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'carrier-ho-refund'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'carrier-ho-refund-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trocarrier-pricing'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-order-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-manifest'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-manifest-order'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'carrier-ho-dashboard-finance'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'carrier-ho-dashboard-operation'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-order-list'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-vehicle-list'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-vehicle-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-driver-list'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-driver-history'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-driver-track'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-driver-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-disbursement'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-disbursement-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-manifest'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-manifest-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-trawlcareer-order-assign-mitra'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'carrier-ho-refund'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'carrier-ho-refund-default'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trocarrier-pricing'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-order-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-manifest'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-manifest-order'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-wallet-income'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li
                                        v-show="isRole(dashboardTrawlcarrier)"
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'carrier-ho-dashboard-finance',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'carrier-ho-dashboard-finance'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'carrier-ho-dashboard-operation'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-home"></i>
                                            <span>Dashboard</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'carrier-ho-dashboard-finance'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'carrier-ho-dashboard-operation'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'carrier-ho-dashboard-finance'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'carrier-ho-dashboard-operation'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardTrawlcarrierFinance
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/carrier/ho/dasboard/finance"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'carrier-ho-dashboard-finance',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Finance
                                                    </a>
                                                </li>
                                                <li
                                                    v-show="
                                                        isRole(
                                                            dashboardTrawlcarrierOperation
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="/carrier/ho/dasboard/operation"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'carrier-ho-dashboard-operation',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Operation
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li
                                        v-show="
                                            isRole(trawlcarrierFinanceRoles)
                                        "
                                    >
                                        <a
                                            href="/trawlcarrier/ho/order"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-trawlcareer-order-list' ||
                                                    this.$route.name ==
                                                        'admin-trawlcareer-order-assign-mitra',
                                            }"
                                        >
                                            <i class="fa fa-file sub"></i>
                                            Pesanan
                                        </a>
                                    </li>
                                    <li v-show="isRole(trawlcarrierRoles)">
                                        <a
                                            href="/trawlcarrier/ho/fleet"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-trawlcareer-vehicle-list' ||
                                                    this.$route.name ==
                                                        'admin-trawlcareer-vehicle-create',
                                            }"
                                        >
                                            <i class="fa fa-laptop sub"></i>
                                            Management Armada
                                        </a>
                                    </li>
                                    <li v-show="isRole(trawlcarrierRoles)">
                                        <a
                                            href="/trawlcarrier/ho/driver"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-trawlcareer-driver-list' ||
                                                    this.$route.name ==
                                                        'admin-trawlcareer-driver-create' ||
                                                    this.$route.name ==
                                                        'admin-trawlcareer-driver-history' ||
                                                    this.$route.name ==
                                                        'admin-trawlcareer-driver-track',
                                            }"
                                        >
                                            <i class="fa fa-user sub"></i>
                                            Management Driver
                                        </a>
                                    </li>
                                    <li v-show="isRole(financeRoles)">
                                        <a
                                            href="/trawlcarrier/ho/disbursement"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-trawlcareer-disbursement' ||
                                                    this.$route.name ==
                                                        'admin-trawlcareer-disbursement-detail',
                                            }"
                                        >
                                            <i class="fa fa-dollar sub"></i>
                                            Pencairan
                                        </a>
                                    </li>
                                    <li
                                        v-show="
                                            isRole(trawlcarrierFinanceRoles)
                                        "
                                    >
                                        <a
                                            href="/trawlcarrier/ho/manifest"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-trawlcareer-manifest' ||
                                                    this.$route.name ==
                                                        'admin-trawlcareer-manifest-detail',
                                            }"
                                        >
                                            <i class="fa fa-map-marker sub"></i>
                                            Tracking order
                                        </a>
                                    </li>
                                    <li v-show="isRole(financeRoles)">
                                        <a
                                            href="/carrier/ho/refund"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'carrier-ho-refund' ||
                                                    this.$route.name ==
                                                        'carrier-ho-refund-detail',
                                            }"
                                        >
                                            <i class="fa fa-money sub"></i>
                                            Pengembalian Dana
                                        </a>
                                    </li>
                                    <li
                                        class="sidebar-dropdown"
                                        v-show="isRole(roleCarrierPricing)"
                                    >
                                        <a
                                            href="/trocarrier/pricing"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                    'trocarrier-pricing',
                                            }"
                                        >
                                            <i class="fa fa-dollar sub"></i>
                                            <span>Cek Harga Carier</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(manifestRoles)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/trawlcarrier/partner/manifest"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-manifest' ||
                                                    this.$route.name ==
                                                        'admin-manifest-detail',
                                            }"
                                        >
                                            <i class="fa fa-calculator sub"></i>
                                            <span>Manifest</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(orderTrawlcarrierRoles)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active: this.$route.name == 'admin-order-list',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'admin-order-list'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-order-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-order-confirmation'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-order-incoming'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-shopping-cart"></i>
                                <span>Order Carier</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'admin-order-list'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-order-confirmation'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-order-incoming'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-order-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name != 'admin-order-list'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-order-confirmation'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-order-incoming'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-order-detail'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/order"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-order-list',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Daftar Pesanan
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/incoming-order"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-order-incoming',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Pesanan Masuk
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/manifest"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-manifest',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Manifest
                                        </a>
                                    </li>
                                    <!-- <li>
                                                    <a
                                                        href="/trawlcarrier/partner/order-confirmation"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'admin-order-confirmation',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Order Confirmation
                                                    </a>
                                                </li> -->
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(armadaRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'admin-vehicle'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-vehicle-create'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-vehicle-edit'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'admin-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'admin-assignment'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-archive sub"></i>
                                <span>Armada</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'admin-vehicle'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-vehicle-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-vehicle-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'admin-assignment'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-wallet-income'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'admin-assignment'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/fleet"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-vehicle' ||
                                                    this.$route.name ==
                                                        'admin-vehicle-create' ||
                                                    this.$route.name ==
                                                        'admin-vehicle-edit',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Kendaraan
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/driver"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-driver',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Driver
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/assign"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-assignment',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Penugasan
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <!-- CRM Menu -->
                        <li class="sidebar-dropdown" v-show="isRole(CRMRoles)">
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'crm-specialist'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-specialist-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-specialist-form'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-registered'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-registered-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-prospect'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-today'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-tomorrow'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-next-two-days'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-next-week'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-next-month'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-need-follow-up'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlcarrier'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlcarrier-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlpack'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlpack-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-today'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-tomorrow'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-prospect-all'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-two-days'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-week'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-month'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-need-follow-up'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-prediction'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-lost'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-deal'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-prospect'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-prospect-deal'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-prospect-lost'
                                        ? 'active mb-1'
                                        : '' || this.$route.name == 'crm-target'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-order-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-order-edit'
                                        ? 'active mb-1'
                                        : '' || this.$route.name == 'crm-order'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-order-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-order-lost'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-order-lost-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal-user'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal-user-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal-user-detail'
                                        ? 'active mb-1'
                                        : '' || this.$route.name == 'crm-tags'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-tags-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-tags-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-branchs'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-branchs-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'crm-branchs-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-branchs-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-leads'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-leads-prospect'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-form'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-form-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-leads-tags'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'trawltruck-mitra'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-create'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-edit'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-detail'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name == 'dashboard-crm'
                                        ? 'active mb-1'
                                        : ''
                                "
                            >
                                <i class="fa fa-clipboard"></i>
                                <span>CRM</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'crm-specialist'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-specialist-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-specialist-form'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-registered'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-registered-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-prospect'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-candidate-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-today'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-tomorrow'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-next-two-days'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-next-week'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-next-month'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-need-follow-up'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlcarrier'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlcarrier-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlpack'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-requested-trawlpack-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-partnership-prospect-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-today'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-tomorrow'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-prospect-all'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-two-days'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-week'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-month'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-need-follow-up'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-prediction'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-lost'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-deal'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-prospect'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-prospect-deal'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-follow-up-cro-prospect-lost'
                                        ? 'block'
                                        : '' || this.$route.name == 'crm-target'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-order-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-order-edit'
                                        ? 'block'
                                        : '' || this.$route.name == 'crm-order'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-order-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-order-lost'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-order-lost-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal-user'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'gratitude-journal-user-detail'
                                        ? 'block'
                                        : '' || this.$route.name == 'crm-tags'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-tags-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-tags-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-branchs'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-branchs-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-branchs-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-branchs-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-leads'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-leads-prospect'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-form'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-form-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-customers-leads-tags'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'trawltruck-mitra'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-create'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-edit'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'trawltruck-mitra-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'dashboard-crm'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name != 'crm-specialist'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-specialist-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-specialist-form'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-registered'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-registered-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-candidate'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-candidate-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-candidate-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-candidate-prospect'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-candidate-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-prospect-today'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-prospect-tomorrow'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-prospect-next-two-days'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-prospect-next-week'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-prospect-next-month'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-prospect-need-follow-up'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-prospect-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-requested-trawlcarrier'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-requested-trawlcarrier-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-requested-trawlpack'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-partnership-requested-trawlpack-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-today'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'crm-prospect-all'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-tomorrow'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-next-two-days'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-next-week'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-next-month'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-need-follow-up'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-prediction'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-lost'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-deal'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-follow-up-cro'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-follow-up-cro-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-follow-up-cro-prospect'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-follow-up-cro-prospect-deal'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-follow-up-cro-prospect-lost'
                                        ? 'none'
                                        : '' || this.$route.name != 'crm-target'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'crm-order-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'crm-order-edit'
                                        ? 'none'
                                        : '' || this.$route.name != 'crm-order'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'crm-order-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name == 'crm-order-lost'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-order-lost-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'gratitude-journal'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'gratitude-journal-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'gratitude-journal-user'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'gratitude-journal-user-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'crm-branchs'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-branchs-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'crm-branchs-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-branchs-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'trawltruck-mitra'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-create'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-edit'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'trawltruck-mitra-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'dashboard-crm'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li
                                        v-show="isRole(crmNonSpecialist)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/dashboard"
                                            :class="{
                                                '': true,
                                                'active second':
                                                    this.$route.name ==
                                                    'dashboard-crm',
                                            }"
                                        >
                                            <i class="fa fa-dashboard sub"></i>
                                            <span>Dashboard</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmNonSpecialist)"
                                        class="sidebar-dropdown1"
                                    >
                                        <a
                                            href="/crm/specialist"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-specialist' ||
                                                    this.$route.name ==
                                                        'crm-specialist-detail' ||
                                                    this.$route.name ==
                                                        'crm-specialist-form',
                                            }"
                                        >
                                            <i class="fa fa-user"></i>
                                            <span>CRM Specialist</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmBd)"
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'crm-partnership-registered',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'crm-partnership-registered'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-registered-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-create'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-edit'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-prospect'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-today'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-tomorrow'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-next-two-days'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-next-week'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-next-month'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-need-follow-up'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlcarrier'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlcarrier-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlpack'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlpack-detail'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-hospital-o sub"></i>
                                            <span>Partnership</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'crm-partnership-registered'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-registered-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-create'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-edit'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-prospect'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-candidate-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-today'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-tomorrow'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-next-two-days'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-next-week'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-next-month'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-need-follow-up'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlcarrier'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlcarrier-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlpack'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-requested-trawlpack-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-partnership-prospect-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-registered'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-registered-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-candidate'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-candidate-create'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-candidate-edit'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-candidate-prospect'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-candidate-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-prospect-today'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-prospect-tomorrow'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-prospect-next-two-days'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-prospect-next-week'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-prospect-next-month'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-prospect-need-follow-up'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-prospect-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-requested-trawlcarrier'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-requested-trawlcarrier-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-requested-trawlpack'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-partnership-requested-trawlpack-detail'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li>
                                                    <a
                                                        href="/crm/partnership/registered"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-registered' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-registered-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Registered
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/crm/partnership/candidate"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-candidate' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-candidate-create' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-candidate-edit' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-candidate-prospect' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-candidate-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Candidate
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="javascript:void(0)"
                                                        @click="
                                                            isShowProspect =
                                                                !isShowProspect
                                                        "
                                                        :class="{
                                                            second: true,
                                                            'active mb-2':
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-prospect-today' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-prospect-tomorrow' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-prospect-next-two-days' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-prospect-next-week' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-prospect-next-month' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-prospect-need-follow-up' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-prospect-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Leads
                                                        <i
                                                            class="fa fa-angle-down pull-right"
                                                        ></i>
                                                    </a>
                                                </li>
                                                <div
                                                    class="box p-2 ps-0 pe-0 mb-3"
                                                    v-if="
                                                        isShowProspect ||
                                                        this.$route.name ==
                                                            'crm-partnership-prospect-today' ||
                                                        this.$route.name ==
                                                            'crm-partnership-prospect-tomorrow' ||
                                                        this.$route.name ==
                                                            'crm-partnership-prospect-next-two-days' ||
                                                        this.$route.name ==
                                                            'crm-partnership-prospect-next-week' ||
                                                        this.$route.name ==
                                                            'crm-partnership-prospect-next-month' ||
                                                        this.$route.name ==
                                                            'crm-partnership-prospect-need-follow-up' ||
                                                        this.$route.name ==
                                                            'crm-partnership-prospect-detail'
                                                    "
                                                >
                                                    <li>
                                                        <a
                                                            href="/crm/partnership/prospect/today"
                                                            :class="{
                                                                sub: true,
                                                                active:
                                                                    this.$route
                                                                        .name ==
                                                                    'crm-partnership-prospect-today',
                                                            }"
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div>
                                                                    <i
                                                                        class="fa fa-circle sub"
                                                                    ></i>
                                                                    Today
                                                                </div>
                                                                <div
                                                                    class="ms-auto"
                                                                >
                                                                    <small
                                                                        v-bind:class="{
                                                                            'red-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name ==
                                                                                'crm-partnership-prospect-today',
                                                                            'gray-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name !=
                                                                                'crm-partnership-prospect-today',
                                                                        }"
                                                                        class="tag radius"
                                                                    >
                                                                        {{
                                                                            partnership_prospect_count?.today
                                                                                ? partnership_prospect_count?.today
                                                                                : 0
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="/crm/partnership/prospect/tomorrow"
                                                            :class="{
                                                                sub: true,
                                                                active:
                                                                    this.$route
                                                                        .name ==
                                                                    'crm-partnership-prospect-tomorrow',
                                                            }"
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div>
                                                                    <i
                                                                        class="fa fa-circle sub"
                                                                    ></i>
                                                                    Tomorrow
                                                                </div>
                                                                <div
                                                                    class="ms-auto"
                                                                >
                                                                    <small
                                                                        v-bind:class="{
                                                                            'red-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name ==
                                                                                'crm-partnership-prospect-tomorrow',
                                                                            'gray-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name !=
                                                                                'crm-partnership-prospect-tomorrow',
                                                                        }"
                                                                        class="tag radius"
                                                                    >
                                                                        {{
                                                                            partnership_prospect_count?.tomorrow
                                                                                ? partnership_prospect_count?.tomorrow
                                                                                : 0
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="/crm/partnership/prospect/next-two-days"
                                                            :class="{
                                                                sub: true,
                                                                active:
                                                                    this.$route
                                                                        .name ==
                                                                    'crm-partnership-prospect-next-two-days',
                                                            }"
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div>
                                                                    <i
                                                                        class="fa fa-circle sub"
                                                                    ></i>
                                                                    Next 2 day
                                                                </div>
                                                                <div
                                                                    class="ms-auto"
                                                                >
                                                                    <small
                                                                        v-bind:class="{
                                                                            'red-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name ==
                                                                                'crm-partnership-prospect-next-two-days',
                                                                            'gray-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name !=
                                                                                'crm-partnership-prospect-next-two-days',
                                                                        }"
                                                                        class="tag radius"
                                                                    >
                                                                        {{
                                                                            partnership_prospect_count?.next_2_days
                                                                                ? partnership_prospect_count?.next_2_days
                                                                                : 0
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="/crm/partnership/prospect/next-week"
                                                            :class="{
                                                                sub: true,
                                                                active:
                                                                    this.$route
                                                                        .name ==
                                                                    'crm-partnership-prospect-next-week',
                                                            }"
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div>
                                                                    <i
                                                                        class="fa fa-circle sub"
                                                                    ></i>
                                                                    Next Week
                                                                </div>
                                                                <div
                                                                    class="ms-auto"
                                                                >
                                                                    <small
                                                                        v-bind:class="{
                                                                            'red-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name ==
                                                                                'crm-partnership-prospect-next-week',
                                                                            'gray-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name !=
                                                                                'crm-partnership-prospect-next-week',
                                                                        }"
                                                                        class="tag radius"
                                                                    >
                                                                        {{
                                                                            partnership_prospect_count?.next_week
                                                                                ? partnership_prospect_count?.next_week
                                                                                : 0
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="/crm/partnership/prospect/next-month"
                                                            :class="{
                                                                sub: true,
                                                                active:
                                                                    this.$route
                                                                        .name ==
                                                                    'crm-partnership-prospect-next-month',
                                                            }"
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div>
                                                                    <i
                                                                        class="fa fa-circle sub"
                                                                    ></i>
                                                                    Next Month
                                                                </div>
                                                                <div
                                                                    class="ms-auto"
                                                                >
                                                                    <small
                                                                        v-bind:class="{
                                                                            'red-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name ==
                                                                                'crm-partnership-prospect-next-month',
                                                                            'gray-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name !=
                                                                                'crm-partnership-prospect-next-month',
                                                                        }"
                                                                        class="tag radius"
                                                                    >
                                                                        {{
                                                                            partnership_prospect_count?.next_month
                                                                                ? partnership_prospect_count?.next_month
                                                                                : 0
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="/crm/partnership/prospect/need-follow-up"
                                                            :class="{
                                                                sub: true,
                                                                active:
                                                                    this.$route
                                                                        .name ==
                                                                    'crm-partnership-prospect-need-follow-up',
                                                            }"
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div>
                                                                    <i
                                                                        class="fa fa-circle sub"
                                                                    ></i>
                                                                    Need Follow
                                                                    Up
                                                                </div>
                                                                <div
                                                                    class="ms-auto"
                                                                >
                                                                    <small
                                                                        v-bind:class="{
                                                                            'red-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name ==
                                                                                'crm-partnership-prospect-need-follow-up',
                                                                            'gray-solid':
                                                                                this
                                                                                    .$route
                                                                                    .name !=
                                                                                'crm-partnership-prospect-need-follow-up',
                                                                        }"
                                                                        class="tag radius"
                                                                    >
                                                                        {{
                                                                            partnership_prospect_count?.need_follow_up
                                                                                ? partnership_prospect_count?.need_follow_up
                                                                                : 0
                                                                        }}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </div>
                                                <li>
                                                    <a
                                                        href="/crm/partnership/requested-trawlpack"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-requested-trawlpack' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-requested-trawlpack-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Requested Cargo
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="/crm/partnership/requested-trawlcarrier"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-requested-trawlcarrier' ||
                                                                this.$route
                                                                    .name ==
                                                                    'crm-partnership-requested-trawlcarrier-detail',
                                                        }"
                                                    >
                                                        <i
                                                            class="fa fa-circle sub"
                                                        ></i>
                                                        Requested Carier
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li
                                        v-show="isRole(mitraTruck)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/trawltruck/mitra"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'trawltruck-mitra' ||
                                                    this.$route.name ==
                                                        'trawltruck-mitra-detail' ||
                                                    this.$route.name ==
                                                        'trawltruck-mitra-edit' ||
                                                    this.$route.name ==
                                                        'trawltruck-mitra-create',
                                            }"
                                        >
                                            <i class="fa fa-heart-o sub"></i>
                                            <span>Mitra Truk</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmRoles)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/customers/leads"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-customers-leads' ||
                                                    this.$route.name ==
                                                        'crm-customers-leads-prospect' ||
                                                    this.$route.name ==
                                                        'crm-customers-form' ||
                                                    this.$route.name ==
                                                        'crm-customers-form-edit' ||
                                                    this.$route.name ==
                                                        'crm-customers-leads-tags',
                                            }"
                                        >
                                            <i class="fa fa-credit-card"></i>
                                            <span>Prospect and Leads Data</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmRoles)"
                                        class="sidebar-dropdown1"
                                        :class="{
                                            '': true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-today',
                                        }"
                                    >
                                        <a
                                            href="javascript:void(0)"
                                            :class="
                                                this.$route.name ==
                                                'crm-prospect-today'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-tomorrow'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-all'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-next-two-days'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-next-week'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-next-month'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-need-follow-up'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-detail'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-prediction'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-lost'
                                                    ? 'active second'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-deal'
                                                    ? 'active second'
                                                    : ''
                                            "
                                        >
                                            <i class="fa fa-book sub"></i>
                                            <span>Follow Up</span>
                                            <i
                                                class="fa fa-angle-down pull-right"
                                            ></i>
                                        </a>
                                        <div
                                            class="sidebar-submenu1"
                                            :class="
                                                this.$route.name ==
                                                'crm-prospect-today'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-tomorrow'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-all'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-next-two-days'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-next-week'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-next-month'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-need-follow-up'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-detail'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-prediction'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-lost'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name ==
                                                          'crm-prospect-deal'
                                                    ? 'block'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-today'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-all'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-tomorrow'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-next-two-days'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-next-week'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-next-month'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-need-follow-up'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-detail'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-prediction'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-lost'
                                                    ? 'none'
                                                    : '' ||
                                                      this.$route.name !=
                                                          'crm-prospect-deal'
                                                    ? 'none'
                                                    : ''
                                            "
                                        >
                                            <ul>
                                                <li v-show="isRole(crmRoles)">
                                                    <a
                                                        href="/crm/prospect/all"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-all',
                                                        }"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div>
                                                                <i
                                                                    class="fa fa-circle sub"
                                                                ></i>
                                                                All Follow Up
                                                            </div>
                                                            <div
                                                                class="ms-auto"
                                                            >
                                                                <small
                                                                    v-bind:class="{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-prospect-all',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-prospect-all',
                                                                    }"
                                                                    class="tag radius"
                                                                >
                                                                    {{
                                                                        prospect_count?.today +
                                                                        prospect_count?.tomorrow +
                                                                        prospect_count?.next_2_days +
                                                                        prospect_count?.next_month +
                                                                        prospect_count?.need_follow_up
                                                                            ? prospect_count?.today +
                                                                              prospect_count?.tomorrow +
                                                                              prospect_count?.next_2_days +
                                                                              prospect_count?.next_month +
                                                                              prospect_count?.need_follow_up
                                                                            : 0
                                                                    }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-show="isRole(crmRoles)">
                                                    <a
                                                        href="/crm/prospect/today"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-today',
                                                        }"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div>
                                                                <i
                                                                    class="fa fa-circle sub"
                                                                ></i>
                                                                Today
                                                            </div>
                                                            <div
                                                                class="ms-auto"
                                                            >
                                                                <small
                                                                    v-bind:class="{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-prospect-today',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-prospect-today',
                                                                    }"
                                                                    class="tag radius"
                                                                >
                                                                    {{
                                                                        prospect_count?.today
                                                                            ? prospect_count?.today
                                                                            : 0
                                                                    }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-show="isRole(crmRoles)">
                                                    <a
                                                        href="/crm/prospect/tomorrow"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-tomorrow',
                                                        }"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div>
                                                                <i
                                                                    class="fa fa-circle sub"
                                                                ></i>
                                                                Tomorrow
                                                            </div>
                                                            <div
                                                                class="ms-auto"
                                                            >
                                                                <small
                                                                    v-bind:class="{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-prospect-tomorrow',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-prospect-tomorrow',
                                                                    }"
                                                                    class="tag radius"
                                                                >
                                                                    {{
                                                                        prospect_count?.tomorrow
                                                                            ? prospect_count?.tomorrow
                                                                            : 0
                                                                    }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-show="isRole(crmRoles)">
                                                    <a
                                                        href="/crm/prospect/next-two-days"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-two-days',
                                                        }"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div>
                                                                <i
                                                                    class="fa fa-circle sub"
                                                                ></i>
                                                                Next 2 Days
                                                            </div>
                                                            <div
                                                                class="ms-auto"
                                                            >
                                                                <small
                                                                    v-bind:class="{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-prospect-next-two-days',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-prospect-next-two-days',
                                                                    }"
                                                                    class="tag radius"
                                                                >
                                                                    {{
                                                                        prospect_count?.next_2_days
                                                                            ? prospect_count?.next_2_days
                                                                            : 0
                                                                    }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-show="isRole(crmRoles)">
                                                    <a
                                                        href="/crm/prospect/next-week"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-week',
                                                        }"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div>
                                                                <i
                                                                    class="fa fa-circle sub"
                                                                ></i>
                                                                Next Week
                                                            </div>
                                                            <div
                                                                class="ms-auto"
                                                            >
                                                                <small
                                                                    v-bind:class="{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-prospect-next-week',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-prospect-next-week',
                                                                    }"
                                                                    class="tag radius"
                                                                >
                                                                    {{
                                                                        prospect_count?.next_week
                                                                            ? prospect_count?.next_week
                                                                            : 0
                                                                    }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-show="isRole(crmRoles)">
                                                    <a
                                                        href="/crm/prospect/next-month"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-month',
                                                        }"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div>
                                                                <i
                                                                    class="fa fa-circle sub"
                                                                ></i>
                                                                Next Month
                                                            </div>
                                                            <div
                                                                class="ms-auto"
                                                            >
                                                                <small
                                                                    v-bind:class="{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-prospect-next-month',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-prospect-next-month',
                                                                    }"
                                                                    class="tag radius"
                                                                >
                                                                    {{
                                                                        prospect_count?.next_month
                                                                            ? prospect_count?.next_month
                                                                            : 0
                                                                    }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-show="isRole(crmRoles)">
                                                    <a
                                                        href="/crm/prospect/need-follow-up"
                                                        :class="{
                                                            sub: true,
                                                            active:
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-need-follow-up',
                                                        }"
                                                    >
                                                        <div
                                                            class="d-flex align-items-center"
                                                        >
                                                            <div>
                                                                <i
                                                                    class="fa fa-circle sub"
                                                                ></i>
                                                                Need Follow Up
                                                            </div>
                                                            <div
                                                                class="ms-auto"
                                                            >
                                                                <small
                                                                    v-bind:class="{
                                                                        'red-solid':
                                                                            this
                                                                                .$route
                                                                                .name ==
                                                                            'crm-prospect-need-follow-up',
                                                                        'gray-solid':
                                                                            this
                                                                                .$route
                                                                                .name !=
                                                                            'crm-prospect-need-follow-up',
                                                                    }"
                                                                    class="tag radius"
                                                                >
                                                                    {{
                                                                        prospect_count?.need_follow_up
                                                                            ? prospect_count?.need_follow_up
                                                                            : 0
                                                                    }}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li
                                        v-show="isRole(crmSpecialistCro)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/follow-up-cro"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-follow-up-cro' ||
                                                    this.$route.name ==
                                                        'crm-follow-up-cro-detail' ||
                                                    this.$route.name ==
                                                        'crm-follow-up-cro-prospect' ||
                                                    this.$route.name ==
                                                        'crm-follow-up-cro-prospect-deal' ||
                                                    this.$route.name ==
                                                        'crm-follow-up-cro-prospect-lost',
                                            }"
                                        >
                                            <i class="fa fa-book"></i>
                                            <span>Follow Up CRO</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmNonSpecialist)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/target"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-target' ||
                                                    this.$route.name ==
                                                        'crm-order-create' ||
                                                    this.$route.name ==
                                                        'crm-order-edit',
                                            }"
                                        >
                                            <i class="fa fa-building sub"></i>
                                            <span>Company Target</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmRepeatOrder)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/order"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-order' ||
                                                    this.$route.name ==
                                                        'crm-order-detail',
                                            }"
                                        >
                                            <i
                                                class="fa fa-shopping-cart sub"
                                            ></i>
                                            <span>Deal Order</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmRepeatOrder)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/order-lost"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-order-lost' ||
                                                    this.$route.name ==
                                                        'crm-order-lost-detail',
                                            }"
                                        >
                                            <i
                                                class="fa fa-shopping-cart sub"
                                            ></i>
                                            <span>Lost Order</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmNonSpecialist)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/gratitude-journal"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'gratitude-journal' ||
                                                    this.$route.name ==
                                                        'gratitude-journal-create' ||
                                                    this.$route.name ==
                                                        'gratitude-journal-user' ||
                                                    this.$route.name ==
                                                        'gratitude-journal-user-detail',
                                            }"
                                        >
                                            <i class="fa fa-heart sub"></i>
                                            <span>Jurnal Syukur</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmRoles)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/tags"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-tags' ||
                                                    this.$route.name ==
                                                        'crm-tags-create' ||
                                                    this.$route.name ==
                                                        'crm-tags-detail',
                                            }"
                                        >
                                            <i class="fa fa-tags sub"></i>
                                            <span>Tags</span>
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(crmAdminOnly)"
                                        class="sidebar-dropdown"
                                    >
                                        <a
                                            href="/crm/branchs"
                                            :class="{
                                                second: true,
                                                active:
                                                    this.$route.name ==
                                                        'crm-branchs' ||
                                                    this.$route.name ==
                                                        'crm-branchs-create' ||
                                                    this.$route.name ==
                                                        'crm-branchs-edit' ||
                                                    this.$route.name ==
                                                        'crm-branchs-detail',
                                            }"
                                        >
                                            <i class="fa fa-bank"></i>
                                            <span>Trobens Branch</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <!-- NEW POSITION -->
                        <li
                            v-show="isRole(dashboardtrawltruckCorporateRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawltruck/partner/dashboard"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'dashboard-trawltruck-mitra',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Dashboard Truk</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawltruckCorporateRoles)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active: this.$route.name == 'management-driver',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'management-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-management-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'management-fleet'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-management-fleet'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'incoming-order'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'management-order'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'management-order-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'management-ho-disbursement'
                                        ? 'active'
                                        : '' || this.$route.name == 'topup'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-building sub"></i>
                                <span>Truk Corporate</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'management-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-management-driver'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'management-fleet'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'detail-management-fleet'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'incoming-order'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'management-order'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'management-order-detail'
                                        ? 'block'
                                        : '' || this.$route.name == 'topup'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'management-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'detail-management-driver'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'management-fleet'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'detail-management-fleet'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'incoming-order'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'incoming-order-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'management-order'
                                        ? 'none'
                                        : '' || this.$route.name != 'topup'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/trawltruck/partner/driver"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'management-driver' ||
                                                    this.$route.name ==
                                                        'detail-management-driver',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Manajemen Driver
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawltruck/partner/fleet"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'management-fleet' ||
                                                    this.$route.name ==
                                                        'detail-management-fleet',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Manajemen Kendaraan
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawltruck/incoming/order"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'incoming-order',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Incoming Order
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawltruck/partner/order"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'management-order' ||
                                                    this.$route.name ==
                                                        'management-order-detail',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Manajemen Order
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawltruck/partner/disbursement"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'management-disbursement',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Manajemen Pencairan
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawltruck/partner/topup"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name == 'topup',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Top up
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerRoles)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active: this.$route.name == 'dashboard-mb',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'dashboard-mb'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'dashboard-item-mb'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'dashboard-mpw'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'dashboard-item-mpw'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'dashboard-mtak'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-globe"></i>
                                <span>Dashboard Mitra</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'dashboard-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'dashboard-item-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'dashboard-mpw'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name != 'dashboard-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name != 'dashboard-mtak'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'dashboard-item-mpw'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'dashboard-item-mb'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'dashboard-mpw'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'dashboard-item-mpw'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'dashboard-mtak'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li v-show="isRole(trawlpackOwnerMBRoles)">
                                        <a
                                            href="/trawlpack/partner/dashboard/income"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'dashboard-mb',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Pendapatan Mitra
                                        </a>
                                    </li>
                                    <li v-show="isRole(trawlpackOwnerMBRoles)">
                                        <a
                                            href="/trawlpack/partner/dashboard/warehouse"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'dashboard-item-mb',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Barang Masuk Gudang
                                        </a>
                                    </li>
                                    <li v-show="isRole(trawlpackOwnerMPWRoles)">
                                        <a
                                            href="/trawlpack/partner/dashboard/income"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'dashboard-mpw',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Pendapatan
                                        </a>
                                    </li>
                                    <li v-show="isRole(trawlpackOwnerMPWRoles)">
                                        <a
                                            href="/trawlpack/partner/dashboard/warehouse"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'dashboard-item-mpw',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Barang Masuk
                                        </a>
                                    </li>
                                    <li
                                        v-show="isRole(trawlpackOwnerMTAKRoles)"
                                    >
                                        <a
                                            href="/trawlpack/partner/dashboard/income"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'dashboard-mtak',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Pendapatan
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <!-- OTHER -->
                        <li
                            v-show="isRole(walletRoles)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active:
                                    this.$route.name == 'admin-wallet-income',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'admin-wallet-income'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-wallet-disbursement'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-wallet-disbursement-request'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-credit-card"></i>
                                <span>Dompet</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'admin-wallet-income'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-wallet-disbursement'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'admin-wallet-disbursement-request'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-wallet-income'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-wallet-disbursement'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'admin-wallet-disbursement-request'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/wallet/income"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'admin-wallet-income',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Pemasukan
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlcarrier/partner/wallet/disbursement"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'admin-wallet-disbursement' ||
                                                    this.$route.name ==
                                                        'admin-wallet-disbursement-request',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Pencairan
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMBRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/income"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'owner-income-mb',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Incoming Orders</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMPWRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/income"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'owner-income-mpw',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Incoming Orders</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMTAKRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/income"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'owner-income-mtak',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Incoming Orders</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMBRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/manifest"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'status-manifest-mb',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Status Manifest</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMPWRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/manifest"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'status-manifest-mpw',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Status Manifest</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMTAKRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/manifest"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'status-manifest-mtak',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Status Manifest</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMBRoles)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active: this.$route.name == 'warehouse-mw-mb',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'warehouse-mw-mb'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'warehouse-p-mb'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'warehouse-rtd-mb'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-globe"></i>
                                <span>Warehouse</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'warehouse-mw-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'warehouse-p-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'warehouse-rtd-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name != 'warehouse-mw-mb'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'warehouse-p-mb'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'warehouse-rtd-mb'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/trawlpack/partner/warehouse/weight"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'warehouse-mw-mb',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Ukur Timbang
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlpack/partner/warehouse/packing"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'warehouse-p-mb' ||
                                                    this.$route.name ==
                                                        'warehouse-p-mb',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Packing
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/trawlpack/partner/warehouse/delivery"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'warehouse-rtd-mb' ||
                                                    this.$route.name ==
                                                        'warehouse-rtd-mb',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Siap Antar
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerMPWRoles)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active: this.$route.name == 'warehouse-rtd-mb',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'warehouse-rtd-mb'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-globe"></i>
                                <span>Warehouse</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'warehouse-rtd-mb'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name != 'warehouse-rtd-mb'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/trawlpack/partner/warehouse/delivery"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'warehouse-rtd-mb' ||
                                                    this.$route.name ==
                                                        'warehouse-rtd-mb',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Siap Antar
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/asset/staff"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'owner-employee',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Kelola Staff</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(trawlpackOwnerRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/trawlpack/partner/asset/fleet"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'owner-transporter',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Manajemen Kendaraan</span>
                            </a>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(roleOnlyAdmin)"
                        >
                            <a
                                href="/master/customer"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'master-customer' ||
                                        this.$route.name ==
                                            'master-customer-detail',
                                }"
                            >
                                <i class="fa fa-user"></i>
                                <span>Master Customer</span>
                            </a>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(roleOnlyAdmin)"
                        >
                            <a
                                href="/master/user"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'master-user' ||
                                        this.$route.name ==
                                            'master-user-detail',
                                }"
                            >
                                <i class="fa fa-users"></i>
                                <span>Master User</span>
                            </a>
                        </li>
                        <!-- CHAT -->
                        <li v-show="isRole(chatRoles)" class="sidebar-dropdown">
                            <a
                                href="/chat"
                                :class="{
                                    '': true,
                                    active: this.$route.name == 'Chat',
                                }"
                            >
                                <i class="fa fa-wechat"></i>
                                <span>Chat</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(crmStaffRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                :href="'/crm/gratitude-journal/user/' + user_id"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'gratitude-journal' ||
                                        this.$route.name ==
                                            'gratitude-journal-create' ||
                                        this.$route.name ==
                                            'gratitude-journal-user' ||
                                        this.$route.name ==
                                            'gratitude-journal-user-detail',
                                }"
                            >
                                <i class="fa fa-heart"></i>
                                <span>Jurnal Syukur</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(truckStickerRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/truck/commission/sticker-ga"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'truck-commission-sticker-ga' ||
                                        this.$route.name ==
                                            'truck-commission-sticker-ga-detail' ||
                                        this.$route.name ==
                                            'truck-commission-sticker-ga-reject',
                                }"
                            >
                                <i class="fa fa-money"></i>
                                <span>Komisi Sticker Truck</span>
                            </a>
                        </li>
                        <li class="sidebar-dropdown">
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name ==
                                    'register-sales-affiliate'
                                        ? 'active mb-1'
                                        : '' ||
                                          this.$route.name ==
                                              'register-sales-affiliate-detail'
                                        ? 'active mb-1'
                                        : ''
                                "
                            >
                                <i class="fa fa-users"></i>
                                <span>Sales Affiliate</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name ==
                                    'register-sales-affiliate'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'register-sales-affiliate-detail'
                                        ? 'block'
                                        : ''
                                "
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="/register-sales-affiliate"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'register-sales-affiliate' ||
                                                    this.$route.name ==
                                                        'register-sales-affiliate-detail',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Register Agent
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/account-sales-affiliate"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'account-sales-affiliate' ||
                                                    this.$route.name ==
                                                        'account-sales-affiliate-detail',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Agent Account
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <main class="page-content">
            <div class="nav-top-main">
                <div class="d-flex align-items-center">
                    <div></div>
                    <div class="ms-auto">
                        <div class="d-flex align-items-center">
                            <div class="dropdown">
                                <div
                                    class="notifications toggle dd-trigger me-4 cursor-pointer"
                                >
                                    <span
                                        class="count"
                                        id="notifications-count"
                                        >{{ totalNotif }}</span
                                    >
                                    <span class="fa fa-bell-o size-22"></span>
                                </div>
                                <div class="dropdown-menu">
                                    <div class="d-flex items-align-center">
                                        <div class="size-16 fw-semibold">
                                            Notifikasi
                                        </div>
                                    </div>
                                    <!-- <div class="radio_container mt-3">
                                        <input
                                            type="radio"
                                            name="radio-service"
                                            class="tab-radio-input"
                                            v-model="service"
                                            id="all"
                                            value="all"
                                        />
                                        <label class="tab-radio-label" for="all"
                                            >All</label
                                        >
                                        <input
                                            type="radio"
                                            name="radio-service"
                                            class="tab-radio-input"
                                            v-model="service"
                                            id="trawlpack"
                                            value="trawlpack"
                                        />
                                        <label
                                            class="tab-radio-label"
                                            for="trawlpack"
                                            >Troben Cargo</label
                                        >
                                        <input
                                            type="radio"
                                            name="radio-service"
                                            class="tab-radio-input"
                                            v-model="service"
                                            id="trawltruck"
                                            value="trawltruck"
                                        />
                                        <label
                                            class="tab-radio-label"
                                            for="trawltruck"
                                            >Troben Truk</label
                                        >
                                        <input
                                            type="radio"
                                            name="radio-service"
                                            class="tab-radio-input"
                                            v-model="service"
                                            id="trawlcarrier"
                                            value="trawlcarrier"
                                        />
                                        <label
                                            class="tab-radio-label"
                                            for="trawlcarrier"
                                            >Troben Carier</label
                                        >
                                    </div> -->
                                    <hr />
                                    <template v-if="notif_all.length > 0">
                                        <div
                                            v-bind:class="{
                                                'scroll-notif':
                                                    notif_all.length > 4,
                                            }"
                                        >
                                            <div class="d-flex mb-3">
                                                <div class="ms-auto">
                                                    <a
                                                        href="javascript:void(0)"
                                                        @click="readAllNotif()"
                                                        class="text-green"
                                                    >
                                                        <u
                                                            >Tandai semua telah
                                                            dibaca</u
                                                        >
                                                    </a>
                                                </div>
                                            </div>
                                            <template
                                                v-for="(
                                                    notif, index
                                                ) in notif_all"
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="readNotif(notif.id)"
                                                    :key="index"
                                                >
                                                    <div
                                                        class="d-flex align-items-center mb-2"
                                                    >
                                                        <div class="me-2">
                                                            <div
                                                                class="user-avatar-sidebar user-notif"
                                                                v-bind:class="{
                                                                    grayscale:
                                                                        notif.status ==
                                                                        'read',
                                                                }"
                                                                :style="{
                                                                    'background-image':
                                                                        'url(' +
                                                                        notif.icon +
                                                                        ')',
                                                                }"
                                                            >
                                                                <span
                                                                    class="position-absolute top-0 start-100 translate-middle p-alert-notif bg-warning border border-light rounded-circle"
                                                                    v-if="
                                                                        notif.status ==
                                                                        'pending'
                                                                    "
                                                                >
                                                                    <span
                                                                        class="visually-hidden"
                                                                        >New
                                                                        alerts</span
                                                                    >
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div
                                                                    class="size-16 fw-semibold"
                                                                    v-bind:class="{
                                                                        'text-gray-dashboard':
                                                                            notif.status ==
                                                                            'read',
                                                                        'text-dark':
                                                                            notif.status ==
                                                                            'pending',
                                                                    }"
                                                                >
                                                                    {{
                                                                        notif.title
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="ms-auto text-gray size-12"
                                                                >
                                                                    {{
                                                                        notif.created_at
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-bind:class="{
                                                                    'text-gray-dashboard':
                                                                        notif.status ==
                                                                        'read',
                                                                    'text-dark':
                                                                        notif.status ==
                                                                        'pending',
                                                                }"
                                                            >
                                                                {{
                                                                    notif.subtitle
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="mt-4">
                                            <center>
                                                <img
                                                    src="../assets/no-notif.png"
                                                    width="120"
                                                    alt=""
                                                />
                                                <div
                                                    class="mt-3 text-gray-dashboard"
                                                >
                                                    Belum ada notifikasi
                                                </div>
                                            </center>
                                        </div>
                                    </template>
                                    <hr />
                                    <div class="text-center">
                                        <a
                                            href="/notification"
                                            class="text-green"
                                        >
                                            Lihat Semua Notifikasi
                                            <i
                                                class="fa fa-angle-right ms-2"
                                            ></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-profile">
                                <div class="toggle-profile cursor-pointer">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2">
                                            <template
                                                v-if="user.avatar != null"
                                            >
                                                <div
                                                    class="user-avatar-sidebar user-small"
                                                    :style="{
                                                        'background-image':
                                                            'url(' +
                                                            user.avatar +
                                                            ')',
                                                    }"
                                                ></div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="user-avatar-sidebar user-small"
                                                    :style="{
                                                        'background-image':
                                                            'url(' +
                                                            require('../assets/user.jpg') +
                                                            ')',
                                                    }"
                                                ></div>
                                            </template>
                                        </div>
                                        <div class="fw-medium size-14">
                                            {{ user.name }}
                                            <i
                                                class="fa fa-angle-down ms-2"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-menu-profile">
                                    <router-link :to="{ name: profileRole() }">
                                        <a
                                            class="size-14 fw-semibold text-dark"
                                        >
                                            Edit Profile
                                        </a>
                                    </router-link>
                                    <hr />
                                    <a
                                        href="javascript:void(0)"
                                        @click="logout()"
                                        class="text-danger size-14 fw-semibold"
                                    >
                                        <i class="fa fa-sign-out me-1"></i>
                                        Log Out
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-nav-top">
                <router-view
                    :totalNotif="totalNotif"
                    @update-data-event="getCountNotif"
                    :message="this.$attrs.message"
                />
            </div>
        </main>
    </div>
</template>

<script>
// import Header from "../components/HeaderAdmin.vue";
import Api from "../api/Api";
import $ from "jquery";
import moment from "moment";
export default {
    components: {
        // Header,
    },
    data() {
        return {
            moment: moment,
            isShowProspect: false,
            user_id: "",
            user: {},
            roles: [],
            prospect_count: {},
            partnership_prospect_count: {},
            roleOnlyAdmin: ["admin-super"],
            dashboardTrawlpackHoRoles: [
                "admin-super",
                "ho-finance",
                "ho-operation",
                "admin-trawlpack",
            ],
            dashboardTrawlpackHoFinanceRoles: [
                "admin-super",
                "ho-finance",
                "admin-trawlpack",
            ],
            financeRoles: ["admin-super", "ho-finance"],
            dashboardTrawlpackHoOperationRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawlpack",
            ],
            InvoiceRoles: ["trawlpack-partner-cashier"],
            dashboardTrawltruckRoles: [
                "admin-super",
                "trawltruck-partner",
                "trawltruck-partner-owner",
            ],
            dashboardHoTrawltruckRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawltruck",
            ],
            assignDriverRoles: ["ho-cs", "ho-operation", "admin-super"],
            dashboardHoTrawltruckFinanceRoles: [
                "admin-super",
                "admin-trawltruck",
                "ho-finance",
            ],
            chatRoles: ["admin-super", "ho-cs", "trawlpack-partner-cs"],
            orderTrawlpackRoles: [
                "admin-super",
                "admin-trawlpack",
                "trawlpack-partner-cs",
                "trawlpack-partner-cargo",
            ],
            hideAdminRole: ["trawlpack-partner-cs", "trawlpack-partner-cargo"],
            orderTrawlcarrierRoles: ["trawlcarrier-partner"],
            trawlcarrierRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawlcarrier",
            ],
            trawlcarrierFinanceRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawlcarrier",
                "ho-finance",
            ],
            manifestRoles: ["admin-super", "trawlcarrier-partner"],
            roleCarrierPricing: [
                "admin-super",
                "admin-trawlpack",
                "ho-cs",
                "ho-operation",
            ],
            walletRoles: ["trawlcarrier-partner"],
            armadaRoles: ["trawlcarrier-partner"],
            customerPaymentRoles: [
                "admin-super",
                "ho-finance",
                "ho-operation",
                "admin-trawlpack",
                "ho-warehouse",
            ],
            partnerIncomeRoles: ["admin-super", "ho-finance"],
            disbursementRoles: ["admin-super", "ho-finance"],
            dataItemRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawlpack",
                "ho-warehouse",
            ],
            trackingOrderRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawlpack",
                "ho-warehouse",
            ],
            trackingAssignTransporterRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawlpack",
            ],
            trawltruckRoles: [
                "admin-super",
                "ho-operation",
                "ho-finance",
                "admin-trawltruck",
            ],
            trawltruckCorporateRoles: [
                "trawltruck-partner",
                "trawltruck-partner-owner",
            ],
            dashboardtrawltruckCorporateRoles: [
                "trawltruck-partner",
                "trawltruck-partner-owner",
            ],
            trawlpackOwnerRoles: [
                "trawlpack-partner-owner-business",
                "trawlpack-partner-owner-warehouse",
                "trawlpack-partner-owner-transporter",
                "trawlpack-partner-owner",
            ],
            trawlpackOwnerMBRoles: ["trawlpack-partner-owner-business"],
            trawlpackOwnerMPWRoles: ["trawlpack-partner-owner-warehouse"],
            trawlpackOwnerMTAKRoles: ["trawlpack-partner-owner-transporter"],
            crmRoles: [
                "admin-crm",
                "admin-super",
                "crm-manager",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            crmBd: ["crm-bd", "admin-crm", "crm-manager"],
            crmAdminOnly: ["admin-crm"],
            crmStaffRoles: [
                "crm-specialist",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            crmRepeatOrder: [
                "admin-crm",
                "crm-manager",
                "crm-specialist",
                "admin-super",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            crmNonSpecialist: ["admin-crm", "crm-manager", "admin-super"],
            dashboardTrawlcarrier: [
                "admin-super",
                "ho-finance",
                "ho-operation",
                "admin-trawlcarrier",
            ],
            crmSpecialistCro: [
                "crm-specialist-cro",
                "admin-crm",
                "crm-manager",
            ],
            dashboardTrawlcarrierFinance: [
                "admin-super",
                "ho-finance",
                "admin-trawlcarrier",
            ],
            dashboardTrawlcarrierOperation: [
                "admin-super",
                "ho-operation",
                "admin-trawlcarrier",
            ],
            tarifHoTrawltruck: [
                "admin-super",
                "admin-trawltruck",
                "ho-finance",
            ],
            mitraTruck: ["admin-super", "admin-trawltruck"],
            rolePriceCubic: ["admin-super", "admin-trawlpack", "ho-cs"],
            roleJadwalKapal: [
                "admin-super",
                "admin-trawlpack",
                "ho-cs",
                "ho-operation",
            ],
            dashboardTrawltruckHoRoles: [
                "admin-super",
                "ho-operation",
                "ho-finance",
                "admin-trawltruck",
            ],
            dashboardTrawltruckHoOperationRoles: [
                "admin-super",
                "ho-operation",
                "admin-trawltruck",
            ],
            dashboardTrawltruckHoFinanceRoles: [
                "admin-super",
                "ho-finance",
                "admin-trawltruck",
            ],
            trawlcarrierHoRefund: [
                "admin-super",
                "ho-finance",
                "admin-trawlcarrier",
            ],
            TropackRoles: [
                "admin-super",
                "ho-finance",
                "ho-operation",
                "admin-trawlpack",
                "trawlpack-partner-cs",
                "trawlpack-partner-cargo",
                "trawlpack-partner-cashier",
                "ho-cs",
                "ho-warehouse",
            ],
            TrotruckkRoles: [
                "admin-super",
                "ho-operation",
                "ho-finance",
                "admin-trawltruck",
                "ho-operation",
            ],
            CRMRoles: [
                "admin-crm",
                "crm-manager",
                "admin-super",
                "crm-specialist",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
                "crm-specialist-cro",
                "admin-trawltruck",
                "crm-bd",
            ],
            truckStickerRoles: [
                "ho-generalaffair",
                "admin-super",
                "admin-trawltruck",
            ],
            isMobile: false,
            notif_all: [],
            notif_trawlpack: [],
            notif_trawltruck: [],
            notif_trawlcarrier: [],
            totalNotif: 0,
        };
    },
    created() {
        this.getUserLogin();
        this.getNotif();
        this.getCountNotif();
    },
    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onResize, {
                passive: true,
            });
        }
    },
    mounted() {
        $(".sidebar-dropdown > a").click(function () {
            $(".sidebar-submenu").slideUp(200);
            if ($(this).parent().hasClass("active")) {
                $(".sidebar-dropdown").removeClass("active");
                $(this).parent().removeClass("active");
            } else {
                $(".sidebar-dropdown").removeClass("active");
                $(this).next(".sidebar-submenu").slideDown(200);
                $(this).parent().addClass("active");
            }
        });
        $(".sidebar-dropdown1 > a").click(function () {
            $(".sidebar-submenu1").slideUp(200);
            if ($(this).parent().hasClass("active")) {
                $(".sidebar-dropdown1").removeClass("active");
                $(this).parent().removeClass("active");
            } else {
                $(".sidebar-dropdown1").removeClass("active");
                $(this).next(".sidebar-submenu1").slideDown(200);
                $(this).parent().addClass("active");
            }
        });
        $("#close-sidebar").click(function () {
            $(".page-wrapper").removeClass("toggled");
        });
        $("#show-sidebar").click(function () {
            $(".page-wrapper").addClass("toggled");
        });

        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });

        const $menu = $(".dropdown");

        const onMouseUp = (e) => {
            if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
                $menu.removeClass("is-active");
            }
        };

        $(".toggle").on("click", () => {
            $menu
                .toggleClass("is-active")
                .promise()
                .done(() => {
                    if ($menu.hasClass("is-active")) {
                        $(document).on("mouseup", onMouseUp);
                    } else {
                        $(document).off("mouseup", onMouseUp);
                    }
                });
        });

        const $menu_profile = $(".dropdown-profile");

        const onMouseUpProfile = (e) => {
            if (
                !$menu_profile.is(e.target) &&
                $menu_profile.has(e.target).length === 0
            ) {
                $menu_profile.removeClass("is-active-profile");
            }
        };

        $(".toggle-profile").on("click", () => {
            $menu_profile
                .toggleClass("is-active-profile")
                .promise()
                .done(() => {
                    if ($menu_profile.hasClass("is-active-profile")) {
                        $(document).on("mouseup", onMouseUpProfile);
                    } else {
                        $(document).off("mouseup", onMouseUpProfile);
                    }
                });
        });
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
        profileRole() {
            if (
                this.roles[0] == "trawltruck-partner-owner" ||
                this.roles[0] == "trawltruck-partner"
            ) {
                return "trawltruck-profile";
            } else if (
                this.roles[0] == "trawlpack-partner-owner" ||
                this.roles[0] == "trawlpack-partner-owner-business" ||
                this.roles[0] == "trawlpack-partner-owner-warehouse" ||
                this.roles[0] == "trawlpack-partner-owner-transporter"
            ) {
                return "owner-profile";
            } else if (
                this.roles[0] == "crm-specialist" ||
                this.roles[0] == "crm-specialist-cs" ||
                this.roles[0] == "crm-specialist-sales" ||
                this.roles[0] == "crm-specialist-telemarketer"
            ) {
                return "crm-profile";
            } else {
                return "admin-profile";
            }
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user = res.data.data;
                    this.user_id = res.data.data.hash;
                    this.roles = res.data.data.roles;
                    if (
                        res.data.data.roles.includes("admin-crm") ||
                        res.data.data.roles.includes("crm-bd") ||
                        res.data.data.roles.includes("crm-manager") ||
                        res.data.data.roles.includes("admin-trawlpack") ||
                        res.data.data.roles.includes("crm-specialist") ||
                        res.data.data.roles.includes("crm-specialist-cs") ||
                        res.data.data.roles.includes("crm-specialist-sales") ||
                        res.data.data.roles.includes(
                            "crm-specialist-telemarketer"
                        )
                    ) {
                        this.getProspectCount();
                    }
                    if (
                        res.data.data.roles.includes("admin-crm") ||
                        res.data.data.roles.includes("crm-bd") ||
                        res.data.data.roles.includes("crm-manager")
                    ) {
                        this.getPartnershipProspectCount();
                    }
                })
                .catch((err) => {
                    console.log("failed-get-profile", err);

                    localStorage.removeItem("token");
                    localStorage.removeItem("roles");

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Gagal mendapatkan Profil",
                    });

                    setTimeout(() => {
                        this.$router.push("/");
                    }, 2000);
                });
        },
        getProspectCount() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospect/count`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.prospect_count = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPartnershipProspectCount() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/count`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.partnership_prospect_count = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        logout() {
            this.$notify({
                group: "foo",
                type: "success",
                title: "Success",
                text: "Logout success",
            });
            this.postNotif();
            localStorage.clear();
            setTimeout(() => {
                this.$router.push({ path: "/" });
            }, 1500);
        },
        getNotif() {
            var payload = {
                limit: 10,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/notification/user`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    let data = res.data.data.list_data;
                    this.notif_all = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCountNotif() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/count`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.totalNotif = res.data.data.pending;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        readNotif(id) {
            var data = {
                notification_id: id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getNotif();
                    this.getCountNotif();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        readAllNotif() {
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/update_all`,
                "",
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getNotif();
                    this.getCountNotif();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        postNotif() {
            var data = {
                prefix: "users",
                id: this.user_id,
                token: localStorage.token,
                platform: "web",
                deviceId: "unique2",
                type: "logout",
            };
            Api.post(`/cb/fcm/update`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    setTimeout(() => {
                        this.$router.push({ path: "/" });
                    }, 1500);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(5deg);
    }
    70% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.page-wrapper {
    height: 100vh;
}
.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}
.page-wrapper .theme.chiller-theme {
    background: #1e2229;
}
/*----------------toggeled sidebar----------------*/
.page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
}
@media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
        padding-left: 260px;
    }
}
#show-sidebar {
    position: fixed;
    left: 0;
    top: 10px;
    padding: 1px;
    font-size: 20px;
    border-radius: 0 20px 20px 0px;
    width: 35px;
    transition-delay: 0.3s;
    z-index: 9999999999;
}
.page-wrapper.toggled #show-sidebar {
    left: -40px;
}
.sidebar-wrapper {
    width: 260px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
}
.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.sidebar-wrapper a {
    text-decoration: none;
}
.sidebar-content {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto;
    /* position: relative; */
    padding: 15px 20px;
}
.sidebar-content.desktop {
    overflow-y: hidden;
}
/* .sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
} */
.sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}
.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}
/*--------------------sidebar-header----------------------*/
.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-info > span {
    display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
    color: #5cb85c;
}
/*-----------------------sidebar-search------------------------*/
.sidebar-wrapper .sidebar-search > div {
    padding: 10px 20px;
}
/*----------------------sidebar-menu-------------------------*/
.sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: 600;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 0px 8px 4px;
}
.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 4px;
    font-size: 14px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 14px;
    font-size: 13px;
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
    transform: rotate(90deg);
    right: 17px;
}
.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
}
.sidebar-footer > a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
    color: #e60013;
    font-weight: 600;
    font-size: 16px;
}
.sidebar-footer > a .notification {
    position: absolute;
    top: 0;
}
.badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
}
.badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: sonar 1.5s infinite;
}
/*--------------------------page-content-----------------------------*/
.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    /* padding-top: 20px; */
}
.page-wrapper .page-content > div {
    /* padding: 20px 40px; */
    padding-right: 0px;
    padding-left: 0px;
}
.page-wrapper .page-content {
    overflow-x: hidden;
}
.chiller-theme .sidebar-wrapper {
    background: #fff;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
}
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a {
    color: #000;
    font-weight: 500;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active {
    color: #fff;
    background: #e60013;
    font-weight: 600;
    border-radius: 12px;
    padding: 4px 0px 4px 8px;
    border-radius: 8px;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active.second {
    color: #e60013;
    background: #ffebee;
    font-weight: 600;
    border-radius: 12px;
    padding: 0px 0px 0px 4px;
    border-radius: 8px;
}
.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}
.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}
.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}
.chiller-theme .sidebar-footer {
    color: #e60013;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
    padding: 5px;
    background: #fff;
}
.chiller-theme .sidebar-footer > a:first-child {
    border-left: none;
}
.chiller-theme .sidebar-footer > a:last-child {
    border-right: none;
}
#close-sidebar {
    background: #e60013;
    color: #fff !important;
    box-shadow: 0 0.125rem 0.25rem rgb(219 83 99 / 10%);
    width: 30px;
    height: 30px;
    border-radius: 50px;
    text-align: center;
    position: absolute;
    right: -14px;
    top: 11px;
    z-index: 99999;
}
.radius {
    border-radius: 50px;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active.sub {
    border-left: none;
    color: #e60013;
    background: #fff;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.sub {
    padding: 8px 8px 8px 5px;
}
.sidebar-wrapper .sidebar-menu ul li a i.sub {
    margin-right: 0px;
    font-size: 12px;
}
.block {
    display: block;
}
.none {
    display: none;
}
.user-avatar-sidebar {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.edit-position {
    position: absolute;
    right: -10px;
    color: #dc3545;
    background: #fff;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    box-shadow: 0 2px 5px rgb(196 205 213 / 25%);
    text-align: center;
    padding: 1px;
}
.nav-top-main {
    background: #fff;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
    padding: 10px;
    width: 100%;
    right: 0;
    z-index: 99;
    left: 0;
    padding-right: 40px !important;
    padding-left: 40px !important;
    position: fixed;
}
.user-small {
    width: 35px !important;
    height: 35px !important;
}
.mt-nav-top {
    margin-top: 50px;
}
.hr-horizontal {
    background: rgba(0, 0, 0, 0);
    border: 0;
    height: 1px;
    color: inherit;
    opacity: 0.25;
    margin: 0.5rem 0;
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.4),
        transparent
    );
}
.hidden {
    display: none;
}
.show {
    display: block !important;
}
.count {
    position: absolute;
    top: -6px;
    right: 15px;
    height: 15px;
    width: 15px;
    background: #e60013;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: bold;
    z-index: 99;
    line-height: 15px;
}
.p-alert-notif {
    padding: 5px;
    left: 6px !important;
    top: 2px !important;
}
.radio_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f7f7f7;
    width: 100%;
    border-radius: 50px;
}
input[type="radio"] {
    appearance: none;
    display: none;
}

label.tab-radio-label {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    cursor: pointer;
    width: 33.33%;
    margin: 5px;
    height: 22px;
    text-align: center;
    border-radius: 50px;
    overflow: hidden;
    color: #000;
}

input.tab-radio-input[type="radio"]:checked + label {
    background-color: #3d8824;
    color: #fff;
    transition: 0.3s;
}
.grayscale {
    filter: grayscale(1);
    background-color: #e0e0e0;
    background-blend-mode: overlay;
}
.user-notif {
    width: 40px !important;
    height: 40px !important;
}
.scroll-notif {
    height: 240px;
    overflow-y: scroll;
}
.dropdown {
    position: relative;
}
.dropdown-menu {
    display: none;
}
.is-active .dropdown-menu {
    display: block;
    background: #fff;
    padding: 15px !important;
    width: auto;
    position: fixed;
    min-width: 380px;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}
.dropdown-profile {
    position: relative;
}
.dropdown-menu-profile {
    display: none;
}
.is-active-profile .dropdown-menu-profile {
    display: block;
    background: #fff;
    padding: 15px !important;
    width: 180px;
    position: fixed;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}
</style>
